import { gql } from '@apollo/client';

export const GET_OS_LOCALES_QUERY = gql`
  query GET_OS_LOCALES_QUERY($companyId: ID!) {
    getOSLocales(companyId: $companyId) {
      _id
      defaultLanguageCode
      active
      activeLanguagesCodes
    }
  }
`;
