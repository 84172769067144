import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextInput, KeyIcon } from 'kosmos-ui/lib';

import { useUpdateMyShopperPwMutation } from 'api/graphql/mutations/shopper/hooks';
import { SectionContainer, SectionHeader, Form } from '../../styles';

import { TUpdateMyShopperPwFormValues } from './types';
import { shopperPwFormValidationSchema } from './helpers';
import { Button } from 'app/components';

export default function ShopperAccountSection() {
  const { t } = useTranslation();

  const [
    updateMyShopperPwMutation,
    { loading: loadingUpdateMyShopperPwMutation },
  ] = useUpdateMyShopperPwMutation();

  const form = useFormik<TUpdateMyShopperPwFormValues>({
    initialValues: {
      currentPw: '',
      newPw: '',
    },
    validationSchema: shopperPwFormValidationSchema,
    onSubmit(values) {
      updateMyShopperPwMutation({
        variables: values,
      });
    },
  });

  return (
    <SectionContainer style={{ maxWidth: 380 }}>
      <SectionHeader>{t('shopperBrowser.account.title')}</SectionHeader>

      <p style={{ margin: '16px 0' }}>
        {t('shopperBrowser.account.description')}
      </p>

      <Form onSubmit={form.handleSubmit} noValidate>
        <TextInput
          name="currentPw"
          value={form.values.currentPw}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={t('shopperBrowser.account.currentPwLabel')}
          variant="filled"
          type="password"
          error={!!form.errors.currentPw && form.touched.currentPw}
          style={{ root: { marginBottom: 8 } }}
          adornments={{ left: <KeyIcon height="20px" /> }}
        />

        <TextInput
          name="newPw"
          value={form.values.newPw}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={t('shopperBrowser.account.newPwLabel')}
          variant="filled"
          type="password"
          error={!!form.errors.newPw && form.touched.newPw}
          adornments={{ left: <KeyIcon height="20px" /> }}
        />

        <Button
          variant="primary"
          type="submit"
          loading={loadingUpdateMyShopperPwMutation}
          style={{ width: '100%', marginTop: 24 }}
        >
          {t('shopperBrowser.account.saveButton')}
        </Button>
      </Form>
    </SectionContainer>
  );
}
