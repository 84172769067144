import { useQuery } from '@apollo/client';

import { useCompany } from 'app/context/CompanyContext/hooks';

import { GET_DELIVERY_LOCATIONS_QUERY } from './documents';
import {
  TGetDeliveryLocationsData,
  TGetDeliveryLocationsVariables,
} from './types';

export function useGetDeliveryLocationsQuery() {
  const company = useCompany();

  const query = useQuery<
    TGetDeliveryLocationsData,
    TGetDeliveryLocationsVariables
  >(GET_DELIVERY_LOCATIONS_QUERY, {
    variables: { companyId: company._id },
  });

  return query;
}
