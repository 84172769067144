import { gql } from '@apollo/client';

export const GET_OS_NAVBAR_QUERY = gql`
  query GET_OS_NAVBAR_QUERY($companyId: ID) {
    getOnlineStoreNavbar(companyId: $companyId) {
      _id
      navlink {
        _id
        children {
          _id
          label
          link
          children {
            _id
            label
            link
          }
        }
      }
      brandLogo
      backgroundColor
      elementsColor
      orientation
    }
  }
`;
