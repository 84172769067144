import styled from 'styled-components';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { lighten } from 'polished';

export const NavigationContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: 200;
`;

export const Navlink = styled(ReactRouterLink)<{ fontStyle: TOSFont }>`
  text-decoration: none;
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ fontStyle }) => fontStyle.family};
  margin-bottom: 16px;
  color: ${({ fontStyle }) => fontStyle.color};

  &:hover {
    color: ${({ fontStyle }) => lighten(0.7, fontStyle.color)};
  }
`;

// @ts-ignore
export const ChildNavlink = styled(Navlink)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const NavlinkLabel = styled.p<{ fontStyle: TOSFont }>`
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ fontStyle }) => fontStyle.family};
  color: ${({ fontStyle }) => fontStyle.color};

  &:hover {
    color: ${({ fontStyle }) => lighten(0.7, fontStyle.color)};
  }
`;
