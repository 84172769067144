import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, LocaleFlagIcon, theme } from 'kosmos-ui/lib';

import { useLayout } from 'app/context/LayoutContext/hooks';
import { useOSLocales } from 'app/context/OSLocalesContext/hooks';

import Button from '../Button';
import Typography from '../Typography';
import { SUPPORTED_LANGUAGES_NAMES } from 'app/constants/locales';

import { TLocalesSwitcherProps } from './types';

export default function LocalesSwitcher(props: TLocalesSwitcherProps) {
  const [showLocaleSelectorDialog, setShowLocaleSelectorDialog] =
    useState(false);

  const { mobile } = useLayout();

  const { i18n, t } = useTranslation();

  const osLocales = useOSLocales();

  const activeLanguageCode = i18n.language as TSupportedLanguageCode;

  function handleLocaleChangeClick(languageCode: TSupportedLanguageCode) {
    i18n.changeLanguage(languageCode);

    setShowLocaleSelectorDialog(false);
  }

  if (!osLocales.active) return null;

  const availableLanguages = (
    Object.entries(SUPPORTED_LANGUAGES_NAMES) as [
      TSupportedLanguageCode,
      string
    ][]
  ).filter(([languageCode]) =>
    osLocales.activeLanguagesCodes.includes(languageCode)
  );

  return (
    <>
      <Button
        onClick={() => setShowLocaleSelectorDialog(true)}
        variant="secondary"
        format="link"
        style={props.style?.showDialogButton}
        className={props.className?.showDialogButton}
      >
        <LocaleFlagIcon
          languageCode={activeLanguageCode}
          height="32px"
          style={props.style?.flag}
          className={props.className?.flag}
        />
      </Button>

      <Dialog
        show={showLocaleSelectorDialog}
        style={{ width: mobile ? '75vw' : 320 }}
      >
        <Dialog.Header>
          <Dialog.Title>{t('localesSwitcher.title')}</Dialog.Title>

          <Dialog.Close onClick={() => setShowLocaleSelectorDialog(false)} />
        </Dialog.Header>

        <Dialog.Body>
          <Typography as="p" variant="paragraph1" style={{ marginBottom: 16 }}>
            {t('localesSwitcher.description')}
          </Typography>

          {availableLanguages.map(([languageCode, languageName]) => (
            <Button
              key={languageCode}
              onClick={() => handleLocaleChangeClick(languageCode)}
              variant="secondary"
              format="outline"
              size="small"
              style={{
                marginBottom: 8,
                border: `1px solid ${
                  activeLanguageCode === languageCode
                    ? // TODO: Update kosmos-ui theme.colorsPalette.gray.light to fit this color.
                      'rgb(215 216 218)'
                    : theme.colorsPalette.gray.superLight
                }`,
              }}
            >
              <LocaleFlagIcon
                languageCode={languageCode}
                height="32px"
                style={{ marginRight: 8 }}
              />

              <Typography as="p" variant="paragraph1">
                {languageName}
              </Typography>
            </Button>
          ))}
        </Dialog.Body>
      </Dialog>
    </>
  );
}
