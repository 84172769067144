import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'kosmos-ui/lib';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { Navlink, NavlinkLabel, ChildNavlink } from '../../styles';

import { Container } from './styles';

/**
 * Renders a group of navlinks to use in the navbar section.
 * @param navlinks - Navlinks to render.
 */
export default function NavbarNavlinks({
  navlinks,
}: {
  navlinks: Distinct<TOSNavlink>[];
}) {
  const { navbar, fonts } = useOSThemeAndContent();

  const history = useHistory();

  return (
    <Container>
      {navlinks.map((navlink) => {
        const childrenOptions: {
          label: ReactNode;
          callback: () => void;
        }[] =
          navlink.children &&
          navlink.children.length > 0 &&
          navlink.children.reduce((acc, child) => {
            if (child.link) {
              return [
                ...acc,
                {
                  label: (
                    <ChildNavlink
                      to="#"
                      fontStyle={{
                        ...fonts.paragraphsFont,
                        color: navbar.elementsColor,
                      }}
                    >
                      {child.label}
                    </ChildNavlink>
                  ),
                  callback() {
                    history.push(child.link as string);
                  },
                },
              ];
            }

            return acc;
          }, [] as any);

        if (childrenOptions && childrenOptions.length > 0) {
          return (
            <Dropdown key={navlink._id}>
              <Dropdown.ToggleButton>
                <NavlinkLabel
                  style={{ margin: '0px 16px' }}
                  fontStyle={{
                    ...fonts.paragraphsFont,
                    color: navbar.elementsColor,
                  }}
                >
                  {navlink.label}
                </NavlinkLabel>
              </Dropdown.ToggleButton>

              <Dropdown.Menu style={{ zIndex: 99999 }}>
                {childrenOptions.map((child, index) => {
                  return (
                    <Dropdown.Item key={index} onClick={child.callback}>
                      {child.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        }

        return (
          navlink.link && (
            <Navlink
              key={navlink._id}
              to={navlink.link || '-'}
              fontStyle={{
                ...fonts.paragraphsFont,
                color: navbar.elementsColor,
              }}
              style={{ margin: '0 16px', padding: '16px 0' }}
              activeStyle={{
                borderBottom: `3px solid ${navbar.elementsColor}`,
              }}
            >
              {navlink.label}
            </Navlink>
          )
        );
      })}
    </Container>
  );
}
