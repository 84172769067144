import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { theme } from 'kosmos-ui/lib';

import { useGetDeliverySettingsQuery } from 'api/graphql/queries/delivery/settings/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import CheckoutCartDetails from './components/CheckoutCartDetails';
import PaymentForm from './components/PaymentForm';
import CheckoutNotes from './components/CheckoutNotes';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import {
  getCheckoutCouponDiscountPrice,
  getCheckoutFinalAmount,
} from './helpers';
import { CheckoutContainer, CheckoutPaymentFormContainer } from './styles';
import { BUY_ONE_GET_TWO_OFFER_COMPANY_IDS } from 'app/constants/general';

/**
 * Checkout section, composed by the shopping cart details and the
 * payment form subsections.
 */
export default function Checkout() {
  const [shippingCost, setShippingCost] = useState(0);

  const { mobile, topBarHeight, announcementBarHeight } = useLayout();
  const osTheme = useOSThemeAndContent();
  const company = useCompany();
  const { shoppingCart } = useShoppingCart();

  const { data: deliverySettingsData } = useGetDeliverySettingsQuery();

  const checkoutNotes = deliverySettingsData?.getDeliverySettings.deliveryInfo;
  const storePickUpEnabled =
    deliverySettingsData?.getDeliverySettings.storePickup;

  const productsAmount: number = Object.values(shoppingCart.products).reduce(
    (acc, product) => {
      const productSubtotal = (
        product.variants
          ? product.variants.reduce((acc, variant) => {
              return acc + variant.subtotal;
            }, 0)
          : product.subtotal
      ) as number;

      return acc + productSubtotal;
    },
    0
  );



  let withCouponTotalAmount = undefined;
  let productAmountWithCoupon = undefined;

  if (shoppingCart.coupon) {
    productAmountWithCoupon = getCheckoutCouponDiscountPrice(
      shoppingCart.coupon.discountType,
      shoppingCart.coupon.value,
      productsAmount
    );
    withCouponTotalAmount = getCheckoutFinalAmount(
      productAmountWithCoupon,
      shippingCost
    );
  }

  // Patch in order to apply 2x1 discount to CharlieTherapy
  if (
    BUY_ONE_GET_TWO_OFFER_COMPANY_IDS.includes(company._id) &&
    Object.values(shoppingCart.products).length
  ) {
    const shoppingCartQty = Object.values(shoppingCart.products).reduce(
      (acc, product) => {
        const productQty = product.variants
          ? product.variants.reduce((acc, variant) => {
              return acc + variant.qty;
            }, 0)
          : (product.qty as unknown as number) || 0;
  
        return acc + productQty;
      },
      0
    );

    if(shoppingCartQty === 2){
      productAmountWithCoupon = (productsAmount/2) * 1.5;
      withCouponTotalAmount = getCheckoutFinalAmount(
        productAmountWithCoupon,
        shippingCost
      );
    }
  }

  const totalAmount = getCheckoutFinalAmount(productsAmount, shippingCost);

  const headerHeight = topBarHeight + announcementBarHeight;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | Checkout`
            : 'Cargando...'}
        </title>
      </Helmet>

      <CheckoutContainer headerHeight={headerHeight}>
        {mobile && <CheckoutNotes checkoutNotes={checkoutNotes} />}

        {!mobile && (
          <CheckoutCartDetails
            amounts={{
              shippingCost,
              productsAmount,
              totalAmount,
              withCouponTotalAmount,
            }}
            checkoutNotes={checkoutNotes}
          />
        )}

        <CheckoutPaymentFormContainer
          backgroundColor={
            mobile
              ? (theme.colorsPalette.gray.superLight as string)
              : osTheme.buttons.primary.backgroundColor
          }
        >
          <PaymentForm
            setShippingCost={setShippingCost}
            amounts={{
              shippingCost,
              productsAmount,
              totalAmount,
              withCouponTotalAmount,
            }}
            storePickUpEnabled={storePickUpEnabled}
          />
        </CheckoutPaymentFormContainer>
      </CheckoutContainer>
    </>
  );
}
