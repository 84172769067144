import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

import { useQueryParam } from 'app/hooks/routes';
import { useGlobalSearch } from 'app/context/GlobalSearchContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import ProductsList from 'app/components/ProductsList';
import Typography from 'app/components/Typography';
import { ProductsSearchResultsContainer } from './styles';

export default function ProductsSearchResults() {
  const { t } = useTranslation();

  const { mobile, topBarHeight, announcementBarHeight } = useLayout();

  const company = useCompany();

  const { setTerm: setGlobalTerm } = useGlobalSearch();

  const queryParam = useQueryParam();

  const searchTerm = queryParam.get('q') as string | undefined;

  const headerHeight = topBarHeight + announcementBarHeight;

  useEffect(
    () => () => {
      setGlobalTerm('');
    },
    [setGlobalTerm]
  );

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | ${t('productsSearchResults.headTitle')}`
            : 'Cargando...'}
        </title>
      </Helmet>

      <ProductsSearchResultsContainer headerHeight={headerHeight}>
        <Typography as="h1" variant="title3" style={{ margin: '0 52px' }}>
          {t('productsSearchResults.title', { searchTerm })}
        </Typography>

        <ProductsList
          search={searchTerm}
          style={{ padding: !mobile ? 52 : '24px 52px 52px 52px' }}
        />
      </ProductsSearchResultsContainer>
    </>
  );
}
