import { useTranslation } from 'react-i18next';

import { useRestMutation } from 'api/rest/mutations';
import { TRestRequestOptions } from 'api/rest/types';
import { useAlerts } from 'app/context/AlertsContext/hooks';
import { REST_REQUESTS } from 'api/rest/constants';

import {
  TRestPaymentMutationData,
  TRestPaymentMutationVariables,
} from './types';

/**
 * [Hook] Provides a trigger function and loading state for a
 * TBK Wpp payment request (REST).
 */
export function useCreateTBKWppPaymentMutation(
  options?: TRestRequestOptions<
    TRestPaymentMutationData,
    TRestPaymentMutationVariables
  >
) {
  const { t } = useTranslation();

  const { showErrorSnackbar } = useAlerts();

  const mutation = useRestMutation<
    TRestPaymentMutationData,
    TRestPaymentMutationVariables
  >(
    `${REST_REQUESTS.payments.tbkWpp.createPayment.baseURL}/${REST_REQUESTS.payments.tbkWpp.createPayment.url}`,
    REST_REQUESTS.payments.tbkWpp.createPayment.method,
    {
      authTokenEntity: 'shopper',
      ...options,
      onCompleted(data) {
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else {
          showErrorSnackbar({
            title: t('api.payments.errorTitle'),
            message: t('api.payments.errorDescription'),
          });
        }
      },
      onError(error) {
        showErrorSnackbar({
          title: t('api.payments.errorTitle'),
          message: error?.response?.data.description || '',
        });
      },
    }
  );

  return mutation;
}
