import { createContext } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

import { useCompany } from '../CompanyContext/hooks';
import { GET_OS_BUTTONS_QUERY } from 'api/graphql/queries/online-store/buttons/documents';
import { GET_OS_FOOTER_QUERY } from 'api/graphql/queries/online-store/footer/documents';
import { GET_OS_FONTS_QUERY } from 'api/graphql/queries/online-store/fonts/documents';
import { GET_OS_NAVBAR_QUERY } from 'api/graphql/queries/online-store/navbar/documents';
import { GET_OS_PAGES_QUERY } from 'api/graphql/queries/online-store/pages/documents';

import { IContextProviderProps, TContextPage } from './types';
import { DEFAULT_OS_THEME_AND_CONTENT_CONTEXT_VALUES } from './contants';

const OSThemeAndContentContext = createContext(
  DEFAULT_OS_THEME_AND_CONTENT_CONTEXT_VALUES
);

function OSThemeAndContentContextProvider({ children }: IContextProviderProps) {
  const { _id: companyId } = useCompany();

  const { data: buttonsData, loading: loadingButtonsData } = useQuery(
    GET_OS_BUTTONS_QUERY,
    { variables: { companyId } }
  );

  const { data: footerData, loading: loadingFooterData } = useQuery(
    GET_OS_FOOTER_QUERY,
    { variables: { companyId } }
  );

  const { data: fontsData, loading: loadingFontsData } = useQuery(
    GET_OS_FONTS_QUERY,
    { variables: { companyId } }
  );

  const { data: navbarData, loading: loadingNavbarData } = useQuery(
    GET_OS_NAVBAR_QUERY,
    { variables: { companyId } }
  );

  const { data: customPagesData, loading: loadingPagesData } = useQuery(
    GET_OS_PAGES_QUERY,
    { variables: { companyId } }
  );

  if (
    loadingButtonsData ||
    loadingFooterData ||
    loadingFontsData ||
    loadingNavbarData ||
    loadingPagesData
  )
    return null;

  return (
    <OSThemeAndContentContext.Provider
      value={{
        fonts: get(fontsData, 'getOnlineStoreFonts'),
        buttons: get(buttonsData, 'getOnlineStoreButtons'),
        navbar: get(navbarData, 'getOnlineStoreNavbar'),
        footer: get(footerData, 'getOnlineStoreFooter'),
        customPages: [
          ...get(customPagesData, 'getOnlineStoreBlogs', [] as TContextPage[]),
          ...get(
            customPagesData,
            'getOnlineStoreGalleries',
            [] as TContextPage[]
          ),
        ].map((page) => ({
          _id: page._id,
          title: page.title,
          type: page.images ? 'gallery' : 'blog',
        })),
      }}
    >
      {children}
    </OSThemeAndContentContext.Provider>
  );
}

export default OSThemeAndContentContextProvider;
export { OSThemeAndContentContext };
