import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Dialog,
  TextInput,
  KeyIcon,
  MailIcon,
  ProfileIcon,
} from 'kosmos-ui/lib';

import { useSignUpShopperMutation } from 'api/graphql/mutations/shopper/hooks';
import { useBackgroundLocation } from 'app/hooks/routes';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { ROUTES } from 'app/constants/app';
import { Button } from 'app/components';

import { signUpShopperFormValidationSchema } from './helpers';
import { TShopperSignUpForm } from './types';
import { GroupedInputsContainer, NotesContainer } from './styles';
import Typography from 'app/components/Typography';

export default function ShopperSignUp() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const { location, goBackToBackgroundLocation } = useBackgroundLocation();

  const [signUpShopperMutation, { loading: loadingSignUpShopperMutation }] =
    useSignUpShopperMutation({
      onCompleted() {
        goBackToBackgroundLocation();
      },
    });

  const form = useFormik<TShopperSignUpForm>({
    initialValues: { firstname: '', lastname: '', email: '', password: '' },
    validationSchema: signUpShopperFormValidationSchema,
    onSubmit(values) {
      signUpShopperMutation({ variables: values });
    },
  });

  return (
    <Dialog show style={{ width: mobile ? '75vw' : '33vw', maxWidth: 560 }}>
      <Dialog.Header>
        <Dialog.Title>{t('shopperSignUp.title')}</Dialog.Title>

        <Dialog.Close onClick={goBackToBackgroundLocation} />
      </Dialog.Header>

      <Dialog.Body>
        <NotesContainer>{t('shopperSignUp.notes')}</NotesContainer>

        <GroupedInputsContainer>
          <TextInput
            name="firstname"
            value={form.values.firstname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={`${t('shopperSignUp.firstnameInputLabel')}*`}
            variant="filled"
            adornments={{ left: <ProfileIcon height="20px" /> }}
            style={{ root: { marginRight: 16 } }}
            error={!!form.errors.firstname && form.touched.firstname}
          />

          <TextInput
            name="lastname"
            value={form.values.lastname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={`${t('shopperSignUp.lastnameInputLabel')}*`}
            variant="filled"
            adornments={{ left: <ProfileIcon height="20px" /> }}
            error={!!form.errors.lastname && form.touched.lastname}
          />
        </GroupedInputsContainer>
        <TextInput
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={`${t('shopperSignUp.emailInputLabel')}*`}
          variant="filled"
          adornments={{ left: <MailIcon height="20px" /> }}
          style={{ root: { marginBottom: 8 } }}
          error={!!form.errors.email && form.touched.email}
        />
        <TextInput
          name="password"
          type="password"
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={`${t('shopperSignUp.passwordInputLabel')}*`}
          variant="filled"
          adornments={{ left: <KeyIcon height="20px" /> }}
          error={!!form.errors.password && form.touched.password}
        />
      </Dialog.Body>

      <Dialog.Footer style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Button
          variant="primary"
          onClick={() => form.handleSubmit()}
          loading={loadingSignUpShopperMutation}
          style={{ width: '100%', marginBottom: 8 }}
        >
          {t('shopperSignUp.submitButton')}
        </Button>

        <Typography as="p" variant="paragraph1">
          {t('shopperSignUp.switchToSignInDescription')}{' '}
          <Link
            to={{
              pathname: ROUTES.shopperSignIn,
              state: location.state,
            }}
            replace
            style={{ textDecoration: 'none' }}
          >
            {t('shopperSignUp.switchToSignInLink')}
          </Link>
        </Typography>
      </Dialog.Footer>
    </Dialog>
  );
}
