export const EN_TRANSLATIONS: TLocaleTranslation = {
  api: {
    payments: {
      errorTitle: 'Error processing your payment',
      errorDescription:
        'We were not able to process your payment. Please try again or contact our support team.',
    },
  },
  shopperEntry: { button: 'Login' },
  shopperSignUp: {
    title: 'Sign up',
    notes:
      'By registering, you will be able to buy more quickly and discover new products and categories of your interest.',
    firstnameInputLabel: 'Name',
    lastnameInputLabel: 'Last name',
    emailInputLabel: 'Email',
    passwordInputLabel: 'Password',
    submitButton: 'Create account',
    switchToSignInDescription: 'Already have an account? Login',
    switchToSignInLink: 'here.',
  },
  shopperSignIn: {
    title: 'Sign in',
    emailInputLabel: 'Email',
    passwordInputLabel: 'Password',
    submitButton: 'Login',
    switchToSignUpDescription: "Don't have an account? Sign up",
    switchToSignUpLink: 'here.',
  },
  shopperBrowser: {
    loadingShopperDescription: 'Loading your account information',
    sidebar: {
      profileLabel: 'Profile',
      ordersLabel: 'My orders',
      accountLabel: 'Account',
      signOutLabel: 'Log out',
    },
    profile: {
      firstnameLabel: 'Firstname',
      lastnameLabel: 'Lastname',
      idLabel: 'ID',
      phoneLabel: 'Phone',
      locationAddressLabel: 'Address',
      saveButton: 'Save changes',
    },
    orders: {
      emptyOrdersTitle: 'You dont have orders yet',
      emptyOrdersDescription:
        'Once you buy a product your order will appear here!',
      debitCardName: 'Debit',
      creditCardName: 'Credit',
      productsQtyLabel: '{{productsQty}} items.',
    },
    account: {
      title: 'Password',
      description:
        'Your password must have at least 8 characters, one uppercase, one number and one lowercase.',
      currentPwLabel: 'Current password',
      newPwLabel: 'Password',
      saveButton: 'Save changes',
    },
  },
  topbar: {
    searchPlaceholder: 'Search',
  },
  productList: {
    filters: {
      showToggleButton: 'Show filters',
      hideToggleButton: 'Hide filters',
      mobileToggleButton: 'Filters',
      mobileTitle: 'Filter products',
      sortByLabel: 'Sort by',
      sortByOptionLabels: {
        nameAsc: 'Name, A-Z',
        nameDesc: 'Name, Z-A',
        priceAsc: 'Price, low-high',
        priceDesc: 'Price, high-low',
        createdAtAsc: 'Date, last-new',
        createdAtDesc: 'Date, new-last',
      },
      filterByDiscountTitle: 'Discounts',
      filterByDiscountLabel: 'Only discounts',
      priceIntervalTitle: 'Price',
      cleanupButton: 'Remove filters',
    },
    fetchMoreButton: 'Load more products',
    productsNotFoundTitle: "We didn't find products",
    productsNotFoundDescription: 'Try with another search!',
    errorLoadingProductsLabel: 'Error loading products!',
  },
  productDetails: {
    outOfStockLabel: 'Out of stock',
    selectVariantPlaceholder: 'Select a variant',
    addToCartButton: 'Add to basket',
  },
  productsSearchResults: {
    headTitle: 'Search in products',
    title: 'Results for "{{searchTerm}}"',
  },
  shoppingCart: {
    addProductsSnackbar: {
      successTitle: 'Product added',
      successDescription: 'Your product has been added to the basket!',
      errorTitle: 'Out of stock!',
      errorDescription: 'The quantity selected is over the available stock.',
    },
  },
  shoppingCartDetailsBar: {
    emptyStateParagraph: 'Your basket is empty.',
    subTotalLabel: 'Sub Total:',
    goToCheckoutButton: 'Go to checkout',
  },
  checkout: {
    shoppingCartDetails: {
      title: 'Checkout',
      continueShoppingButton: 'Continue shopping',
      subtotalLabel: 'Subtotal',
      shippingCostsLabel: 'Delivery fee',
      couponLabel: 'Promotion code or voucher',
      totalAmountLabel: 'Order total',
    },
    paymentForm: {
      firstnameLabel: 'Name',
      lastnameLabel: 'Lastname',
      emailLabel: 'Email',
      phoneLabel: 'Phone',
      idLabel: 'Citizen ID',
      deliveryOptionLabel: 'Delivery to address',
      storePickupOptionLabel: 'Pick up on store',
      deliveryAddressLabel: 'Address',
      deliveryLocationPlaceholder: 'Region',
      deliveryZonePlaceholder: 'Town/City',
      deliveryNotesLabel: 'Delivery instructions',
      deliveryErrorLabel: 'We need to know where to deliver your products!',
      tbkOneClickGatewayTitle: 'Webpay Oneclick',
      tbkOneClickGatewayDescription: 'Pay with only one click',
      tbkWppGatewayTitle: 'Webpay plus',
      notSelectedDeliveryLocationOptionLabel: 'Select a Region',
      couponCodeLabel: 'Promotion code or voucher',
      applyCouponButton: 'Apply promotion',
      missingFieldsErrorLabel: 'Some fields are not yet fulfilled!',
      steps: {
        contactTitle: 'Contact',
        deliveryTitle: 'Delivery',
        paymentTitle: 'Payment',
        summaryTitle: 'Summary',
        goBackButton: 'Back',
        goNextButton: 'Next',
        payButton: 'Pay',
      },
    },
  },
  paymentResult: {
    invalidTokenTitle: 'Oops',
    invalidTokenDescription:
      'The page you are looking for does not exist. Return to our home page to continue shopping!',
    invalidTokenActionMessage: 'Continue shopping',
    failedPaymentTitle: 'Oops',
    failedPaymentDescription:
      'We had an error processing your payment! Please try again or contact us to help you.',
    failedPaymentActionMessage: 'Continue shopping',
    cancelledPaymentTitle: 'Order cancelled',
    cancelledPaymentDescription:
      'Your purchase was successfully cancelled. Return to our homepage to continue shopping!',
    cancelledPaymentActionMessage: 'Continue shopping',
    succeedPaymentTitle: 'Thanks for your purchase',
    succeedPaymentDescription:
      'Your order was successfully processed. We will send an email to {{shopperEmail}} with the receipt of the order.',
    succeedPaymentActionMessage: 'Continue shopping',
  },
  localesSwitcher: {
    title: 'Change language',
    description:
      'Select the language you prefer for browsing, shopping and communications.',
  },
};
