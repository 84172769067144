import styled from 'styled-components';

export const CheckoutAmountsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-top: 24px;
  }
`;
