import { useLazyQuery } from '@apollo/client';

import { isCouponExpired } from 'app/sections/Checkout/components/PaymentForm/helpers';
import { useAlerts } from 'app/context/AlertsContext/hooks';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';

import { GET_COUPON_QUERY } from './documents';
import { TGetCouponData, TGetCouponVariables } from './types';

export function useLazyGetCouponQuery() {
  const { showSuccessSnackbar, showErrorSnackbar } = useAlerts();

  const { dispatchShoppingCart } = useShoppingCart();

  const query = useLazyQuery<TGetCouponData, TGetCouponVariables>(
    GET_COUPON_QUERY,
    {
      onCompleted(data) {
        const coupon = data.getCoupon;

        const couponExpired = isCouponExpired(coupon.endDate);

        const couponExhausted =
          coupon.maxUsage && coupon.maxUsage - coupon.usage <= 0;

        if (couponExpired || couponExhausted) {
          showErrorSnackbar({
            title: 'Cupón expirado!',
            message: 'Por favor intenta con un código que aún sea utilizable.',
          });
        } else {
          dispatchShoppingCart({
            type: 'addCoupon',
            coupon: { discountType: coupon.discountType, value: coupon.value },
          });

          showSuccessSnackbar({
            title: 'Descuento aplicado!',
            message: `${coupon.code} ha sido aplicado correctamente.`,
          });
        }
      },
      onError(error) {
        showErrorSnackbar({
          title: 'Error en tu cupón',
          message: error.graphQLErrors[0]?.message,
        });
      },
    }
  );

  return query;
}
