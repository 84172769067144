import helpers from 'app/helpers';

export function getProductDiscountTag({
  variants,
  price,
  discountPrice,
}: Pick<TProduct, 'variants' | 'price' | 'discountPrice'>): string | undefined {
  let tag;

  if (price && discountPrice) {
    tag = helpers.getProductDiscountToRender(price, discountPrice);
  } else if (variants) {
    const areVariantsDiscounts = variants.reduce((acc, variant) => {
      return acc || !!variant.discountPrice;
    }, false);

    if (areVariantsDiscounts) tag = 'Con descuentos';
  }

  return tag;
}
