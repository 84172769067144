import styled from "styled-components";

export const SnackbarMainContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SnackbarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SnackbarHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsPalette.black.default};
  margin: 0 0 5px 0;
`;

export const SnackbarParagraph = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
  margin: 0;
`;
