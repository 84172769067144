import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { EN_TRANSLATIONS } from './resources/en.translations';
import { ES_TRANSLATIONS } from './resources/es.translations';
import { DE_TRANSLATIONS } from './resources/de.translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN_TRANSLATIONS,
      },
      es: {
        translation: ES_TRANSLATIONS,
      },
      de: {
        translation: DE_TRANSLATIONS,
      },
    },
  });
