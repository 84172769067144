import { TProps } from './types';
import { MainContainer } from './styles';

function ShopBackdrop({
  showSidebar,
  showShoppingCartDetailsBar,
  toggleShowSidebar,
  toggleShowShoppingCartDetailsBar,
}: TProps) {
  function handleClick() {
    if (showSidebar && !showShoppingCartDetailsBar) return toggleShowSidebar();
    else if (showShoppingCartDetailsBar && !showSidebar)
      return toggleShowShoppingCartDetailsBar();
  }

  return <MainContainer onClick={handleClick} />;
}

export default ShopBackdrop;
