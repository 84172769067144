import { useQuery } from '@apollo/client';
import { useCompany } from 'app/context/CompanyContext/hooks';

import { GET_DELIVERY_SETTINGS_QUERY } from './documents';
import {
  TGetDeliverySettingsData,
  TGetDeliverySettingsOptions,
  TGetDeliverySettingsVariables,
} from './types';

export function useGetDeliverySettingsQuery(
  options?: TGetDeliverySettingsOptions
) {
  const company = useCompany();

  const query = useQuery<
    TGetDeliverySettingsData,
    TGetDeliverySettingsVariables
  >(GET_DELIVERY_SETTINGS_QUERY, {
    ...options,
    variables: { companyId: company._id, ...options?.variables },
  });

  return query;
}
