import { useTranslation } from 'react-i18next';

import helpers from 'app/helpers';
import { getCouponDiscountText } from '../../helpers';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import Typography from 'app/components/Typography';

import { TCheckoutAmountsProps } from './types';
import { CheckoutAmountsContainer } from './styles';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { theme } from 'kosmos-ui/lib';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { BUY_ONE_GET_TWO_OFFER_COMPANY_IDS } from 'app/constants/general';

/**
 * Renders the subtotal, delivery fees, coupon discount and total of the checkout.
 */
export default function CheckoutAmounts({
  amounts,
  exclude,
  style,
}: TCheckoutAmountsProps) {
  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  const { shoppingCart } = useShoppingCart();
  const company = useCompany();

  return (
    <CheckoutAmountsContainer style={style}>
      {!exclude?.includes('subtotal') && (
        <Typography
          variant='paragraph2'
          style={{
            color: osTheme.buttons.primary.backgroundColor,
            fontWeight: theme.fontWeights.bold,
          }}
        >
          {t('checkout.shoppingCartDetails.subtotalLabel')}: $
          {helpers.formatNumber(amounts.productsAmount)}
        </Typography>
      )}

      {!exclude?.includes('shippingCosts') && (
        <Typography
          variant='paragraph2'
          style={{
            color: osTheme.buttons.primary.backgroundColor,
            fontWeight: theme.fontWeights.bold,
          }}
        >
          {t('checkout.shoppingCartDetails.shippingCostsLabel')}: $
          {helpers.formatNumber(amounts.shippingCost)}
        </Typography>
      )}

      {!exclude?.includes('coupon') && shoppingCart.coupon && (
        <Typography
          variant='paragraph2'
          style={{
            color: osTheme.buttons.primary.backgroundColor,
            fontWeight: theme.fontWeights.bold,
          }}
        >
          {t('checkout.shoppingCartDetails.couponLabel')}: -$
          {helpers.formatNumber(
            amounts.totalAmount - (amounts.withCouponTotalAmount as number)
          )}{' '}
          {getCouponDiscountText(
            shoppingCart.coupon.value,
            shoppingCart.coupon.discountType
          )}
        </Typography>
      )}

      {!exclude?.includes('twoForOneDiscount') &&
        amounts.withCouponTotalAmount &&
        BUY_ONE_GET_TWO_OFFER_COMPANY_IDS.includes(company._id) && (
          <Typography
            variant='paragraph2'
            style={{
              color: osTheme.buttons.primary.backgroundColor,
              fontWeight: theme.fontWeights.bold,
            }}
          >
            {'2do prod. 50% off'}: -$
            {helpers.formatNumber(
              amounts.totalAmount - (amounts.withCouponTotalAmount as number)
            )}
          </Typography>
        )}

      {!exclude?.includes('total') && (
        <Typography
          variant='paragraph2'
          style={{
            color: osTheme.buttons.primary.backgroundColor,
            fontWeight: theme.fontWeights.bold,
          }}
        >
          {t('checkout.shoppingCartDetails.totalAmountLabel')}: $
          {helpers.formatNumber(
            amounts.withCouponTotalAmount || amounts.totalAmount
          )}
        </Typography>
      )}
    </CheckoutAmountsContainer>
  );
}
