import { gql } from '@apollo/client';

export const GET_DELIVERY_LOCATIONS_QUERY = gql`
  query GET_DELIVERY_LOCATIONS_QUERY($companyId: ID) {
    getDeliveryLocations(companyId: $companyId) {
      _id
      name
      zones {
        _id
        name
        shippingCost
      }
    }
  }
`;
