import styled from 'styled-components';

export const PaymentResultContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};
  height: 100vh;
  padding: 52px 15vw;
`;

export const PaymentResultFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
