import { gql } from '@apollo/client';

export const GET_OS_BUTTONS_QUERY = gql`
  query GET_OS_BUTTONS_QUERY($companyId: ID!) {
    getOnlineStoreButtons(companyId: $companyId) {
      _id
      primary {
        backgroundColor
        fontColor
      }
      danger {
        backgroundColor
        fontColor
      }
      success {
        backgroundColor
        fontColor
      }
    }
  }
`;
