import { useQuery } from '@apollo/client';

import { useCompany } from 'app/context/CompanyContext/hooks';

import { GET_OS_LOCALES_QUERY } from './documents';
import { TGetOSLocaleData, TGetOSLocaleVariables } from './types';

export function useGetOSLocalesQuery() {
  const company = useCompany();

  const query = useQuery<TGetOSLocaleData, TGetOSLocaleVariables>(
    GET_OS_LOCALES_QUERY,
    { variables: { companyId: company._id } }
  );

  return query;
}
