import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Loader,
  TextInput,
  ToggleButtons,
  TbkOneClickIcon,
  TbkWPPIcon,
  theme,
} from 'kosmos-ui/lib';
import { darken, lighten } from 'polished';

import { useGetDeliveryLocationsQuery } from 'api/graphql/queries/delivery/location/hooks';
import { useLazyGetCouponQuery } from 'api/graphql/queries/products/coupon/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useShopper } from 'app/context/ShopperContext/hooks';
import CheckoutAmounts from '../CheckoutAmounts';
import CheckoutCartDetails from '../CheckoutCartDetails';
import Typography from 'app/components/Typography';
import Select from 'app/components/Select';

import { getPaymentFormStepButtons } from './helpers';
import {
  TPaymentForm,
  TPaymentFormGatewaysFieldsProps,
  TPaymentFormDeliveryFieldsProps,
  TPaymentFormShopperDataFieldsProps,
  TPaymentFormStepSwitcherProps,
} from './types';
import {
  PaymentFormButton,
  PaymentFormButtonsContainer,
  PaymentFormFooterContainer,
  paymentFormSelectStyles,
  PaymentGatewayContainer,
  PaymentGatewayHeader,
} from './styles';

/**
 * Renders the content of the active step in the payment process.
 */
export function PaymentFormStepSwitcher({
  activeStep,
  form,
  amounts,
  storePickUpEnabled,
  setShippingCost,
}: TPaymentFormStepSwitcherProps) {
  const { mobile } = useLayout();

  switch (activeStep) {
    case 0:
      if (mobile)
        return (
          <>
            <CheckoutCartDetails amounts={amounts} />
            <CheckoutAmounts
              amounts={amounts}
              exclude={['shippingCosts', 'coupon', 'total']}
              style={{ marginTop: 0 }}
            />
          </>
        );

      return <PaymentFormShopperDataFields form={form} />;

    case 1:
      if (mobile) return <PaymentFormShopperDataFields form={form} />;

      return (
        <PaymentFormDeliveryFields
          form={form}
          storePickUpEnabled={storePickUpEnabled}
          amounts={amounts}
          setShippingCost={setShippingCost}
        />
      );

    case 2:
      if (mobile)
        return (
          <PaymentFormDeliveryFields
            form={form}
            storePickUpEnabled={storePickUpEnabled}
            amounts={amounts}
            setShippingCost={setShippingCost}
          />
        );

      return (
        <>
          {/* <PaymentFormGatewaysFields form={form} /> */}
          <PaymentFormCouponDiscountFields form={form} />
        </>
      );

    case 3:
      if (mobile)
        return (
          <>
            {/* <PaymentFormGatewaysFields form={form} /> */}
            <PaymentFormCouponDiscountFields form={form} />
            <CheckoutAmounts amounts={amounts} />
          </>
        );

      return (
        <>
          {/* <PaymentFormGatewaysFields form={form} /> */}
          <PaymentFormCouponDiscountFields form={form} />
        </>
      );

    default:
      return null;
  }
}

/**
 * Renders the payment form shopper information fields.
 */
export function PaymentFormShopperDataFields({
  form,
}: TPaymentFormShopperDataFieldsProps) {
  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  return (
    <>
      <TextInput
        id="userFirstname"
        name="userFirstname"
        value={form.values.userFirstname}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        label={t('checkout.paymentForm.firstnameLabel')}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { margin: '16px 0 16px 0' },
          input: { borderBottomWidth: 1 },
        }}
        error={!!form.errors.userFirstname && form.touched.userFirstname}
      />

      <TextInput
        type="text"
        id="userLastname"
        name="userLastname"
        value={form.values.userLastname}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        label={t('checkout.paymentForm.lastnameLabel')}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { margin: '16px 0' },
          input: { borderBottomWidth: 1 },
        }}
        error={!!form.errors.userLastname && form.touched.userLastname}
      />

      <TextInput
        type="email"
        id="userEmail"
        name="userEmail"
        value={form.values.userEmail}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        label={t('checkout.paymentForm.emailLabel')}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { margin: '16px 0' },
          input: { borderBottomWidth: 1 },
        }}
        error={!!form.errors.userEmail && form.touched.userEmail}
      />

      <TextInput
        name="userPhone"
        id="userPhone"
        value={form.values.userPhone}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        label={t('checkout.paymentForm.phoneLabel')}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { margin: '16px 0' },
          input: { borderBottomWidth: 1 },
        }}
        error={!!form.errors.userPhone && form.touched.userPhone}
      />

      <TextInput
        id="userRut"
        name="userRut"
        label={t('checkout.paymentForm.idLabel')}
        value={form.values.userRut}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { margin: '16px 0' },
          input: { borderBottomWidth: 1 },
        }}
        error={!!form.errors.userRut && form.touched.userRut}
      />
    </>
  );
}

/**
 * Renders the payment form delivery fields.
 */
export function PaymentFormDeliveryFields({
  form,
  storePickUpEnabled,
  amounts,
  setShippingCost,
}: TPaymentFormDeliveryFieldsProps) {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const osTheme = useOSThemeAndContent();

  const { data: deliveryLocationsData } = useGetDeliveryLocationsQuery();

  const deliveryLocations = deliveryLocationsData?.getDeliveryLocations;

  const selectLocationOptions = deliveryLocations?.map((location) => {
    const locationName = location.name;

    const locationId = location._id;

    return {
      value: locationId,
      label: locationName,
    };
  });

  const selectZoneOptions = form.values.deliveryLocation
    ? deliveryLocations
        ?.filter((deliveryLocation) => {
          return deliveryLocation._id === form.values.deliveryLocation?.value;
        })[0]
        .zones.map((deliveryZone) => {
          return {
            value: deliveryZone._id,
            label: deliveryZone.name,
          };
        })
    : [
        {
          value: '',
          label: t(
            'checkout.paymentForm.notSelectedDeliveryLocationOptionLabel'
          ),
        },
      ];

  const shippingCost =
    form.values.deliveryType === 'storepick'
      ? 0
      : form.values.deliveryLocation &&
        deliveryLocations &&
        deliveryLocations.length !== 0
      ? deliveryLocations?.reduce((acc, deliveryLocation) => {
          if (deliveryLocation._id === form.values.deliveryLocation?.value) {
            return deliveryLocation.zones.reduce((acc, deliveryZone) => {
              if (deliveryZone._id === form.values.deliveryZone?.value) {
                return deliveryZone.shippingCost || 0;
              }

              return acc;
            }, 0);
          }

          return acc;
        }, 0)
      : 0;

  useEffect(() => {
    setShippingCost(shippingCost);
  }, [shippingCost, setShippingCost]);

  return (
    <>
      {storePickUpEnabled && (
        <ToggleButtons
          options={[
            {
              label: t('checkout.paymentForm.deliveryOptionLabel'),
              value: 'delivery',
            },
            {
              label: t('checkout.paymentForm.storePickupOptionLabel'),
              value: 'storepick',
            },
          ]}
          value={form.values.deliveryType}
          setValue={(selectedOption, event) => {
            event.preventDefault();

            form.setFieldValue('deliveryType', selectedOption);
          }}
          style={{
            mainContainer: {
              backgroundColor: mobile
                ? theme.colorsPalette.gray.lighter
                : 'transparent',
              margin: 'auto',
            },
            buttons: {
              color: osTheme.buttons.primary.fontColor,
              backgroundColor: lighten(
                0.1,
                osTheme.buttons.primary.backgroundColor
              ),
            },
          }}
        />
      )}

      {form.values.deliveryType === 'delivery' && (
        <>
          <TextInput
            id="deliveryAddress"
            name="deliveryAddress"
            type="text"
            value={form.values.deliveryAddress}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={t('checkout.paymentForm.deliveryAddressLabel')}
            colors={{
              highlight: darken(0.5, osTheme.buttons.primary.fontColor),
              base: darken(0.5, osTheme.buttons.primary.fontColor),
            }}
            style={{
              root: { margin: '16px 0 16px 0' },
              input: { borderBottomWidth: 1 },
            }}
          />

          <Select
            options={selectLocationOptions}
            value={form.values.deliveryLocation}
            onChange={(inSelectLocation) =>
              form.setFieldValue('deliveryLocation', inSelectLocation)
            }
            styles={paymentFormSelectStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={t('checkout.paymentForm.deliveryLocationPlaceholder')}
          />

          <Select
            options={selectZoneOptions}
            value={form.values.deliveryZone}
            onChange={(inSelectZone) =>
              form.setFieldValue('deliveryZone', inSelectZone)
            }
            styles={paymentFormSelectStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={t('checkout.paymentForm.deliveryZonePlaceholder')}
          />
        </>
      )}

      {((form.errors.deliveryZone && form.touched.deliveryZone) ||
        (form.errors.deliveryLocation && form.touched.deliveryLocation)) && (
        <Typography
          as="p"
          variant="paragraph1"
          style={{ color: theme.colorsPalette.red.default }}
        >
          {t('checkout.paymentForm.deliveryErrorLabel')}
        </Typography>
      )}

      {form.values.deliveryType === 'delivery' && (
        <TextInput
          type="text"
          id="deliveryNotes"
          name="deliveryNotes"
          value={form.values.deliveryNotes}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={t('checkout.paymentForm.deliveryNotesLabel')}
          colors={{
            highlight: darken(0.5, osTheme.buttons.primary.fontColor),
            base: darken(0.5, osTheme.buttons.primary.fontColor),
          }}
          style={{
            root: { margin: '16px 0' },
            input: { borderBottomWidth: 1 },
          }}
        />
      )}

      {mobile && (
        <CheckoutAmounts
          amounts={amounts}
          exclude={['subtotal', 'coupon', 'total']}
        />
      )}
    </>
  );
}

/**
 * Renders the payment methods and coupon code.
 */
export function PaymentFormGatewaysFields({
  form,
}: TPaymentFormGatewaysFieldsProps) {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const osTheme = useOSThemeAndContent();

  const { shopper } = useShopper();

  return (
    <>
      <Checkbox
        checked={form.values.paymentGateway === 'tbkOneClick'}
        onChange={(event) =>
          form.setFieldValue(
            'paymentGateway',
            !event.target.checked ? undefined : 'tbkOneClick'
          )
        }
        style={{ root: { margin: '8px 0' } }}
        custom
        disabled={!shopper}
      >
        <PaymentGatewayContainer
          checked={form.values.paymentGateway === 'tbkOneClick'}
          backgroundColor={osTheme.buttons.primary.backgroundColor}
          fontColor={osTheme.buttons.primary.fontColor}
        >
          <TbkOneClickIcon
            height="24px"
            style={{
              backgroundColor: 'white',
              padding: 8,
              borderRadius: 8,
              height: 24,
              width: 96,
            }}
          />

          <PaymentGatewayHeader>
            <Typography
              as="h1"
              variant="paragraph1"
              style={{
                color: mobile
                  ? osTheme.buttons.primary.backgroundColor
                  : osTheme.buttons.primary.fontColor,
                fontWeight: theme.fontWeights.bold,
              }}
            >
              {t('checkout.paymentForm.tbkOneClickGatewayTitle')}
            </Typography>

            <Typography
              variant="paragraph1"
              as="p"
              style={{ fontSize: theme.fontSizes.small, textAlign: 'end' }}
            >
              {!shopper
                ? 'Login to pay with only one click'
                : t('checkout.paymentForm.tbkOneClickGatewayDescription')}
            </Typography>
          </PaymentGatewayHeader>
        </PaymentGatewayContainer>
      </Checkbox>

      <Checkbox
        checked={form.values.paymentGateway === 'tbkWpp'}
        onChange={(event) =>
          form.setFieldValue(
            'paymentGateway',
            !event.target.checked ? undefined : 'tbkWpp'
          )
        }
        style={{ root: { margin: '16px 0' } }}
        custom
      >
        <PaymentGatewayContainer
          checked={form.values.paymentGateway === 'tbkWpp'}
          backgroundColor={osTheme.buttons.primary.backgroundColor}
          fontColor={osTheme.buttons.primary.fontColor}
        >
          <TbkWPPIcon
            height="18px"
            style={{
              backgroundColor: 'white',
              padding: 8,
              borderRadius: 8,
              height: 24,
              width: 96,
            }}
          />

          <PaymentGatewayHeader>
            <Typography
              as="h1"
              variant="paragraph1"
              style={{
                color: mobile
                  ? osTheme.buttons.primary.backgroundColor
                  : osTheme.buttons.primary.fontColor,
                fontWeight: theme.fontWeights.bold,
              }}
            >
              {t('checkout.paymentForm.tbkWppGatewayTitle')}
            </Typography>
          </PaymentGatewayHeader>
        </PaymentGatewayContainer>
      </Checkbox>
    </>
  );
}

/**
 * Renders the payment form coupon code input (field + apply btn).
 */
export function PaymentFormCouponDiscountFields({
  form,
}: TPaymentFormGatewaysFieldsProps) {
  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  const company = useCompany();

  const [getCouponQuery, { loading: loadingGetCouponQuery }] =
    useLazyGetCouponQuery();

  return (
    <>
      <TextInput
        id="couponCode"
        name="couponCode"
        value={form.values.couponCode}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        label={t('checkout.paymentForm.couponCodeLabel')}
        colors={{
          highlight: darken(0.5, osTheme.buttons.primary.fontColor),
          base: darken(0.5, osTheme.buttons.primary.fontColor),
        }}
        style={{
          root: { marginBottom: 16 },
          input: { borderBottomWidth: 1 },
        }}
      />

      {form.values.couponCode && (
        <PaymentFormButton
          onClick={(event) => {
            event.preventDefault();

            getCouponQuery({
              variables: {
                companyId: company._id,
                code: form.values.couponCode,
              },
            });
          }}
          backgroundColor={osTheme.buttons.primary.backgroundColor}
          fontColor={osTheme.buttons.primary.fontColor}
          style={{
            width: '100%',
            fontSize: 14,
            maxWidth: 'unset',
            marginBottom: 24,
          }}
        >
          {loadingGetCouponQuery ? (
            <Loader />
          ) : (
            t('checkout.paymentForm.applyCouponButton')
          )}
        </PaymentFormButton>
      )}
    </>
  );
}

/**
 * Renders payment form action buttons and error messages.
 */
export function PaymentFormFooter({
  activeStep,
  form,
  setActiveStep,
  loadingPayment,
}: {
  activeStep: number;
  form: TPaymentForm;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  loadingPayment: boolean;
}) {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const osTheme = useOSThemeAndContent();

  const stepButtons = getPaymentFormStepButtons({
    activeStep,
    setActiveStep,
    form,
    mobile,
  });

  return (
    <PaymentFormFooterContainer>
      {Object.keys(form.errors).length > 0 && activeStep === (mobile ? 3 : 2) && (
        <Typography
          as="p"
          variant="paragraph1"
          style={{
            color: theme.colorsPalette.red.default,
            marginBottom: 16,
          }}
        >
          {t('checkout.paymentForm.missingFieldsErrorLabel')}
        </Typography>
      )}

      <PaymentFormButtonsContainer>
        {activeStep > 0 && (
          <PaymentFormButton
            onClick={stepButtons[0].callback}
            backgroundColor={osTheme.buttons.primary.backgroundColor}
            fontColor={osTheme.buttons.primary.fontColor}
            style={{ marginRight: 8, width: '50%', fontSize: 14 }}
          >
            {stepButtons[0].label}
          </PaymentFormButton>
        )}

        <PaymentFormButton
          onClick={stepButtons[1].callback}
          type={activeStep === 2 ? 'submit' : undefined}
          backgroundColor={osTheme.buttons.primary.backgroundColor}
          fontColor={osTheme.buttons.primary.fontColor}
          style={{ width: '50%', fontSize: 14, alignItems: 'center' }}
        >
          {loadingPayment ? <Loader /> : stepButtons[1].label}
        </PaymentFormButton>
      </PaymentFormButtonsContainer>
    </PaymentFormFooterContainer>
  );
}
