export const DE_TRANSLATIONS: TLocaleTranslation = {
  api: {
    payments: {
      errorTitle: 'Fehler bei der Verarbeitung deine Zahlung',
      errorDescription:
        'Wir konnten deine Zahlung nicht verarbeiten. Bitte versuchst du es erneut oder wenden du sich an unser Support-Team.',
    },
  },
  shopperEntry: { button: 'Anmelden' },
  shopperSignUp: {
    title: 'Registrieren',
    notes:
      'Durch die Registrierung du kannst schneller einkaufen und neue Produkte und Kategorien entdecken, die du interessieren.',
    firstnameInputLabel: 'Vorname',
    lastnameInputLabel: 'Nachname',
    emailInputLabel: 'E-Mail-Adresse',
    passwordInputLabel: 'Kennwort',
    submitButton: 'Benutzerkonto erstellen',
    switchToSignInDescription: 'Du bist bereits Mitglied?',
    switchToSignInLink: 'Anmelden.',
  },
  shopperSignIn: {
    title: 'Anmelden',
    emailInputLabel: 'E-Mail-Adresse',
    passwordInputLabel: 'Kennwort',
    submitButton: 'Anmelden',
    switchToSignUpDescription: 'Noch kein Mitglied?',
    switchToSignUpLink: 'Sei dabei.',
  },
  shopperBrowser: {
    loadingShopperDescription: 'Dein Konto wird geladen...',
    sidebar: {
      profileLabel: 'Profile',
      ordersLabel: 'Meine Bestellungen',
      accountLabel: 'Konto',
      signOutLabel: 'Konten wechseln',
    },
    profile: {
      firstnameLabel: 'Vorname',
      lastnameLabel: 'Nachname',
      idLabel: 'ID',
      phoneLabel: 'Telefonnummer',
      locationAddressLabel: 'Adresse',
      saveButton: 'Änderungen speichern',
    },
    orders: {
      emptyOrdersTitle: 'Du hast noch keine Bestellungen',
      emptyOrdersDescription:
        'Sobald du ein Produkt gekauft habe, wird deine Bestellung hier angezeigt!',
      debitCardName: 'Debitkarte',
      creditCardName: 'Kreditkarte',
      productsQtyLabel: '{{productsQty}} Artikel.',
    },
    account: {
      title: 'Passwort',
      description:
        'Dein Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, eine Ziffer und einen Kleinbuchstaben.',
      currentPwLabel: 'Aktuelles Passwort',
      newPwLabel: 'Passwort',
      saveButton: 'Änderungen speichern',
    },
  },
  topbar: {
    searchPlaceholder: 'Suchen',
  },
  productList: {
    filters: {
      showToggleButton: 'Filter anzeigen',
      hideToggleButton: 'Filter verbergen',
      mobileToggleButton: 'Filtern',
      mobileTitle: 'Produkte filtern',
      sortByLabel: 'Sortieren nach',
      sortByOptionLabels: {
        nameAsc: 'Name, A-Z',
        nameDesc: 'Name, Z-A',
        priceAsc: 'Preis, Niedrig-Hoch',
        priceDesc: 'Preis, Hoch-Niedrig',
        createdAtAsc: 'Datum, zuletzt-neu',
        createdAtDesc: 'Datum, neu-zuletzt',
      },
      filterByDiscountTitle: 'Rabatte',
      filterByDiscountLabel: 'Nur rabatte',
      priceIntervalTitle: 'Preis',
      cleanupButton: 'Filtern löschen',
    },
    fetchMoreButton: 'Weitere Produkte anzeigen',
    productsNotFoundTitle: 'Wir haben keine Produkte gefunden',
    productsNotFoundDescription: 'Versuchst dir es mit einer anderen Suche!',
    errorLoadingProductsLabel: 'Fehler beim Laden der Produkte!',
  },
  productDetails: {
    outOfStockLabel: 'Nicht vorrättig',
    selectVariantPlaceholder: 'Wählst du eine Variante aus',
    addToCartButton: 'In den Einkaufswagen',
  },
  productsSearchResults: {
    headTitle: 'Suche in Produkten',
    title: 'Vorschlägen für "{{searchTerm}}"',
  },
  shoppingCart: {
    addProductsSnackbar: {
      successTitle: 'Zum Warenkorb hinzugefügt',
      successDescription: 'Deine Produkt wurde dem Warenkorb hinzugefügt!',
      errorTitle: 'Nicht vorrättig!',
      errorDescription:
        'Die ausgewählte Menge liegt über dem verfügbaren Bestand.',
    },
  },
  shoppingCartDetailsBar: {
    emptyStateParagraph: 'Es befinden sich keine Produkte im Warenkorb.',
    subTotalLabel: 'Zwischensumme:',
    goToCheckoutButton: 'Zur Kasse gehen',
  },
  checkout: {
    shoppingCartDetails: {
      title: 'Kasse',
      continueShoppingButton: 'Zurück zum Shop',
      subtotalLabel: 'Zwischensumme',
      shippingCostsLabel: 'Verpackung & Versand',
      couponLabel: 'Aktionscode',
      totalAmountLabel: 'Gesamt',
    },
    paymentForm: {
      firstnameLabel: 'Vorname',
      lastnameLabel: 'Nachname',
      emailLabel: 'E-Mail-Adresse',
      phoneLabel: 'Telefonnummer',
      idLabel: 'ID',
      deliveryOptionLabel: 'Lieferung an Adresse',
      storePickupOptionLabel: 'Abholung',
      deliveryAddressLabel: 'Versandadresse',
      deliveryLocationPlaceholder: 'Land/Region',
      deliveryZonePlaceholder: 'Ort',
      deliveryNotesLabel: 'Lieferanweisungen',
      deliveryErrorLabel:
        'Wir müssen wissen, wohin wir Ihre Produkte liefern sollen!',
      tbkOneClickGatewayTitle: 'Webpay Oneclick',
      tbkOneClickGatewayDescription: 'Bezahlen Sie mit einem Klick',
      tbkWppGatewayTitle: 'Webpay plus',
      notSelectedDeliveryLocationOptionLabel: 'Wählen dir eine Region aus',
      couponCodeLabel: 'Aktionscode',
      applyCouponButton: 'Anwenden',
      missingFieldsErrorLabel: 'Einige Felder sind noch nicht ausgefüllt!',
      steps: {
        contactTitle: 'Kontakt',
        deliveryTitle: 'Lieferung',
        paymentTitle: 'Zahlung',
        summaryTitle: 'Zusammenfassung',
        goBackButton: 'Zurück',
        goNextButton: 'Weiter',
        payButton: 'Zahlung',
      },
    },
  },
  paymentResult: {
    invalidTokenTitle: 'Oops',
    invalidTokenDescription:
      'Die gesuchte Seite existiert nicht. Kehrst du zu unserer Startseite zurück, um mit dem Einkaufen fortzufahren!',
    invalidTokenActionMessage: 'Zurück zum Shop',
    failedPaymentTitle: 'Oops',
    failedPaymentDescription:
      'Bei der Verarbeitung deine Zahlung ist ein Fehler aufgetreten! Bitte versuchst du es erneut oder kontaktieren du uns, um du zu helfen.',
    failedPaymentActionMessage: 'Zurück zum Shop',
    cancelledPaymentTitle: 'Bestellung storniert',
    cancelledPaymentDescription:
      'Deine Kauf wurde erfolgreich storniert. Kehrst du zu unserer Homepage zurück, um mit dem Einkaufen fortzufahren!',
    cancelledPaymentActionMessage: 'Zurück zum Shop',
    succeedPaymentTitle: 'Danke für deine Bestellung!',
    succeedPaymentDescription:
      'Deine Bestellung wurde erfolgreich bearbeitet. Wir senden eine E-Mail mit dem Eingang der Bestellung an {{shopperEmail}}.',
    succeedPaymentActionMessage: 'Zurück zum Shop',
  },
  localesSwitcher: {
    title: 'Spracheinstellungen',
    description:
      'Wähle dir die Sprache, die dir für das Surfen, Einkaufen und die Kommunikation bevorzugen.',
  },
};
