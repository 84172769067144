import { useFormik } from 'formik';
import { Avatar, TextInput } from 'kosmos-ui/lib';

import { useShopper } from 'app/context/ShopperContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useUpdateMyShopperProfileMutation } from 'api/graphql/mutations/shopper/hooks';
import {
  SectionContainer,
  SectionHeader,
  GroupedInputsContainer,
  Form,
} from '../../styles';
import { Button } from 'app/components';

import { TUpdateMyShopperProfileFormValues } from './types';
import { AvatarContainer, AvatarSubHeader } from './styles';
import { useTranslation } from 'react-i18next';

export default function ShopperProfileSection() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const { shopper } = useShopper();

  const [
    updateMyShopperProfileMutation,
    { loading: loadingUpdateMyShopperProfileMutation },
  ] = useUpdateMyShopperProfileMutation();

  const form = useFormik<TUpdateMyShopperProfileFormValues>({
    initialValues: {
      firstname: shopper?.firstname,
      lastname: shopper?.lastname,
      rut: shopper?.rut || '',
      phone: shopper?.phone || '',
      locationAddress:
        (shopper?.locations &&
          shopper.locations.length > 0 &&
          shopper.locations[0].address) ||
        '',
    },
    onSubmit({ locationAddress, ...values }) {
      updateMyShopperProfileMutation({
        variables: {
          ...values,
          locations: [
            {
              city: '',
              commune: '',
              address: locationAddress,
            },
          ],
        },
      });
    },
  });

  return (
    <SectionContainer style={{ maxWidth: 380 }}>
      <AvatarContainer>
        <Avatar
          withInitials
          height={mobile ? 40 : 64}
          style={{ marginBottom: 12 }}
        >
          {shopper?.firstname} {shopper?.lastname}
        </Avatar>

        <SectionHeader>
          {shopper?.firstname} {shopper?.lastname}
        </SectionHeader>

        <AvatarSubHeader>{shopper?.email}</AvatarSubHeader>
      </AvatarContainer>

      <Form onSubmit={form.handleSubmit} noValidate>
        <GroupedInputsContainer>
          <TextInput
            name="firstname"
            value={form.values.firstname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={t('shopperBrowser.profile.firstnameLabel')}
            variant="filled"
            style={{ root: { marginRight: 16 } }}
          />

          <TextInput
            name="lastname"
            value={form.values.lastname}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={t('shopperBrowser.profile.lastnameLabel')}
            variant="filled"
          />
        </GroupedInputsContainer>

        <GroupedInputsContainer>
          <TextInput
            name="rut"
            value={form.values.rut}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={t('shopperBrowser.profile.idLabel')}
            variant="filled"
            style={{ root: { marginRight: 16 } }}
          />

          <TextInput
            name="phone"
            value={form.values.phone}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            label={t('shopperBrowser.profile.phoneLabel')}
            variant="filled"
          />
        </GroupedInputsContainer>

        <TextInput
          name="locationAddress"
          value={form.values.locationAddress}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={t('shopperBrowser.profile.locationAddressLabel')}
          variant="filled"
        />

        <Button
          variant="primary"
          type="submit"
          loading={loadingUpdateMyShopperProfileMutation}
          style={{ width: '100%', marginTop: 24 }}
        >
          {t('shopperBrowser.profile.saveButton')}
        </Button>
      </Form>
    </SectionContainer>
  );
}
