import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { theme, CarretIcon } from 'kosmos-ui';

import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import helpers from 'app/helpers';
import { Button } from 'app/components';
import Typography from 'app/components/Typography';
import { TShoppingCartProductToRender } from 'app/context/ShoppingCartContext/types';
import { ROUTES } from 'app/constants/app';
import CheckoutNotes from '../CheckoutNotes';
import CheckoutAmounts from '../CheckoutAmounts';

import { TCheckoutCartDetailsProps } from './types';
import {
  CheckoutCartDetailsContainer,
  CheckoutCartProductsContainer,
  CheckoutCartProductInnerContainer,
  CheckoutCartProductsInfoContainer,
  CheckoutCartProductImage,
  CheckoutCartProductNameContainer,
  CheckoutCartTotalTypography,
  CheckoutCartButtonsContainer,
  CheckoutCartPaymentDetailsContainer,
  CheckoutGoBackTypography,
  CheckoutCartFooter,
} from './styles';

/**
 * Checkout cart details (products and amounts).
 */
export default function CheckoutCartDetails({
  amounts,
  checkoutNotes,
}: TCheckoutCartDetailsProps) {
  const {
    shoppingCart,
    dispatchShoppingCart,
    getShoppingCartProductsToRender,
  } = useShoppingCart();

  const osTheme = useOSThemeAndContent();

  const { t } = useTranslation();

  const { mobile } = useLayout();

  const history = useHistory();

  function handleAddToCartClick(product: TShoppingCartProductToRender): void {
    if (!product.mixinName && !product.mixinId) {
      return dispatchShoppingCart({
        type: 'add',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: product.category,
        },
      });
    } else if (product.mixinName && product.mixinId) {
      return dispatchShoppingCart({
        type: 'addWithVariant',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          name: product.name,
          category: product.category,
          price: product.price,
          mixinId: product.mixinId,
          mixinName: product.mixinName,
        },
      });
    }
  }

  function handleRemoveFromCartClick(
    product: TShoppingCartProductToRender
  ): void {
    if (!product.mixinName && !product.mixinId) {
      return dispatchShoppingCart({
        type: 'remove',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: product.category,
        },
      });
    } else if (product.mixinName && product.mixinId) {
      return dispatchShoppingCart({
        type: 'removeWithVariant',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          name: product.name,
          category: product.category,
          price: product.price,
          mixinId: product.mixinId,
          mixinName: product.mixinName,
        },
      });
    }
  }

  const shoppingCartProducts = getShoppingCartProductsToRender(
    shoppingCart.products
  );

  return (
    <CheckoutCartDetailsContainer>
      {!mobile && (
        <>
          <Typography as="h1" variant="title2">
            {t('checkout.shoppingCartDetails.title')}
          </Typography>

          <CheckoutNotes checkoutNotes={checkoutNotes} />
        </>
      )}

      <CheckoutCartProductsContainer>
        {shoppingCartProducts.map((product, index) => {
          return (
            <CheckoutCartProductInnerContainer key={`${product._id}-${index}`}>
              <CheckoutCartProductsInfoContainer>
                <CheckoutCartProductImage src={product.image} />

                <CheckoutCartProductNameContainer>
                  <Typography
                    as="p"
                    variant="paragraph1"
                    style={{ color: theme.colorsPalette.black.default }}
                  >
                    {product.name}
                  </Typography>

                  {product.mixinId && product.mixinName && (
                    <Typography as="p" variant="paragraph1">
                      {product.mixinName}
                    </Typography>
                  )}

                  <Typography as="p" variant="paragraph1">
                    ${helpers.formatNumber(product.price)}
                  </Typography>

                  {mobile && (
                    <CheckoutCartButtonsContainer>
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();

                          handleRemoveFromCartClick(product);
                        }}
                      >
                        -
                      </Button>

                      <Typography
                        as="p"
                        variant="paragraph1"
                        style={{
                          width: 32,
                          display: 'flex',
                          justifyContent: 'center',
                          color: theme.colorsPalette.black.default,
                        }}
                      >
                        {product.qty}
                      </Typography>

                      <Button
                        variant="secondary"
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();

                          handleAddToCartClick(product);
                        }}
                      >
                        +
                      </Button>
                    </CheckoutCartButtonsContainer>
                  )}
                </CheckoutCartProductNameContainer>
              </CheckoutCartProductsInfoContainer>

              {!mobile && (
                <CheckoutCartButtonsContainer>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => handleRemoveFromCartClick(product)}
                  >
                    -
                  </Button>

                  <Typography
                    as="p"
                    variant="paragraph1"
                    style={{
                      width: 32,
                      display: 'flex',
                      justifyContent: 'center',
                      color: theme.colorsPalette.black.default,
                    }}
                  >
                    {product.qty}
                  </Typography>

                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => handleAddToCartClick(product)}
                  >
                    +
                  </Button>
                </CheckoutCartButtonsContainer>
              )}

              <CheckoutCartTotalTypography
                fontStyle={osTheme.fonts.paragraphsFont}
              >
                ${helpers.formatNumber(product.subtotal)}
              </CheckoutCartTotalTypography>
            </CheckoutCartProductInnerContainer>
          );
        })}
      </CheckoutCartProductsContainer>

      {!mobile && (
        <CheckoutCartFooter>
          {!mobile && (
            <Button
              onClick={() => history.push(ROUTES.home)}
              variant="primary"
              format="outline"
            >
              <CarretIcon direction="left" height="12px" />{' '}
              <CheckoutGoBackTypography>
                {t('checkout.shoppingCartDetails.continueShoppingButton')}
              </CheckoutGoBackTypography>
            </Button>
          )}

          <CheckoutCartPaymentDetailsContainer>
            <CheckoutAmounts amounts={amounts} />
          </CheckoutCartPaymentDetailsContainer>
        </CheckoutCartFooter>
      )}
    </CheckoutCartDetailsContainer>
  );
}
