import styled from 'styled-components';
import { TSelectProps } from 'kosmos-ui/lib/components/Select/types';
import { theme } from 'kosmos-ui/lib';

export const ProductsListContainer = styled.div`
  position: relative;
  flex: 1;
  text-align: center;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};
`;

export const ProductsListItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 32px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }
`;

export const ProductsListFiltersContainer = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
  }
`;

export const ProductsListMask = styled.div`
  width: calc(100%);
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
`;

export const ProductsListSortByContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProductsListFetchMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

export const ProductsListMobileSidebarHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const ProductFiltersSidebarContainer = styled.aside<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 64px 56px;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};
  min-height: calc(100% - 128px);
  min-width: 136px;
  max-width: 240px;
  transform: ${({ show }) => (!show ? 'translateX(-100%)' : 'translateX(0)')};
  transition: transform 0.3s ease-out;
  z-index: 100;

  @media (max-width: 600px) {
    min-width: calc(100% - 48px);
    padding: 24px;
    height: 100%;
  }
`;

export function getProductsListSortBySelectStyles({
  optionSelected,
  mobile,
}: {
  optionSelected: boolean;
  mobile?: boolean;
}): ValueOf<Pick<TSelectProps, 'style'>> {
  return {
    container: {
      width: optionSelected ? (mobile ? 200 : 168) : 24,
      borderBottom: 'none',
      '&:hover': {
        borderBottom: 'none',
      },
    },

    singleValue: {
      color: theme.colorsPalette.black.superLight,
    },

    control: {
      backgroundColor: 'transparent',
    },

    dropdownIndicator: {
      color: theme.colorsPalette.black.default,
      '&:hover': {
        color: theme.colorsPalette.black.default,
      },
    },

    indicatorContainer: {
      padding: 0,
    },

    menu: {
      position: 'absolute',
      right: !mobile ? 0 : 'unset',
      zIndex: 9999,
    },
  };
}
