import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, CarretIcon, Skeleton } from 'kosmos-ui';

import { useShopper } from 'app/context/ShopperContext/hooks';
import { Button } from 'app/components';
import { ROUTES } from 'app/constants/app';

export default function ShopperEntry() {
  const location = useLocation();

  const { t } = useTranslation();

  const { shopper, loadingShopperData } = useShopper();

  if (loadingShopperData) return <Skeleton height={36} variant="circle" />;

  if (!shopper)
    return (
      <Link
        to={{
          pathname: ROUTES.shopperSignIn,
          state: { background: location },
        }}
        style={{ textDecoration: 'none' }}
      >
        <Button
          as="div"
          variant="primary"
          style={{ padding: '8px 16px', fontSize: 12, minWidth: 'unset' }}
        >
          {t('shopperEntry.button')}

          <CarretIcon height="10px" style={{ margin: '0 0 -2px 6px' }} />
        </Button>
      </Link>
    );

  return (
    <Link
      to={{
        pathname: ROUTES.shopperBrowser,
        state: { background: location },
      }}
      style={{ textDecoration: 'none' }}
    >
      <Avatar withInitials>
        {shopper.firstname} {shopper.lastname}
      </Avatar>
    </Link>
  );
}
