import dayjs from 'dayjs';
import localization from 'dayjs/locale/es';

/**
 * Formats a number by including dots and commas.
 * @param number - Plain-format number that wants to be converted.
 * @returns - The provided number as formatted string.
 */
export function formatNumber(number: number): string {
  const converterRegex = /(\d)(?=(\d{3})+(?!\d))/g;

  const parsedNumber = Math.floor(number).toString();

  return parsedNumber.replace(converterRegex, '$1.');
}

/**
 * Formats a date with ES / DD-MM-YY format.
 * @param date - Date to format.
 * @returns - Formatted date.
 */
export function formatDate(
  date: Date,
  format: string | undefined = 'DD-MM-YY'
): string {
  const formattedDate = dayjs(date).locale('es', localization).format(format);

  return formattedDate;
}
