import { Checkbox as BaseCheckbox } from 'kosmos-ui';
import { TCheckboxProps } from 'kosmos-ui/lib/components/Checkbox/types';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

export default function Checkbox({
  style: baseStyle,
  ...props
}: TCheckboxProps) {
  const osTheme = useOSThemeAndContent();

  const color = osTheme.buttons.primary.backgroundColor;
  const labelStyles = { fontFamily: osTheme.fonts.paragraphsFont.family };

  return (
    <BaseCheckbox
      color={color}
      style={{ label: labelStyles, ...baseStyle }}
      {...props}
    />
  );
}
