import { useState } from 'react';
import get from 'lodash/get';

import ShopBackdrop from './components/Backdrop';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import ShoppingCartDetailsBar from 'app/sections/Navigation/ShoppingCartDetailsBar';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import AnnouncementBar from './components/AnnouncementBar';

import { NavigationContainer } from './styles';

/**
 * Navigation of the app, that includes top and side bars.
 */
export default function Navigation() {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showShoppingCartDetailsBar, setShowShoppingCartDetailsBar] =
    useState<boolean>(false);

  const {
    navbar: { navlink, orientation: navbarOrientation },
  } = useOSThemeAndContent();

  const { shoppingCart } = useShoppingCart();

  const { mobile } = useLayout();

  function toggleShowSidebar() {
    setShowSidebar(!showSidebar);
  }

  function toggleShowShoppingCartDetailsBar() {
    setShowShoppingCartDetailsBar(!showShoppingCartDetailsBar);
  }

  const shoppingCartQty = Object.values(shoppingCart.products).reduce(
    (acc, product) => {
      const productQty = product.variants
        ? product.variants.reduce((acc, variant) => {
            return acc + variant.qty;
          }, 0)
        : (product.qty as unknown as number) || 0;

      return acc + productQty;
    },
    0
  );

  const navlinks: Distinct<TOSNavlink>[] = (
    get(navlink, 'children', []) as Distinct<TOSNavlink>[]
  ).reduce((acc, children) => {
    if (children.link || (children.children && children.children.length > 0)) {
      return [...acc, children];
    }

    return acc;
  }, [] as Distinct<TOSNavlink>[]);

  return (
    <NavigationContainer>
      <AnnouncementBar />

      <Topbar
        toggleShowSidebar={toggleShowSidebar}
        toggleShowShoppingCartDetailsBar={toggleShowShoppingCartDetailsBar}
        shoppingCartQty={shoppingCartQty}
        navlinks={navbarOrientation === 'horizontal' ? navlinks : undefined}
      />

      {(navbarOrientation === 'vertical' || mobile) && (
        <Sidebar
          show={showSidebar}
          toggleShow={toggleShowSidebar}
          navlinks={navlinks}
        />
      )}

      <ShoppingCartDetailsBar
        show={showShoppingCartDetailsBar}
        toggleShow={toggleShowShoppingCartDetailsBar}
      />

      {(navbarOrientation === 'vertical' || mobile) &&
        (showSidebar || showShoppingCartDetailsBar) && (
          <ShopBackdrop
            showSidebar={showSidebar}
            showShoppingCartDetailsBar={showShoppingCartDetailsBar}
            toggleShowSidebar={toggleShowSidebar}
            toggleShowShoppingCartDetailsBar={toggleShowShoppingCartDetailsBar}
          />
        )}
    </NavigationContainer>
  );
}
