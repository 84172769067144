import { createContext, ReactNode, useEffect, useState } from 'react';
import i18next from 'i18next';

import { useGetOSLocalesQuery } from 'api/graphql/queries/online-store/locales/hooks';

import { TOSLocalesContextValues } from './types';

export const OSLocalesContext = createContext({} as TOSLocalesContextValues);

export function OSLocalesContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [i18nInit, setI18nInit] = useState(false);

  const { data: osLocalesData } = useGetOSLocalesQuery();

  const osLocales = osLocalesData?.getOSLocales;

  const osLocalesActive = osLocales?.active;

  const osDefaultLanguageCode = osLocales?.defaultLanguageCode;

  useEffect(() => {
    if (osLocalesActive === false && !i18nInit) {
      setI18nInit(true);

      i18next.changeLanguage(osDefaultLanguageCode);
    }
  }, [osLocalesActive, osDefaultLanguageCode, i18nInit, setI18nInit]);

  if (!osLocales) return null;

  return (
    <OSLocalesContext.Provider value={osLocales}>
      {children}
    </OSLocalesContext.Provider>
  );
}
