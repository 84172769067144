import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-x: scroll;
  height: 88px;

  @media (max-width: 600px) {
    padding: 16px 0;
  }
`;
