import {
  TShoppingCartVariant,
  TShoppingCartProductToRender,
  TShoppingCartProduct,
  TShoppingCartAction,
  TShoppingCartState,
  TShoppingCartFlags,
  TShoppingCartActionProduct,
} from './types';

/**
 * Updates the shopping cart state based on the provided action.
 * @param state The current state of the shopping cart.
 * @param action The action to be performed (add, remove or reset).
 */
function shoppingCartReducer(
  state: TShoppingCartState,
  action: TShoppingCartAction
): TShoppingCartState {
  switch (action.type) {
    case 'setup':
      state = action.cachedShoppingCart;

      return { ...state, coupon: undefined, flags: {} as TShoppingCartFlags };

    case 'add':
      return addToShoppingCart(action, state);

    case 'addWithVariant':
      return addWithVariantToShoppingCart(
        action as Omit<TShoppingCartAction, 'mixinName' | 'mixinId'> & {
          mixinName: string;
          mixinId: string;
        },
        state
      );

    case 'remove':
      return removeFromShoppingCart(action, state);

    case 'removeWithVariant':
      return removeWithVariantToShoppingCart(
        action,
        state as Omit<TShoppingCartState, 'variants'> & {
          variants: TShoppingCartVariant[];
        }
      );

    case 'reset':
      // eslint-disable-next-line no-case-declarations
      const resetState = { products: {}, flags: {} } as TShoppingCartState;

      localStorage.setItem('grafitoShoppingCart', JSON.stringify(resetState));

      return resetState;

    case 'addCoupon':
      return { ...state, coupon: action.coupon };

    default:
      localStorage.setItem('grafitoShoppingCart', JSON.stringify(state));

      return state;
  }
}

// Resolver to add a product to the shopping cart when there
// ARE NOT VARIANTS involved.
function addToShoppingCart(
  action: TShoppingCartAction,
  prevState: TShoppingCartState
): TShoppingCartState {
  const prevProduct =
    prevState.products[(action.product as TShoppingCartActionProduct)._id];

  const canBeAdded = prevProduct
    ? !((prevProduct.stock as number) - (action.qty as number) < 0)
    : true;

  const subtotal =
    (action.product as TShoppingCartActionProduct).price *
    ((prevProduct ? (prevProduct.qty as number) : 0) +
      (canBeAdded ? (action.qty as number) : 0));

  const products = {
    ...prevState.products,
    [(action.product as TShoppingCartActionProduct)._id]: prevProduct
      ? {
          ...prevProduct,
          qty:
            (prevProduct.qty as number) +
            ((prevProduct.stock as number) - (action.qty as number) < 0
              ? 0
              : (action.qty as number)),
          subtotal,
          stock:
            (prevProduct.stock as number) - (action.qty as number as number) >=
            0
              ? (prevProduct.stock as number) - (action.qty as number as number)
              : prevProduct.stock,
          price: (action.product as TShoppingCartActionProduct).price,
          image: (action.product as TShoppingCartActionProduct).image,
          sku: (action.product as TShoppingCartActionProduct).sku,
          name: (action.product as TShoppingCartActionProduct).name,
          category: (action.product as TShoppingCartActionProduct).category,
        }
      : {
          qty: action.qty as number,
          subtotal,
          stock:
            ((action.product as TShoppingCartActionProduct).stock as number) -
            (action.qty as number),
          name: (action.product as TShoppingCartActionProduct).name,
          price: (action.product as TShoppingCartActionProduct).price,
          category: (action.product as TShoppingCartActionProduct).category,
          image: (action.product as TShoppingCartActionProduct).image,
          sku: (action.product as TShoppingCartActionProduct).sku,
        },
  };

  const state: TShoppingCartState = {
    ...prevState,
    flags: { additionResult: canBeAdded },
    products,
  };

  localStorage.setItem('grafitoShoppingCart', JSON.stringify(state));

  return state;
}

// Resolver to remove a product from the shopping cart when there
// ARE NOT VARIANTS involved.
function removeFromShoppingCart(
  action: TShoppingCartAction,
  prevState: TShoppingCartState
): TShoppingCartState {
  const prevProduct =
    prevState.products[(action.product as TShoppingCartActionProduct)._id];

  if (!prevProduct) {
    localStorage.setItem('grafitoShoppingCart', JSON.stringify(prevState));

    return prevState;
  }

  const qty = (prevProduct.qty as number) - (action.qty as number);

  const subtotal =
    (action.product as TShoppingCartActionProduct).price *
    ((prevProduct ? (prevProduct.qty as number) : 0) - (action.qty as number));

  const stock = (prevProduct.stock as number) + (action.qty as number);

  if (qty === 0) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [(action.product as TShoppingCartActionProduct)._id]: productToRemoveId,
      ..._newProductsState
    } = prevState.products;

    const newProductsState = _newProductsState;

    const newRemoveState = { ...prevState, products: newProductsState };

    localStorage.setItem('grafitoShoppingCart', JSON.stringify(newRemoveState));

    return newRemoveState;
  }

  const newRemoveState = {
    ...prevState,
    products: {
      ...prevState.products,
      [(action.product as TShoppingCartActionProduct)._id]: {
        ...prevProduct,
        qty,
        subtotal,
        stock,
        image: (action.product as TShoppingCartActionProduct).image,
        sku: (action.product as TShoppingCartActionProduct).sku,
        price: (action.product as TShoppingCartActionProduct).price,
        name: (action.product as TShoppingCartActionProduct).name,
        category: (action.product as TShoppingCartActionProduct).category,
      },
    },
  };

  localStorage.setItem('grafitoShoppingCart', JSON.stringify(newRemoveState));

  return newRemoveState;
}

// Resolver to add a product to the shopping cart when there
// ARE VARIANTS involved.
function addWithVariantToShoppingCart(
  action: Omit<TShoppingCartAction, 'mixinName' | 'mixinId'> & {
    mixinName: string;
    mixinId: string;
  },
  prevState: TShoppingCartState
): TShoppingCartState {
  const prevProduct =
    prevState.products[(action.product as TShoppingCartActionProduct)._id];

  const variants = prevProduct
    ? getVariantsToAddToShoppingCart(
        prevProduct.variants as TShoppingCartVariant[],
        {
          mixinId: (action.product as TShoppingCartActionProduct)
            .mixinId as string,
          mixinName: (action.product as TShoppingCartActionProduct)
            .mixinName as string,
          qty: action.qty as number,
          stock: (action.product as TShoppingCartActionProduct).stock as number,
          sku: (action.product as TShoppingCartActionProduct).sku,
          price: (action.product as TShoppingCartActionProduct).price as number,
        }
      )
    : {
        newVariants: [
          {
            mixinId: (action.product as TShoppingCartActionProduct)
              .mixinId as string,
            mixinName: (action.product as TShoppingCartActionProduct)
              .mixinName as string,
            qty: action.qty as number,
            subtotal:
              ((action.product as TShoppingCartActionProduct).price as number) *
              (action.qty as number),
            stock:
              ((action.product as TShoppingCartActionProduct).stock as number) -
              (action.qty as number),
            sku: (action.product as TShoppingCartActionProduct).sku,
            price: (action.product as TShoppingCartActionProduct)
              .price as number,
          },
        ],
        canBeAdded:
          ((action.product as TShoppingCartActionProduct).stock as number) -
            (action.qty as number) >=
          0,
      };

  const state: TShoppingCartState = {
    ...prevState,
    flags: { additionResult: variants.canBeAdded },
    products: {
      ...prevState.products,
      [(action.product as TShoppingCartActionProduct)._id]: prevProduct
        ? {
            ...prevProduct,
            name: (action.product as TShoppingCartActionProduct).name,
            category: (action.product as TShoppingCartActionProduct).category,
            image: (action.product as TShoppingCartActionProduct).image,
            variants: variants.newVariants,
          }
        : {
            name: (action.product as TShoppingCartActionProduct).name,
            category: (action.product as TShoppingCartActionProduct).category,
            image: (action.product as TShoppingCartActionProduct).image,
            variants: variants.newVariants,
          },
    },
  };

  localStorage.setItem('grafitoShoppingCart', JSON.stringify(state));

  return state;
}

// Resolver to remove a product to the shopping cart when there
// ARE VARIANTS involved.
function removeWithVariantToShoppingCart(
  action: TShoppingCartAction,
  prevState: Omit<TShoppingCartState, 'variants'> & {
    variants: TShoppingCartVariant[];
  }
): TShoppingCartState {
  const prevProduct =
    prevState.products[(action.product as TShoppingCartActionProduct)._id];

  const prevProductVariant = (
    prevProduct.variants as TShoppingCartVariant[]
  ).find(
    (variant) =>
      variant.mixinId === (action.product as TShoppingCartActionProduct).mixinId
  );

  if (!prevProduct || !prevProductVariant) {
    localStorage.setItem('grafitoShoppingCart', JSON.stringify(prevState));

    return prevState;
  }

  const qty = prevProductVariant.qty - (action.qty as number);

  const subtotal =
    prevProductVariant.subtotal -
    (action.product as TShoppingCartActionProduct).price *
      (action.qty as number);

  const stock = prevProductVariant.stock + (action.qty as number);

  if (qty === 0) {
    let newProductsState;

    const newProductVariants = (
      prevProduct.variants as TShoppingCartVariant[]
    ).filter((variant) => {
      return (
        variant.mixinId !==
        (action.product as TShoppingCartActionProduct).mixinId
      );
    });

    if (newProductVariants.length === 0) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [(action.product as TShoppingCartActionProduct)._id]: productToRemoveId,
        ..._newProductsState
      } = prevState.products;

      newProductsState = _newProductsState;
    } else {
      newProductsState = {
        ...prevState.products,
        [(action.product as TShoppingCartActionProduct)._id]: {
          ...prevProduct,
          variants: newProductVariants,
        },
      };
    }

    const newRemoveState = { ...prevState, products: newProductsState };

    localStorage.setItem('grafitoShoppingCart', JSON.stringify(newRemoveState));

    return newRemoveState;
  }

  const newRemoveState = {
    ...prevState,
    products: {
      ...prevState.products,
      [(action.product as TShoppingCartActionProduct)._id]: {
        ...prevProduct,
        variants: (prevProduct.variants as TShoppingCartVariant[]).map(
          (variant) => {
            if (
              variant.mixinId ===
              (action.product as TShoppingCartActionProduct).mixinId
            )
              return {
                ...variant,
                qty,
                subtotal,
                stock,
              };

            return variant;
          }
        ),
      },
    },
  };

  localStorage.setItem('grafitoShoppingCart', JSON.stringify(newRemoveState));

  return newRemoveState;
}

/**
 * Gets the variants to add to the shopping cart.
 * @param prevVariants The previous variants of the product.
 * @param currentVariant The variant to be added.
 * @returns A new array of variants for a product in the
 * shopping cart.
 */
function getVariantsToAddToShoppingCart(
  prevVariants: TShoppingCartVariant[],
  currentVariant: Omit<TShoppingCartVariant, 'subtotal'>
): { newVariants: TShoppingCartVariant[]; canBeAdded: boolean } {
  let variantAlreadyExist = false;

  let canBeAdded = true;

  let newVariants = prevVariants.reduce((acc, prevVariant) => {
    if (prevVariant.mixinId === currentVariant.mixinId) {
      variantAlreadyExist = true;

      canBeAdded = prevVariant.stock - currentVariant.qty >= 0;

      return [
        ...acc,
        {
          ...currentVariant,
          qty:
            prevVariant.qty +
            (prevVariant.stock - currentVariant.qty < 0
              ? 0
              : currentVariant.qty),
          subtotal:
            prevVariant.subtotal +
            (canBeAdded ? currentVariant.qty * currentVariant.price : 0),
          stock: canBeAdded
            ? prevVariant.stock - currentVariant.qty
            : prevVariant.stock,
        },
      ];
    }

    return [...acc, prevVariant];
  }, [] as TShoppingCartVariant[]);

  if (!variantAlreadyExist)
    newVariants = [
      ...newVariants,
      {
        ...currentVariant,
        stock: currentVariant.stock - currentVariant.qty,
        subtotal: currentVariant.price * currentVariant.qty,
      },
    ];

  return { newVariants, canBeAdded };
}

/**
 * Gets a ready-to-render version of the shopping cart (array, not hash
 * table of products).
 * @param shoppingCartProducts The current context shopping cart products.
 * @returns An array of the products in the shopping cart.
 */
function getShoppingCartProductsToRender(shoppingCartProducts: {
  [key: string]: TShoppingCartProduct;
}): TShoppingCartProductToRender[] {
  let products = [] as any;

  Object.keys(shoppingCartProducts).forEach((productId) => {
    const product = shoppingCartProducts[productId];

    if (product.variants) {
      return (products = [
        ...products,
        ...(product.variants as unknown as TShoppingCartVariant[]).map(
          (variant) => {
            return {
              _id: productId,
              name: product.name,
              image: product.image,
              sku: product.sku,
              price: variant.price,
              category: product.category,
              qty: variant.qty,
              subtotal: variant.subtotal,
              mixinName: variant.mixinName,
              mixinId: variant.mixinId,
            };
          }
        ),
      ]);
    }

    return (products = [...products, { ...product, _id: productId }]);
  });

  return products;
}

export { shoppingCartReducer, getShoppingCartProductsToRender };
