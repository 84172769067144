import { useApolloClient, useMutation } from '@apollo/client';

import { AUTH_TOKEN_KEYS } from 'app/constants/app';
import { useAlerts } from 'app/context/AlertsContext/hooks';
import { useBackgroundLocation } from 'app/hooks/routes';

import {
  SIGN_IN_SHOPPER_MUTATION,
  SIGN_OUT_SHOPPER_MUTATION,
  SIGN_UP_SHOPPER_MUTATION,
  UPDATE_MY_SHOPPER_PROFILE_MUTATION,
  UPDATE_MY_SHOPPER_PW_MUTATION,
} from './documents';
import {
  TSignInShopperData,
  TSignInShopperVariables,
  TSignOutShopperData,
  TSignUpShopperData,
  TSignUpShopperVariables,
  TUpdateMyShopperProfileData,
  TUpdateMyShopperProfileVariables,
  TUpdateMyShopperPwData,
  TUpdateMyShopperPwVariables,
} from './types';

export function useSignUpShopperMutation(args?: { onCompleted?: () => void }) {
  const apolloClient = useApolloClient();

  const { showErrorSnackbar } = useAlerts();

  const mutation = useMutation<TSignUpShopperData, TSignUpShopperVariables>(
    SIGN_UP_SHOPPER_MUTATION,
    {
      onCompleted(res) {
        const token = res.signUpShopper.token;

        localStorage.setItem(AUTH_TOKEN_KEYS.shopper, token);

        apolloClient.resetStore();

        args?.onCompleted && args.onCompleted();
      },
      onError(error) {
        showErrorSnackbar({
          title: 'Oops',
          message: error.graphQLErrors[0]?.message,
        });
      },
    }
  );

  return mutation;
}

export function useSignInShopperMutation(args?: { onCompleted?: () => void }) {
  const { showErrorSnackbar } = useAlerts();

  const apolloClient = useApolloClient();

  const mutation = useMutation<TSignInShopperData, TSignInShopperVariables>(
    SIGN_IN_SHOPPER_MUTATION,
    {
      onCompleted(res) {
        const token = res.signInShopper.token;

        localStorage.setItem(AUTH_TOKEN_KEYS.shopper, token);

        apolloClient.resetStore();

        args?.onCompleted && args.onCompleted();
      },
      onError(error) {
        showErrorSnackbar({
          title: 'Oops',
          message: error.graphQLErrors[0]?.message,
        });
      },
    }
  );

  return mutation;
}

export function useSignOutShopperMutation(args?: { onCompleted?: () => void }) {
  const { goBackToBackgroundLocation } = useBackgroundLocation();

  const apolloClient = useApolloClient();

  const { showErrorSnackbar } = useAlerts();

  const mutation = useMutation<TSignOutShopperData>(SIGN_OUT_SHOPPER_MUTATION, {
    onCompleted(res) {
      const message = res.signOutShopper.message;

      if (message) {
        localStorage.clear();

        apolloClient.resetStore();

        goBackToBackgroundLocation();

        args?.onCompleted && args.onCompleted();
      }
    },
    onError(error) {
      showErrorSnackbar({
        title: 'Oops',
        message: error.graphQLErrors[0]?.message,
      });
    },
  });

  return mutation;
}

export function useUpdateMyShopperProfileMutation(args?: {
  onCompleted?: () => void;
}) {
  const { showSuccessSnackbar, showErrorSnackbar } = useAlerts();

  const mutation = useMutation<
    TUpdateMyShopperProfileData,
    TUpdateMyShopperProfileVariables
  >(UPDATE_MY_SHOPPER_PROFILE_MUTATION, {
    onCompleted() {
      showSuccessSnackbar({ message: 'Perfil actualizado correctamente!' });

      args?.onCompleted && args.onCompleted();
    },
    onError(error) {
      showErrorSnackbar({
        title: 'Error actualizando tu perfil',
        message: error.graphQLErrors[0]?.message,
      });
    },
  });

  return mutation;
}

export function useUpdateMyShopperPwMutation(args?: {
  onCompleted?: () => void;
}) {
  const { showSuccessSnackbar, showErrorSnackbar } = useAlerts();

  const mutation = useMutation<
    TUpdateMyShopperPwData,
    TUpdateMyShopperPwVariables
  >(UPDATE_MY_SHOPPER_PW_MUTATION, {
    onCompleted(res) {
      if (!res.updateMyShopperPw.message) {
        return showErrorSnackbar({
          title: 'Error actualizando tu contraseña',
          message:
            'No pudimos actualizar tu contraseña ahora. Por favor intentalo más tarde.',
        });
      }

      showSuccessSnackbar({ message: 'Contraseña actualizada correctamente!' });

      args?.onCompleted && args.onCompleted();
    },
    onError(error) {
      showErrorSnackbar({
        title: 'Error actualizando tu contraseña',
        message: error.graphQLErrors[0]?.message,
      });
    },
  });

  return mutation;
}
