import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';

import RestAPIClient from './client';
import {
  TRestAPIRequestError,
  TRestMutationMethod,
  TRestRequestOptions,
  TUseRestMutationHook,
} from './types';

export function useRestMutation<TData = unknown, TVariables = unknown>(
  url: string,
  method: TRestMutationMethod,
  options?: TRestRequestOptions<TData, TVariables>
): TUseRestMutationHook<TData, TVariables> {
  const [data, setData] = useState<TData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TRestAPIRequestError | undefined>(
    undefined
  );

  const { authTokenEntity, mock, ...axiosOptions } = options || {};

  const mutation = useCallback(
    (payload?: TVariables) => {
      setLoading(true);

      const apiClient = RestAPIClient.getInstance({
        mock,
        url,
        authTokenEntity,
      });

      apiClient({
        baseURL: `${process.env.REACT_APP_QUOTE_REQUESTER_API_BASE_URL}/api/v1/`,
        url,
        method,
        data: payload,
        ...axiosOptions,
      })
        .then((response: AxiosResponse<TData>) => {
          setData(response.data);

          options?.onCompleted && options.onCompleted(response.data);

          setLoading(false);
        })
        .catch((error: TRestAPIRequestError) => {
          setError(error);

          options?.onError && options.onError(error);

          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, url]
  );

  return [mutation, { data, loading, error }];
}
