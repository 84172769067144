import styled from 'styled-components';

type TPriceProps = {
  fontStyles: TOSFont;
  isDiscountPrice: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  box-shadow: 2px 4px 18px rgb(0 0 0 / 2%);
  cursor: pointer;
  border-radius: 8px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  display: inline;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;

  @media (max-width: 1250px) {
    height: 50vh;
  }
  @media (max-width: 1030px) {
    height: 45vh;
  }
  @media (max-width: 600px) {
    height: 50vh;
  }
`;

export const DiscountPriceTag = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 8px;
  left: 0;
  bottom: 24px;
  background-color: #eb1f37;
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const NameParagraph = styled.p<{ fontStyles: TOSFont }>`
  font-size: ${({ fontStyles }) => fontStyles.size}px;
  font-family: ${({ fontStyles }) => fontStyles.family};
  color: ${({ theme }) => theme.colorsPalette.black.default};

  &:hover {
    color: ${({ theme }) => theme.colorsPalette.gray.dark};
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 8px 0;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  margin-bottom: 16px;
  cursor: pointer;
`;

export const Price = styled.p<TPriceProps>`
  font-size: ${({ fontStyles }) => fontStyles.size}px;
  font-family: ${({ fontStyles }) => fontStyles.family};
  color: ${({ fontStyles }) => fontStyles.color};

  text-decoration: ${({ isDiscountPrice }) =>
    isDiscountPrice ? 'line-through' : 'none'};
  margin: 0 10px;
`;

export const DiscountPrice = styled.p<{ fontStyles: TOSFont }>`
  font-size: ${({ fontStyles }) => fontStyles.size}px;
  font-family: ${({ fontStyles }) => fontStyles.family};
  margin: 0 10px;
`;
