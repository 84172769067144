import * as yup from 'yup';

export const shopperPwFormValidationSchema = yup.object().shape({
  currentPw: yup.string().required(),
  newPw: yup
    .string()
    .required()
    .min(8)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
});
