const NON_VALID_CHARS = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';

const VALID_CHARS = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';

export const CHARS_EQUIVALENCE_HASH_TABLE = [...NON_VALID_CHARS].reduce(
  (acc, nonValidChar, index) => {
    return { ...acc, [nonValidChar]: [...VALID_CHARS][index] };
  },
  {} as { [key: string]: string }
);

export const BUY_ONE_GET_TWO_OFFER_COMPANY_IDS = ['64613fa63496d30002db7d45'];
