import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import { CheckoutNotesContainer } from './styles';

export default function CheckoutNotes({
  checkoutNotes,
}: {
  checkoutNotes?: string;
}) {
  const osTheme = useOSThemeAndContent();

  if (!checkoutNotes) return null;

  return (
    <CheckoutNotesContainer
      color={osTheme.buttons.primary.fontColor}
      backgroundColor={osTheme.buttons.primary.backgroundColor}
    >
      {checkoutNotes}
    </CheckoutNotesContainer>
  );
}
