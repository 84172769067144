import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useCompany } from 'app/context/CompanyContext/hooks';

export default function useRUM() {
  const [gaInit, setGaInit] = useState(false);

  const location = useLocation();

  const company = useCompany();

  useEffect(() => {
    if (company && company.apps?.ga?.trackingId) {
      ReactGA.initialize(company.apps.ga.trackingId);
      ReactGA.pageview('/');

      setGaInit(true);
    }
  }, [company]);

  useEffect(() => {
    if (gaInit) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [gaInit, location]);

  return {
    ga: { init: gaInit, setInit: setGaInit },
  };
}
