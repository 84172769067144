import { useTranslation } from 'react-i18next';
import { Chip, theme } from 'kosmos-ui';
import DiscountTicket from 'kosmos-ui/lib/icons/DiscountTicket';

import { formatDate, formatNumber } from 'app/helpers/general';
import { ORDER_STATUS_DISPLAY_NAMES } from 'app/constants/app';
import { getOrderStatusChipStyles } from '../../helpers';

import {
  Card,
  ConfirmationNumberContainer,
  AmountContainer,
  AmountSubContainer,
  ProductNoContainer,
  StatusContainer,
  SubParagraph,
} from './styles';

export default function ShopperOrderCard({ order }: { order: TOrder }) {
  const { t } = useTranslation();

  const productsQty = order.products.reduce((acc, product) => {
    const productQty =
      product.variants && product.variants.length > 0
        ? (product.variants as unknown as any[]).reduce((acc, variant) => {
            return acc + variant.qty;
          }, 0)
        : (product.qty as unknown as number) || 0;

    return acc + productQty;
  }, 0);

  return (
    <Card>
      <ConfirmationNumberContainer>
        <b style={{ color: theme.colorsPalette.gray.dark }}>
          {order.confirmationNumber}
        </b>

        <SubParagraph>{formatDate(order.createdAt, 'DD-MM-YY')}</SubParagraph>
      </ConfirmationNumberContainer>

      <AmountContainer>
        <AmountSubContainer>
          <p style={{ color: theme.colorsPalette.gray.dark }}>
            ${formatNumber(order.totalAmount)}
          </p>

          {order.coupon && (
            <DiscountTicket
              color={theme.colorsPalette.gray.lighter}
              style={{ marginLeft: 8 }}
            />
          )}
        </AmountSubContainer>

        <SubParagraph style={{ marginTop: -4 }}>
          {order.payment.type === 'debit'
            ? t('shopperBrowser.orders.debitCardName')
            : order.payment.type === 'credit'
            ? t('shopperBrowser.orders.creditCardName')
            : ''}
        </SubParagraph>
      </AmountContainer>

      <ProductNoContainer>
        <p>{t('shopperBrowser.orders.productsQtyLabel', { productsQty })}</p>
      </ProductNoContainer>

      <StatusContainer>
        <Chip style={getOrderStatusChipStyles(order.status)}>
          {ORDER_STATUS_DISPLAY_NAMES[order.status]}
        </Chip>
      </StatusContainer>
    </Card>
  );
}
