import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';

/**
 * Allows to scroll the app to top on every route change.
 */
export function useScrollToTop() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

/**
 * Keeps track of a React state previous value once it changes.
 * @param value React state variable.
 * @returns The previous value of the state provided param.
 */
export function usePrevious<T = any>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

/**
 * Check if a React state has changed or not.
 * @param value React state variable.
 * @returns A flag indicating if the provided state value has changed.
 */
export function useCompare(value: any) {
  const prevValue = usePrevious(value);

  return prevValue !== value;
}

/**
 * Sets a debounced value to value (passed in) after the specified delay.
 * @param value - Value to debounce.
 * @param delay - Timeout to delay the value setup.
 */
export function useDebounce<T = string>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * It takes as parameters a eventName, a call-back functions (handler)
 * and optionally a reference element to track events.
 */
export function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: keyof WindowEventMap,
  handler: (event: Event) => void,
  element?: RefObject<T>
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}

/**
 * Dynamically recover the width and the height of an HTML element.
 * Dimensions are updated when resizing the window.
 */
export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T>,
  options?: { onUpdate?: (_: TElementSize) => void }
): TElementSize {
  const [size, setSize] = useState<TElementSize>({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(() => {
    const node = elementRef?.current;

    if (node) {
      const size = {
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      };

      setSize(size);

      options?.onUpdate && options?.onUpdate(size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  useEffect(() => {
    updateSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('resize', updateSize);

  return size;
}
