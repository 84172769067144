import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon, FiltersIcon, Skeleton, theme } from 'kosmos-ui/lib';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { formatNumber } from 'app/helpers/general';
import Typography from '../Typography';
import Select from '../Select';
import Checkbox from '../Checkbox';
import Button from '../Button';

import { useProductListSortByOptions } from './hooks';
import { TProductListFiltersProps, TProductListSortByOption } from './types';
import {
  getProductsListSortBySelectStyles,
  ProductFiltersSidebarContainer,
  ProductsListFiltersContainer,
  ProductsListMask,
  ProductsListMobileSidebarHeader,
  ProductsListSortByContainer,
} from './styles';

export function ProductsListFilters({
  filterBy,
  sortBy,
  setFilterBy,
  setSortBy,
  priceInterval,
  loadingPriceIntervals,
}: TProductListFiltersProps) {
  const [showFilters, setShowFilters] = useState(false);

  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  const { mobile } = useLayout();

  const sortByOptions = useProductListSortByOptions();

  function handleIsOnSaleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterBy((prevFilters) => {
      if (event.target.checked) return { ...prevFilters, isOnSale: true };

      if (prevFilters?.isOnSale) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isOnSale, ...prevFiltersWithoutIsOnSale } = prevFilters;

        return prevFiltersWithoutIsOnSale;
      }

      return prevFilters;
    });
  }

  function handlePriceIntervalsChange(
    event: React.ChangeEvent<HTMLInputElement>,
    priceInterval: number[]
  ) {
    setFilterBy((prevFilters) => {
      if (!event.target.checked)
        return { ...prevFilters, priceInterval: undefined };

      return { ...prevFilters, priceInterval };
    });
  }

  const activeFiltersCount =
    Object.keys(filterBy || {}).length + (mobile ? (sortBy ? 1 : 0) : 0);

  return (
    <>
      <ProductsListFiltersContainer>
        <Button
          onClick={() => setShowFilters((prevValue) => !prevValue)}
          variant='secondary'
          format='link'
          style={{
            fontWeight: osTheme.fonts.paragraphsFont.weight as any,
            color: theme.colorsPalette.black.default,
            fontFamily: osTheme.fonts.paragraphsFont.family,
          }}
        >
          {!mobile
            ? !showFilters
              ? t('productList.filters.showToggleButton')
              : t('productList.filters.hideToggleButton')
            : t('productList.filters.mobileToggleButton')}{' '}
          {!!activeFiltersCount && `(${activeFiltersCount})`}
          <FiltersIcon
            color={theme.colorsPalette.black.default}
            style={{ margin: `0 ${!mobile ? 16 : 0}px 0 8px` }}
          />
        </Button>

        {!mobile && (
          <ProductsListSortByContainer>
            <Typography
              variant='paragraph1'
              as='label'
              htmlFor='selectProductsSortBy'
              style={{
                marginRight: sortBy ? 8 : 0,
                color: theme.colorsPalette.black.default,
              }}
            >
              {t('productList.filters.sortByLabel')}:
            </Typography>

            <Select
              value={sortByOptions.find(
                (sortByOption) => sortByOption.value === sortBy
              )}
              options={sortByOptions}
              onChange={(option) =>
                setSortBy((option as TProductListSortByOption)?.value)
              }
              placeholder=''
              name='selectProductsSortBy'
              inputId='selectProductsSortBy'
              openMenuOnFocus
              isClearable
              isSearchable={false}
              style={getProductsListSortBySelectStyles({
                optionSelected: !!sortBy,
              })}
            />
          </ProductsListSortByContainer>
        )}
      </ProductsListFiltersContainer>

      <ProductFiltersSidebarContainer show={showFilters}>
        {mobile && (
          <>
            <ProductsListMobileSidebarHeader>
              <Button
                onClick={() => setShowFilters(false)}
                variant='secondary'
                format='link'
                style={{ width: 'fit-content' }}
              >
                <CloseIcon
                  height='16px'
                  color={theme.colorsPalette.black.default}
                />
              </Button>
            </ProductsListMobileSidebarHeader>

            <Typography
              as='h1'
              variant='paragraph1'
              style={{
                color: theme.colorsPalette.black.default,
                marginBottom: 16,
                fontWeight: theme.fontWeights.bold,
                fontSize: 20,
              }}
            >
              {t('productList.filters.mobileTitle')}
            </Typography>

            <Typography
              as='h1'
              variant='paragraph1'
              style={{
                color: theme.colorsPalette.black.default,
                margin: '0 0 8px 0',
              }}
            >
              {t('productList.filters.sortByLabel')}
            </Typography>

            <ProductsListSortByContainer>
              {sortByOptions.map((sortByOption, index) => (
                <Checkbox
                  key={`product-sortby-option-checkbox-${index}`}
                  checked={sortBy === sortByOption.value}
                  onChange={(event) =>
                    setSortBy(
                      !event.target.checked
                        ? undefined
                        : (sortByOption as TProductListSortByOption).value
                    )
                  }
                  style={{ root: { marginBottom: 12 } }}
                >
                  {sortByOption.label}
                </Checkbox>
              ))}
            </ProductsListSortByContainer>
          </>
        )}

        <Typography
          as='h1'
          variant='paragraph1'
          style={{
            color: theme.colorsPalette.black.default,
            margin: '12px 0 8px 0',
          }}
        >
          {t('productList.filters.filterByDiscountTitle')}
        </Typography>

        <Checkbox
          checked={!!filterBy?.isOnSale}
          onChange={handleIsOnSaleChange}
          style={{ root: { marginBottom: 12 } }}
        >
          {t('productList.filters.filterByDiscountLabel')}
        </Checkbox>

        {loadingPriceIntervals ? (
          <Skeleton style={{ width: '100%', height: 8 }} />
        ) : (
          priceInterval && (
            <>
              <Typography
                as='h1'
                variant='paragraph1'
                style={{
                  color: theme.colorsPalette.black.default,
                  margin: '12px 0 8px 0',
                }}
              >
                {t('productList.filters.priceIntervalTitle')}
              </Typography>

              {priceInterval.intervals.map((priceInterval, index) => (
                <Checkbox
                  key={`priceInterval-${index}`}
                  checked={
                    filterBy?.priceInterval?.toString() ===
                    priceInterval.toString()
                  }
                  onChange={(event) =>
                    handlePriceIntervalsChange(event, priceInterval)
                  }
                  style={{ root: { marginBottom: 12 } }}
                >
                  {`$${formatNumber(priceInterval[0])}-$${formatNumber(
                    priceInterval[1]
                  )}`}
                </Checkbox>
              ))}
            </>
          )
        )}

        {!!activeFiltersCount && (
          <Button
            onClick={() => {
              mobile && setSortBy(undefined);
              setFilterBy(undefined);
            }}
            variant='primary'
            format='outline'
            style={{ marginTop: 16, width: '100%' }}
          >
            {t('productList.filters.cleanupButton')}
          </Button>
        )}
      </ProductFiltersSidebarContainer>

      {showFilters && (
        <ProductsListMask onClick={() => setShowFilters(false)} />
      )}
    </>
  );
}
