import styled from 'styled-components';

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TransparentButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  display: flex;
  margin-right: 10px;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 24px 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const GroupedInputsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const SectionHeader = styled.h1`
  color: ${({ theme }) => theme.colorsPalette.gray.darker};
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

export const SubSectionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 16px;
  width: 100%;
  overflow: auto;
  padding: 24px 0;
`;

export const ActiveSectionHeader = styled.h1`
  font-size: 14px;
  color: ${({ theme }) => theme.colorsPalette.gray.dark};
  margin: 0;
`;
