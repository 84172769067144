import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, SearchIcon, TextInput } from 'kosmos-ui/lib';

import { useLayout } from 'app/context/LayoutContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import { TSearchInputProps } from './types';
import { SearchInputContainer } from './styles';

export default function SearchInput({
  searchTerm,
  setSearchTerm,
  expanded,
  setExpanded,
  style,
  className,
}: TSearchInputProps) {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const osTheme = useOSThemeAndContent();

  if (!expanded)
    return (
      <Button
        variant="secondary"
        format="link"
        onClick={() => setExpanded(true)}
        style={style?.expandButton}
        className={className?.expandButton}
      >
        <SearchIcon
          height="20px"
          color={osTheme.navbar.elementsColor}
          style={style?.expandIcon}
          className={className?.expandIcon}
        />
      </Button>
    );

  return (
    <SearchInputContainer>
      <TextInput
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        style={{ root: { minWidth: mobile ? 244 : 200 }, ...style?.input }}
        placeholder={`${t('topbar.searchPlaceholder')}...`}
        variant="filled"
        adornments={{
          left: (
            <SearchIcon
              height="18px"
              style={style?.searchIcon}
              className={className?.searchIcon}
            />
          ),
        }}
        className={className?.input}
      />

      <Button
        variant="secondary"
        format="link"
        onClick={() => setExpanded(false)}
        style={style?.collapseButton}
        className={className?.collapseButton}
      >
        <CloseIcon
          height="16px"
          color={osTheme.navbar.elementsColor}
          style={{ marginLeft: 8, ...style?.collapseIcon }}
          className={className?.collapseIcon}
        />
      </Button>
    </SearchInputContainer>
  );
}
