import { TOSThemeAndContentContextValues } from 'app/context/OSThemeAndContentContext/types';
import { TTypographyVariant } from 'kosmos-ui/lib/components/Typography/types';
import { CSSProperties } from 'react';

export function getTypographyCompanyStyles({
  variant,
  companyTypography,
}: {
  variant: TTypographyVariant;
  companyTypography: ValueOf<Pick<TOSThemeAndContentContextValues, 'fonts'>>;
}): CSSProperties | undefined {
  switch (variant) {
    case 'title1':
      return {
        fontFamily: companyTypography.titlesFont.family,
        color: companyTypography.titlesFont.color,
        fontSize: `${companyTypography.titlesFont.size}px`,
        fontWeight: companyTypography.titlesFont.weight as any,
      };

    case 'title2':
      return {
        fontFamily: companyTypography.titlesFont.family,
        color: companyTypography.titlesFont.color,
        fontSize: `${
          parseInt(companyTypography.titlesFont.size) > 36
            ? '36'
            : companyTypography.titlesFont.size
        }px`,
        fontWeight: companyTypography.titlesFont.weight as any,
      };

    case 'title3':
      return {
        fontFamily: companyTypography.titlesFont.family,
        color: companyTypography.titlesFont.color,
        fontSize: `${
          parseInt(companyTypography.titlesFont.size) > 24
            ? '24'
            : companyTypography.titlesFont.size
        }px`,
        fontWeight: companyTypography.titlesFont.weight as any,
      };

    case 'subtitle1':
    case 'subtitle2':
      return {
        fontFamily: companyTypography.subtitlesFont.family,
        color: companyTypography.subtitlesFont.color,
        fontSize: `${companyTypography.subtitlesFont.size}px`,
        fontWeight: companyTypography.subtitlesFont.weight as any,
      };

    case 'paragraph1':
    case 'paragraph2':
    case 'paragraph3':
      return {
        margin: 0,
        fontFamily: companyTypography.paragraphsFont.family,
        color: companyTypography.paragraphsFont.color,
        fontSize: `${companyTypography.paragraphsFont.size}px`,
        fontWeight: companyTypography.paragraphsFont.weight as any,
      };

    default:
      break;
  }
}
