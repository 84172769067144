import { FacebookIcon, InstagramIcon, PaymentCardIcon, theme } from 'kosmos-ui';

import { useCompany } from 'app/context/CompanyContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { PAYMENT_CARDS_VARIANTS, ROUTES } from 'app/constants/app';

import {
  Container,
  EndParagraphContainer,
  InfoParagraph,
  SectionsContainer,
  PageLink,
  SectionContainer,
  Header,
  SocialMediaContainer,
  SubSectionItemContainer,
  Paragraph,
  ParagraphLink,
  GrafitoLink,
  PaymentCardsContainer,
} from './styles';

/**
 * Footer section of the app.
 */
export default function Footer() {
  const company = useCompany();

  const {
    footer: { contactSection, socialNetworksSection },
    customPages,
    fonts,
  } = useOSThemeAndContent();

  const copyrightDate = new Date();
  const copyrightYear = copyrightDate.getFullYear();

  return (
    <Container>
      <SectionsContainer>
        {contactSection.active && (
          <SectionContainer>
            {contactSection.title && (
              <Header
                fontStyle={{
                  ...fonts.titlesFont,
                  size:
                    parseInt(fonts.titlesFont.size) > 20
                      ? '24'
                      : fonts.titlesFont.size,
                }}
              >
                {contactSection.title}
              </Header>
            )}

            <SubSectionItemContainer>
              {contactSection.addresses &&
                contactSection.addresses.map((address, index) => (
                  <InfoParagraph
                    fontSize={fonts.paragraphsFont.size}
                    fontFamily={fonts.paragraphsFont.family}
                    key={`footer-contactsection-${address}-${index}`}
                  >
                    {address}
                  </InfoParagraph>
                ))}
            </SubSectionItemContainer>

            <SubSectionItemContainer>
              {contactSection.emails &&
                contactSection.emails.map((email, index) => (
                  <InfoParagraph
                    fontSize={fonts.paragraphsFont.size}
                    fontFamily={fonts.paragraphsFont.family}
                    key={`footer-contactsection-${email}-${index}`}
                  >
                    {email}
                  </InfoParagraph>
                ))}
            </SubSectionItemContainer>

            <SubSectionItemContainer isLast={true}>
              {contactSection.phones &&
                contactSection.phones.map((phone, index) => (
                  <InfoParagraph
                    fontSize={fonts.paragraphsFont.size}
                    fontFamily={fonts.paragraphsFont.family}
                    key={index}
                  >
                    {phone}
                  </InfoParagraph>
                ))}
            </SubSectionItemContainer>
          </SectionContainer>
        )}

        {customPages && (
          <SectionContainer>
            <Header
              fontStyle={{
                ...fonts.titlesFont,
                size:
                  parseInt(fonts.titlesFont.size) > 20
                    ? '24'
                    : fonts.titlesFont.size,
              }}
            >
              Acerca
            </Header>

            {customPages.map((customPage) => {
              return (
                <PageLink
                  fontSize={fonts.paragraphsFont.size}
                  fontFamily={fonts.paragraphsFont.family}
                  to={`${ROUTES.customPages}/${customPage.type}/${customPage._id}`}
                  key={customPage._id}
                >
                  {customPage.title}
                </PageLink>
              );
            })}
          </SectionContainer>
        )}

        {socialNetworksSection.active && (
          <SectionContainer>
            <Header
              fontStyle={{
                ...fonts.titlesFont,
                size:
                  parseInt(fonts.titlesFont.size) > 20
                    ? '24'
                    : fonts.titlesFont.size,
              }}
            >
              {socialNetworksSection.title}
            </Header>

            {socialNetworksSection.instagram && (
              <SocialMediaContainer>
                <InstagramIcon
                  height="20px"
                  color={theme.colorsPalette.gray.light}
                />

                <ParagraphLink
                  fontSize={fonts.paragraphsFont.size}
                  fontFamily={fonts.paragraphsFont.family}
                  href={socialNetworksSection.instagram}
                >
                  Instagram
                </ParagraphLink>
              </SocialMediaContainer>
            )}

            {socialNetworksSection.facebook && (
              <SocialMediaContainer>
                <FacebookIcon
                  height="20px"
                  color={theme.colorsPalette.gray.light}
                />

                <ParagraphLink
                  fontSize={fonts.paragraphsFont.size}
                  fontFamily={fonts.paragraphsFont.family}
                  href={socialNetworksSection.facebook}
                >
                  Facebook
                </ParagraphLink>
              </SocialMediaContainer>
            )}
          </SectionContainer>
        )}
      </SectionsContainer>

      {process.env.REACT_APP_SHOW_GRAFITO_SIGNATURE && (
        <EndParagraphContainer>
          <PaymentCardsContainer>
            {PAYMENT_CARDS_VARIANTS.map((cardVariant, index) => {
              return <PaymentCardIcon key={index} variant={cardVariant} />;
            })}
          </PaymentCardsContainer>

          <Paragraph
            fontSize={fonts.paragraphsFont.size}
            fontFamily={fonts.paragraphsFont.family}
          >
            © {copyrightYear} {company.fantasyName || 'Grafito App'}, by{' '}
            <GrafitoLink
              fontSize={fonts.paragraphsFont.size}
              fontFamily={fonts.paragraphsFont.family}
              href="https://www.grafito.app"
            >
              Grafito
            </GrafitoLink>
          </Paragraph>
        </EndParagraphContainer>
      )}
    </Container>
  );
}
