import { useQuery } from '@apollo/client';

import { GET_PRODUCT_CATEGORY_QUERY } from './documents';
import {
  TGetProductCategoriesVariables,
  TGetProductCategoryData,
} from './types';

export function useGetProductCategoryQuery(categoryId: string) {
  const query = useQuery<
    TGetProductCategoryData,
    TGetProductCategoriesVariables
  >(GET_PRODUCT_CATEGORY_QUERY, {
    variables: { _id: categoryId },
  });

  return query;
}
