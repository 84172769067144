import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const AvatarSubHeader = styled.h2`
  font-weight: 500;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
  font-size: 14px;
  margin: 8px 0 16px 0;
`;
