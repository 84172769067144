import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

import { AUTH_TOKEN_KEYS } from 'app/constants/app';
import { getDeviceId } from 'app/helpers/device';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
}) as unknown as ApolloLink;

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEYS.shopper);

  const deviceId: string = await getDeviceId();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      deviceId,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getProducts: relayStylePagination([
          'search',
          'categoryId',
          'merchantId',
        ]),
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: from([authLink, onErrorLink, httpLink]),
});

export default client;
