import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { ContentEditor, theme } from 'kosmos-ui/lib';

import {
  GET_OS_GALLERY_QUERY,
  GET_OS_BLOG_QUERY,
} from 'api/graphql/queries/online-store/pages/documents';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import {
  BlackMask,
  ContentContainer,
  Header,
  HeaderContainer,
  GalleryContainer,
  GalleryImage,
} from './styles';

export default function CustomPage() {
  const company = useCompany();
  const { navbar, fonts } = useOSThemeAndContent();
  const { mobile } = useLayout();

  const { _id, type } = useParams<{
    _id: string;
    type: TOSPageType;
  }>();

  const pageQuery = {
    document: type === 'gallery' ? GET_OS_GALLERY_QUERY : GET_OS_BLOG_QUERY,
    name: type === 'gallery' ? 'getOnlineStoreGallery' : 'getOnlineStoreBlog',
  };

  const {
    data: pageData,
    loading: loadingPageData,
    error: errorOnPageData,
  } = useQuery(pageQuery.document, { variables: { _id } });

  const page: TOSBlogAndGallery = get(pageData, pageQuery.name);

  const navbarOrientation = navbar.orientation;

  if (loadingPageData) return <p>Cargando página...</p>;

  if (errorOnPageData) return <p>Error cargando página!</p>;

  if (!page) return <p>Esta página no contiene información para mostrar!</p>;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | ${page.title}`
            : 'Cargando...'}
        </title>
      </Helmet>

      <HeaderContainer
        backgroundImage={page.backgroundImage || ''}
        navbarOrientation={mobile ? 'vertical' : navbarOrientation}
      >
        {page.backgroundImage && (
          <BlackMask
            navbarOrientation={mobile ? 'vertical' : navbarOrientation}
          />
        )}

        <Header fontStyle={fonts.titlesFont}>{page.title}</Header>
      </HeaderContainer>

      <ContentContainer pageType={type}>
        {type === 'gallery' ? (
          <GalleryContainer>
            {page.images.map((image, index) => {
              return <GalleryImage key={index} src={image.url} />;
            })}
          </GalleryContainer>
        ) : (
          <ContentEditor
            value={page.content}
            readOnly
            style={{
              editor: { border: 'none' },
              root: {
                background: theme.colorsPalette.gray.superLight,
                border: 'none',
                fontFamily: fonts.paragraphsFont.family,
                color: fonts.paragraphsFont.color,
                fontSize: theme.fontSizes.regularLarge,
                lineHeight: '32px',
              },
            }}
          />
        )}
      </ContentContainer>
    </>
  );
}
