import { gql } from '@apollo/client';

export const GET_PRODUCT_CATEGORY_QUERY = gql`
  query GET_PRODUCT_CATEGORY_QUERY($_id: ID!) {
    getProductCategory(_id: $_id) {
      _id
      name
      image
      description
    }
  }
`;
