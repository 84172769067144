import { TRestRequests } from './types';

export const REST_REQUESTS: TRestRequests = {
  payments: {
    tbkWpp: {
      createPayment: {
        baseURL: process.env.REACT_APP_REST_API_URL as string,
        url: 'payments/tbkWpp',
        method: 'post',
      },
    },
    tbkOneClick: {
      createPayment: {
        baseURL: process.env.REACT_APP_REST_API_URL as string,
        url: 'payments/tbkOneClick',
        method: 'post',
      },
      getCreditCards: {
        baseURL: process.env.REACT_APP_REST_API_URL as string,
        url: 'payments/tbkOneClick/creditCards',
        method: 'get',
      },
      subscribeCreditCard: {
        baseURL: process.env.REACT_APP_REST_API_URL as string,
        url: 'payments/tbkOneClick/creditCards',
        method: 'post',
      },
    },
  },
};
