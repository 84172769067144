import { useFormik } from 'formik';

import { useShopper } from 'app/context/ShopperContext/hooks';
import { useCreateTBKWppPaymentMutation } from 'api/rest/resources/payment/hooks';
import { TCheckoutAmounts } from '../../types';

import { formValidationSchema } from './helpers';
import { TPaymentFormValues, TUsePaymentFormHook } from './types';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';

export function usePaymentForm(props: {
  amounts: TCheckoutAmounts;
}): TUsePaymentFormHook {
  const company = useCompany();

  const { shopper } = useShopper();

  const [
    createTBKWppPaymentMutation,
    { loading: loadingCreateTBKWppPaymentMutation },
  ] = useCreateTBKWppPaymentMutation();

  const { shoppingCart } = useShoppingCart();

  const form = useFormik<TPaymentFormValues>({
    initialValues: {
      userFirstname: shopper?.firstname || '',
      userLastname: shopper?.lastname || '',
      userEmail: shopper?.email || '',
      userPhone: shopper?.phone || '',
      userRut: shopper?.rut || '',
      deliveryType: 'delivery',
      deliveryLocation: undefined as
        | { value: string; label: string }
        | undefined,
      deliveryZone: undefined as { value: string; label: string } | undefined,
      deliveryAddress:
        (shopper?.locations &&
          shopper.locations.length > 0 &&
          shopper.locations[0].address) ||
        '',
      deliveryNotes: '',
      couponCode: '',
      // TODO: Change to "undefined" when backend finish with
      // oneclick implementation, so user can select between
      // multiple payment options.
      paymentGateway: 'tbkWpp',
    },
    validationSchema: formValidationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      switch (values.paymentGateway) {
        case 'tbkWpp':
          createTBKWppPaymentMutation({
            sessionId: 'grafito-session-id',
            companyId: company._id,
            onlineStoreClientBaseUrl: company.domain,
            orderData: {
              totalAmount: props.amounts.totalAmount,
              productsAmount: props.amounts.productsAmount,
              shippingCost: props.amounts.shippingCost,
              deliveryLocation: values.deliveryLocation?.value,
              deliveryZone: values.deliveryZone?.value,
              deliveryAddress: form.values.deliveryAddress,
              deliveryNotes: form.values.deliveryNotes,
              deliveryType: form.values.deliveryType as any,
              couponCode: form.values.couponCode,
              products: Object.keys(shoppingCart.products).map((_id) => {
                const product = shoppingCart.products[_id as any] as any;

                return {
                  ...product,
                  _id,
                };
              }),
            },
            paymentData: {
              amount: props.amounts.totalAmount,
            },
            shopperData: {
              email: form.values.userEmail,
              firstname: form.values.userFirstname,
              lastname: form.values.userLastname,
              rut: form.values.userRut,
              phone: form.values.userPhone,
            },
          });

          break;

        case 'tbkOneClick':
          // TODO: Add mutation when backend finishes implementation.
          break;

        default:
          break;
      }
    },
  });

  return [form, { loadingPayment: loadingCreateTBKWppPaymentMutation }];
}
