export const ES_TRANSLATIONS: TLocaleTranslation = {
  api: {
    payments: {
      errorTitle: 'Error al procesar tu pago',
      errorDescription:
        'No pudimos procesar tu pago. Por favor intentalo de nuevo o contáctate con nuestro equipo de soporte.',
    },
  },
  shopperEntry: { button: 'Entrar' },
  shopperSignUp: {
    title: 'Registrate',
    notes:
      'Registrandote podrás comprar más rápidamente y descubrir nuevos productos y categorías de tu interés.',
    firstnameInputLabel: 'Nombre',
    lastnameInputLabel: 'Apellido',
    emailInputLabel: 'Email',
    passwordInputLabel: 'Contraseña',
    submitButton: 'Crear cuenta',
    switchToSignInDescription: '¿Ya tienes una cuenta? Inicia sesión',
    switchToSignInLink: 'aquí.',
  },
  shopperSignIn: {
    title: 'Inicia sesión',
    emailInputLabel: 'Email',
    passwordInputLabel: 'Contraseña',
    submitButton: 'Entrar',
    switchToSignUpDescription: '¿No tienes una cuenta? Registrate',
    switchToSignUpLink: 'aquí.',
  },
  shopperBrowser: {
    loadingShopperDescription: 'Cargando informacion de tu cuenta',
    sidebar: {
      profileLabel: 'Perfil',
      ordersLabel: 'Mis compras',
      accountLabel: 'Cuenta',
      signOutLabel: 'Cerrar sesión',
    },
    profile: {
      firstnameLabel: 'Nombre',
      lastnameLabel: 'Apellido',
      idLabel: 'RUT',
      phoneLabel: 'Teléfono',
      locationAddressLabel: 'Dirección',
      saveButton: 'Guardar cambios',
    },
    orders: {
      emptyOrdersTitle: 'Aún no tienes compras',
      emptyOrdersDescription:
        'Una vez compres productos tus órdenes aparecerán aquí!',
      debitCardName: 'Débito',
      creditCardName: 'Crédito',
      productsQtyLabel: '{{productsQty}} items.',
    },
    account: {
      title: 'Contraseña',
      description:
        'Tu contraseña debe tener como mínimo 8 caracteres, una mayúscula, un número y una minúscula.',
      currentPwLabel: 'Contraseña actual',
      newPwLabel: 'Contraseña',
      saveButton: 'Guardar cambios',
    },
  },
  topbar: {
    searchPlaceholder: 'Buscar',
  },
  productList: {
    filters: {
      showToggleButton: 'Mostrar filtros',
      hideToggleButton: 'Ocultar filtros',
      mobileToggleButton: 'Filtros',
      mobileTitle: 'Filtrar productos',
      sortByLabel: 'Ordenar por',
      sortByOptionLabels: {
        nameAsc: 'Nombre, A-Z',
        nameDesc: 'Nombre, Z-A',
        priceAsc: 'Precio, bajo-alto',
        priceDesc: 'Precio, alto-bajo',
        createdAtAsc: 'Fecha, antiguo-nuevo',
        createdAtDesc: 'Fecha, nuevo-antiguo',
      },
      filterByDiscountTitle: 'Descuentos',
      filterByDiscountLabel: 'Solo descuentos',
      priceIntervalTitle: 'Precio',
      cleanupButton: 'Quitar filtros',
    },
    fetchMoreButton: 'Cargar más productos',
    productsNotFoundTitle: 'No encontramos productos',
    productsNotFoundDescription: 'Intenta con otra búsqueda!',
    errorLoadingProductsLabel: 'Error cargando productos!',
  },
  productDetails: {
    outOfStockLabel: 'Agotado',
    selectVariantPlaceholder: 'Selecciona una variante',
    addToCartButton: 'Agregar al carro',
  },
  productsSearchResults: {
    headTitle: 'Buscar en productos',
    title: 'Resultados para "{{searchTerm}}"',
  },
  shoppingCart: {
    addProductsSnackbar: {
      successTitle: 'Producto agregado',
      successDescription: 'Tu producto fue agregado al carrito!',
      errorTitle: 'Cantidad fuera de stock!',
      errorDescription:
        'La cantidad que seleccionaste sobrepasa el stock disponible.',
    },
  },
  shoppingCartDetailsBar: {
    emptyStateParagraph: 'Tu carrito está vacío.',
    subTotalLabel: 'Sub Total:',
    goToCheckoutButton: 'Ir al checkout',
  },
  checkout: {
    shoppingCartDetails: {
      title: 'Checkout',
      continueShoppingButton: 'Seguir comprando',
      subtotalLabel: 'Subtotal',
      shippingCostsLabel: 'Costos de envío',
      couponLabel: 'Cupón de descuento',
      totalAmountLabel: 'Total de tu compra',
    },
    paymentForm: {
      firstnameLabel: 'Nombre',
      lastnameLabel: 'Apellido',
      emailLabel: 'Email',
      phoneLabel: 'Teléfono',
      idLabel: 'Rut',
      deliveryOptionLabel: 'Despacho a domicilio',
      storePickupOptionLabel: 'Retiro en tienda',
      deliveryAddressLabel: 'Dirección',
      deliveryLocationPlaceholder: 'Región',
      deliveryZonePlaceholder: 'Comuna',
      deliveryNotesLabel: 'Notas para la entrega',
      deliveryErrorLabel: 'Necesitamos saber donde llevar tus productos!',
      tbkOneClickGatewayTitle: 'Webpay Oneclick',
      tbkOneClickGatewayDescription: 'Paga con un solo click',
      tbkWppGatewayTitle: 'Webpay plus',
      notSelectedDeliveryLocationOptionLabel: 'Selecciona una provincia',
      couponCodeLabel: 'Cupón de descuento',
      applyCouponButton: 'Aplicar descuento',
      missingFieldsErrorLabel: 'Algunos campos están sin completar!',
      steps: {
        contactTitle: 'Contacto',
        deliveryTitle: 'Despacho',
        paymentTitle: 'Pago',
        summaryTitle: 'Resumen',
        goBackButton: 'Atrás',
        goNextButton: 'Siguiente',
        payButton: 'Pagar',
      },
    },
  },
  paymentResult: {
    invalidTokenTitle: 'Oops',
    invalidTokenDescription:
      'La página que estás buscando no existe. Te invitamos a volver a nuestro homepage para seguir comprando!',
    invalidTokenActionMessage: 'Volver',
    failedPaymentTitle: 'Oops',
    failedPaymentDescription:
      'Tuvimos un error procesando tu pago! Por favor intentalo nuevamente o contactate con nosotros para ayudarte.',
    failedPaymentActionMessage: 'Seguir comprando',
    cancelledPaymentTitle: 'Compra cancelada',
    cancelledPaymentDescription:
      'Tu compra fue cancelada correctamente. Te invitamos a volver a nuestro homepage para seguir comprando!',
    cancelledPaymentActionMessage: 'Seguir comprando',
    succeedPaymentTitle: 'Gracias por tu compra!',
    succeedPaymentDescription:
      'Tu pago fue realizado con éxito. Enviaremos un email a {{shopperEmail}} con el comprobante de la compra.',
    succeedPaymentActionMessage: 'Seguir comprando',
  },
  localesSwitcher: {
    title: 'Cambiar idioma',
    description:
      'Selecciona el idioma en el que quiere navegar y comunicarte con nosotros.',
  },
};
