import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { Navlink } from '../../styles';

import { TSidebarProps } from './types';
import { SidebarContainer } from './styles';
import CollapsableNavLink from '../CollapsableNavLink';

/**
 * Sidebar where all store navlinks are listed.
 * @param show - Flag for showing or not the component.
 * @param toggleShow - Dispatcher for showing or hiding the component.
 */
export default function Sidebar({ show, toggleShow, navlinks }: TSidebarProps) {
  const { navbar, fonts } = useOSThemeAndContent();

  return (
    <SidebarContainer show={show} backgroundColor={navbar.backgroundColor}>
      <Navlink
        fontStyle={{
          ...fonts.paragraphsFont,
          color: navbar.elementsColor,
        }}
        to='/'
        onClick={toggleShow}
      >
        Home
      </Navlink>

      {navlinks &&
        navlinks.map((navlink) => {
          const children =
            navlink.children &&
            navlink.children.length > 0 &&
            navlink.children.filter((child) => child.link);

          if (children && children.length > 0) {
            return (
              <CollapsableNavLink
                key={navlink._id}
                label={navlink.label}
                onChildClick={() => toggleShow()}
              >
                {children}
              </CollapsableNavLink>
            );
          }

          return (
            navlink.link && (
              <Navlink
                key={navlink._id}
                to={navlink.link || '-'}
                onClick={() => toggleShow()}
                fontStyle={{
                  ...fonts.paragraphsFont,
                  color: navbar.elementsColor,
                }}
              >
                {navlink.label}
              </Navlink>
            )
          );
        })}
    </SidebarContainer>
  );
}
