import styled from 'styled-components';
import { Card as KosmosCard } from 'kosmos-ui';

export const ConfirmationNumberContainer = styled.div`
  width: 136px;
`;

export const AmountContainer = styled.div`
  width: 142px;
`;

export const AmountSubContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductNoContainer = styled.div`
  width: 140px;
`;

export const StatusContainer = styled.div`
  width: 132px;
`;

export const SubParagraph = styled.p`
  color: ${({ theme }) => theme.colorsPalette.gray.light};
`;

export const Card = styled(KosmosCard)`
  && {
    margin-bottom: 15px;
    flex-direction: row;
    padding: 24px;
    align-items: center;
  }
`;
