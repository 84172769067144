import { Typography as BaseTypography } from 'kosmos-ui/lib';
import { TTypographyProps } from 'kosmos-ui/lib/components/Typography/types';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import { getTypographyCompanyStyles } from './helpers';

export default function Typography({
  children,
  variant,
  style: baseStyle,
  ...props
}: TTypographyProps) {
  const osTheme = useOSThemeAndContent();

  const style = getTypographyCompanyStyles({
    variant,
    companyTypography: osTheme.fonts,
  });

  return (
    <BaseTypography
      variant={variant}
      style={{ ...style, ...baseStyle }}
      {...props}
    >
      {children}
    </BaseTypography>
  );
}
