import { createContext, ReactNode, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Dialog as Alert,
  theme,
  SuccessIcon,
  ErrorIcon,
  Button,
} from 'kosmos-ui';

import { TAlertsContextValues, TShowAlertProps } from './types';
import {
  SnackbarMainContainer,
  SnackbarContentContainer,
  SnackbarHeader,
  SnackbarParagraph,
} from './styles';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

export const AlertsContext = createContext({} as TAlertsContextValues);

export function AlertsContextProvider({ children }: { children: ReactNode }) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<string | ReactNode>('');
  const [alertHeader, setAlertHeader] = useState<string | ReactNode>('');
  const [alertDeclineActionCallback, setAlertDeclineActionCallback] = useState<
    (() => void) | undefined
  >(undefined);

  function showAlert({
    content,
    header,
    declineActionCallback,
  }: TShowAlertProps) {
    setIsAlertOpen(true);

    setAlertContent(content);

    setAlertHeader(header);

    if (declineActionCallback)
      setAlertDeclineActionCallback(declineActionCallback);
  }

  function showSuccessSnackbar({
    title = 'Éxito',
    message,
  }: {
    title?: string;
    message: string;
  }) {
    toast(
      () => (
        <SnackbarMainContainer>
          <SuccessIcon
            style={{ marginRight: '10px' }}
            color={theme.colorsPalette.green.default}
            height='25px'
          />

          <SnackbarContentContainer>
            <SnackbarHeader>{title}</SnackbarHeader>

            <SnackbarParagraph>{message}</SnackbarParagraph>
          </SnackbarContentContainer>
        </SnackbarMainContainer>
      ),
      { className: 'success-snackbar' }
    );
  }

  function showErrorSnackbar({
    title = 'Oops',
    message,
  }: {
    title?: string;
    message: string;
  }) {
    toast(
      () => (
        <SnackbarMainContainer>
          <ErrorIcon
            style={{ marginRight: '10px' }}
            color={theme.colorsPalette.red.default}
            height='25px'
          />

          <SnackbarContentContainer>
            <SnackbarHeader>{title}</SnackbarHeader>

            <SnackbarParagraph>{message}</SnackbarParagraph>
          </SnackbarContentContainer>
        </SnackbarMainContainer>
      ),
      { className: 'error-snackbar' }
    );
  }

  return (
    <AlertsContext.Provider
      value={{ showAlert, showSuccessSnackbar, showErrorSnackbar }}
    >
      {children}

      <ToastContainer
        autoClose={3000}
        position={toast.POSITION.BOTTOM_LEFT}
        hideProgressBar={true}
      />

      <Alert show={isAlertOpen}>
        <Alert.Header>
          <Alert.Title>{alertHeader}</Alert.Title>

          <Alert.Close onClick={() => setIsAlertOpen(false)} />
        </Alert.Header>

        <Alert.Body>{alertContent}</Alert.Body>

        <Alert.Footer>
          <Button
            variant='primary'
            onClick={() => {
              setIsAlertOpen(false);

              if (alertDeclineActionCallback) alertDeclineActionCallback();
            }}
          >
            Ok
          </Button>
        </Alert.Footer>
      </Alert>
    </AlertsContext.Provider>
  );
}
