import { gql } from '@apollo/client';

export const GET_COUPON_QUERY = gql`
  query GET_COUPON_QUERY($code: String!, $companyId: ID!) {
    getCoupon(code: $code, companyId: $companyId) {
      code
      value
      endDate
      discountType
      maxUsage
      usage
    }
  }
`;
