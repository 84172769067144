import { createContext, useState, useEffect, ReactNode } from 'react';

import { TLayoutContextValues } from './types';

export const LayoutContext = createContext({} as TLayoutContextValues);

export function LayoutContextProvider({ children }: { children: ReactNode }) {
  const [mobile, setMobile] = useState(window.innerWidth <= 600);

  const [announcementBarHeight, setAnnouncementBarHeight] = useState(0);
  const [topBarHeight, setTopBarHeight] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => setMobile(window.innerWidth <= 600);

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        mobile,
        announcementBarHeight,
        setAnnouncementBarHeight,
        topBarHeight,
        setTopBarHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}
