import styled from 'styled-components';
import { Link } from 'react-router-dom';

type TInfoParagraphProps = {
  fontSize: string;
  fontFamily: string;
};

export const Container = styled.footer`
  padding: 72px 56px;
`;

export const SectionsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  height: 200px;
  margin-bottom: 48px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    text-align: center;
    height: 100%;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;

  @media (max-width: 600px) {
    width: 100%;
    margin: 16px 0;
    align-items: center;
  }
`;

export const SubSectionItemContainer = styled.div<{ isLast?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isLast }) => (isLast ? 'none' : '7px')};
`;

export const Header = styled.h1<{ fontStyle: TOSFont }>`
  color: ${({ fontStyle }) => fontStyle.color};
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  font-family: ${({ fontStyle }) => fontStyle.family};

  @media (max-width: 600px) {
    margin-bottom: 12px;
  }
`;

export const InfoParagraph = styled.span<TInfoParagraphProps>`
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
`;

export const ParagraphLink = styled.a<{ fontSize: string; fontFamily: string }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
  margin: 0 0 8px 12px;

  &:hover {
    color: ${({ theme }) => theme.colorsPalette.gray.dark};
  }
`;

export const PageLink = styled(Link)<{ fontSize: string; fontFamily: string }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
  margin-bottom: 8px;

  &:hover {
    color: ${({ theme }) => theme.colorsPalette.gray.dark};
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const Paragraph = styled.p<{ fontSize: string; fontFamily: string }>`
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
`;

export const GrafitoLink = styled.a<{ fontSize: string; fontFamily: string }>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: inherit;
  color: inherit;

  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colorsPalette.gray.default};
  }
`;

export const EndParagraphContainer = styled.section`
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  text-align: center;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
`;

export const PaymentCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
