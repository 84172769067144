import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import helpers from 'app/helpers';
import {
  TShoppingCartVariant,
  TShoppingCartProductToRender,
} from 'app/context/ShoppingCartContext/types';
import Button from '../../../components/Button';
import { ROUTES } from 'app/constants/app';

import { TProps } from './types';
import {
  ShoppingDetailsContainer,
  ButtonsContainer,
  ShoppingDetailsProductsSection,
  ProductContainer,
  ProductDetailsContainer,
  ProductImage,
  ProductNameParagraph,
  ProductVariantParagraph,
  EmptyStateParagraph,
  ProductPriceAndQty,
  SubtotalContainer,
  SubtotalTextContainer,
} from './styles';

/**
 * Sidebar where all the shopping cart products are listed, including
 * the cart subtotal.
 * @param show - Flag for showing or not the component.
 * @param toggleShow - Dispatcher for showing or hiding the component.
 * theme and content configs.
 */
export default function ShoppingCartDetailsBar({ show, toggleShow }: TProps) {
  const history = useHistory();

  const { t } = useTranslation();

  const {
    shoppingCart,
    dispatchShoppingCart,
    getShoppingCartProductsToRender,
  } = useShoppingCart();

  const {
    fonts: { paragraphsFont },
  } = useOSThemeAndContent();

  function handleAddToCartClick(product: TShoppingCartProductToRender): void {
    if (!product.mixinName && !product.mixinId) {
      return dispatchShoppingCart({
        type: 'add',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: get(product, 'category.name'),
        },
      });
    } else if (product.mixinName && product.mixinId) {
      return dispatchShoppingCart({
        type: 'addWithVariant',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: product.category,
          mixinId: product.mixinId,
          mixinName: product.mixinName,
        },
      });
    }
  }

  function handleRemoveFromCartClick(
    product: TShoppingCartProductToRender
  ): void {
    if (!product.mixinName && !product.mixinId) {
      return dispatchShoppingCart({
        type: 'remove',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: get(product, 'category.name'),
        },
      });
    } else if (product.mixinName && product.mixinId) {
      return dispatchShoppingCart({
        type: 'removeWithVariant',
        qty: 1,
        product: {
          _id: product._id,
          image: product.image,
          sku: product.sku,
          price: product.price,
          name: product.name,
          category: product.category,
          mixinId: product.mixinId,
          mixinName: product.mixinName,
        },
      });
    }
  }

  const shoppingCartProducts = getShoppingCartProductsToRender(
    shoppingCart.products
  );

  const shoppingCartSubtotal = Object.values(shoppingCart.products).reduce(
    (acc, product) => {
      const productSubtotal = (
        product.variants
          ? (product.variants as unknown as TShoppingCartVariant[]).reduce(
              (acc, variant) => {
                return acc + variant.subtotal;
              },
              0
            )
          : product.subtotal
      ) as number;

      return acc + productSubtotal;
    },
    0
  );

  return (
    <>
      <ShoppingDetailsContainer show={show}>
        <ShoppingDetailsProductsSection>
          {shoppingCartProducts.length === 0 ? (
            <EmptyStateParagraph fontStyle={paragraphsFont}>
              {t('shoppingCartDetailsBar.emptyStateParagraph')}
            </EmptyStateParagraph>
          ) : (
            <>
              {shoppingCartProducts.map((shoppingCartProduct, index) => {
                return (
                  <ProductContainer key={`${shoppingCartProduct._id}-${index}`}>
                    <ProductImage src={shoppingCartProduct.image} />
                    <ProductDetailsContainer>
                      <ProductNameParagraph fontStyle={paragraphsFont}>
                        {shoppingCartProduct.name}
                      </ProductNameParagraph>

                      {shoppingCartProduct.mixinName && (
                        <ProductVariantParagraph fontStyle={paragraphsFont}>
                          {shoppingCartProduct.mixinName}
                        </ProductVariantParagraph>
                      )}

                      <ProductPriceAndQty fontStyle={paragraphsFont}>
                        ${helpers.formatNumber(shoppingCartProduct.price || 0)}{' '}
                        x {shoppingCartProduct.qty}
                      </ProductPriceAndQty>
                      <ButtonsContainer>
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() =>
                            handleAddToCartClick(shoppingCartProduct)
                          }
                          style={{ margin: '0 4px' }}
                        >
                          +
                        </Button>

                        <Button
                          variant="secondary"
                          size="small"
                          onClick={() =>
                            handleRemoveFromCartClick(shoppingCartProduct)
                          }
                          style={{ margin: '0 4px' }}
                        >
                          -
                        </Button>
                      </ButtonsContainer>
                    </ProductDetailsContainer>
                  </ProductContainer>
                );
              })}
            </>
          )}
        </ShoppingDetailsProductsSection>

        {shoppingCartProducts.length !== 0 && (
          <SubtotalContainer>
            <SubtotalTextContainer>
              <p>{t('shoppingCartDetailsBar.subTotalLabel')}</p>

              <p>${helpers.formatNumber(shoppingCartSubtotal)}</p>
            </SubtotalTextContainer>

            <Button
              variant="primary"
              onClick={() => {
                toggleShow();

                history.push(ROUTES.checkout);
              }}
              style={{ width: '100%' }}
            >
              {t('shoppingCartDetailsBar.goToCheckoutButton')}
            </Button>
          </SubtotalContainer>
        )}
      </ShoppingDetailsContainer>
    </>
  );
}
