import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { Carousel } from 'kosmos-ui';

import { GET_OS_HOMEPAGE_QUERY } from 'api/graphql/queries/online-store/homepage/documents';
import { ROUTES } from 'app/constants/app';
import { ImageCard, ProductCard } from 'app/components';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import {
  SelectedProductsProductsContainer,
  SelectedProductsHeader,
  SelectedProductsSectionContainer,
  BlackMask,
  Header,
  TopCategoriesSectionContainer,
  SubHeader,
} from './styles';

/**
 * Homepage section of the app.
 * @param header Object containing all header section content
 * @param topCategories Object containing all top categories section content
 * @param selectedProducts Object containing all selected products section content
 */
export default function Home() {
  const company = useCompany();
  const osTheme = useOSThemeAndContent();

  const { topBarHeight, announcementBarHeight } = useLayout();

  const {
    data: homepageData,
    loading: loadingHomepageData,
    error: errorOnHomepageData,
  } = useQuery(GET_OS_HOMEPAGE_QUERY, {
    variables: { companyId: company._id },
  });

  const homepage: TOSHomepage = get(homepageData, 'getOSHomepage');

  if (loadingHomepageData) return <p>Cargando...</p>;

  if (errorOnHomepageData || !homepage)
    return <p>Error cargando la página de inicio!</p>;

  const header = homepage.header;

  const slides = homepage.header.slides;

  const topCategories = homepage.topCategories;

  const selectedProducts = homepage.selectedProducts;

  const showSelectedProductsSection =
    !!selectedProducts.title.trim() || selectedProducts.products.length > 0;

  const headerHeight = topBarHeight + announcementBarHeight;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | Inicio`
            : 'Cargando...'}
        </title>
      </Helmet>

      <Carousel
        withBullets
        autoplay={header.slidesTransitionSpeed * 1000}
        style={{
          root: {
            height: `calc(${header.height * 100}vh - ${headerHeight}px)`,
            paddingTop: headerHeight,
          },
        }}
      >
        {slides.map((slide) => {
          return (
            <Carousel.Slide
              key={slide._id}
              style={{
                background: `url(${slide.backgroundImage}) no-repeat center center`,
                backgroundSize: 'cover',
              }}
            >
              <BlackMask height={header.height} opacity={header.opacity} />

              <Carousel.Caption>
                <Header fontStyle={osTheme.fonts.titlesFont}>
                  {slide.title}
                </Header>

                <SubHeader fontStyle={osTheme.fonts.subtitlesFont}>
                  {slide.subtitle}
                </SubHeader>
              </Carousel.Caption>
            </Carousel.Slide>
          );
        })}
      </Carousel>

      <TopCategoriesSectionContainer>
        {topCategories
          .filter(({ isActive }: { isActive: boolean }) => isActive)
          .map(({ _id, name, image }: Distinct<TProductCategory>) => (
            <Link key={_id} to={`${ROUTES.categories}/${_id}`}>
              <ImageCard image={image} title={name} />
            </Link>
          ))}
      </TopCategoriesSectionContainer>

      {showSelectedProductsSection && (
        <SelectedProductsSectionContainer>
          <SelectedProductsHeader fontStyle={osTheme.fonts.titlesFont}>
            {selectedProducts.title}
          </SelectedProductsHeader>

          <SelectedProductsProductsContainer>
            {selectedProducts.products.map((product) => {
              return (
                <ProductCard key={product._id} product={product}>
                  <ProductCard.Image style={{ img: { height: '50vh' } }} />
                  <ProductCard.Name />
                  <ProductCard.Price />
                </ProductCard>
              );
            })}
          </SelectedProductsProductsContainer>
        </SelectedProductsSectionContainer>
      )}
    </>
  );
}
