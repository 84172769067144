import { createContext, ReactNode } from 'react';
import { FallbackScreen, theme } from 'kosmos-ui';

import { useGetCompanyByDomainQuery } from 'api/graphql/queries/company/hooks';

import { TCompanyContextValues } from './types';

export const CompanyContext = createContext({} as TCompanyContextValues);

export function CompanyContextProvider({ children }: { children: ReactNode }) {
  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorOnCompanyData,
  } = useGetCompanyByDomainQuery();

  const company = companyData?.getCompanyByDomain;

  if (loadingCompanyData)
    return (
      <FallbackScreen
        type='loading'
        color={theme.colorsPalette.black.default}
      />
    );

  if (errorOnCompanyData || (!loadingCompanyData && !company))
    return (
      <FallbackScreen
        type='error'
        color={theme.colorsPalette.gray.dark}
        message={`Error cargando ${window.location.host}!`}
      />
    );

  return (
    <CompanyContext.Provider value={company as Distinct<TCompany>}>
      {children}
    </CompanyContext.Provider>
  );
}
