import { Loader } from 'kosmos-ui';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import { getOSButtonVariantStyles } from './helpers';
import { TButtonProps } from './types';
import { ButtonContainer } from './styles';

/**
 * Render button component.
 * @param onClick Function to trigger when button is clicked.
 * @param text Text content that will display the button.
 * @param type Button type (each type has its own style).
 * @param disabled Flag that enable/disable button clicking.
 * @param loading Flag that determines if the Loader component renders or not.
 */
export default function Button({
  as: Component = 'button',
  variant,
  format,
  size,
  disabled,
  loading,
  children,
  style,
  className,
  ...props
}: TButtonProps) {
  const osTheme = useOSThemeAndContent();

  const variantStyle = getOSButtonVariantStyles({
    variant,
    format,
    size,
    osTheme: { buttons: osTheme.buttons, fonts: osTheme.fonts },
  });

  return (
    <ButtonContainer
      as={Component}
      disabled={disabled}
      style={style}
      className={className}
      variantStyle={variantStyle}
      {...props}
    >
      {loading ? <Loader /> : children}
    </ButtonContainer>
  );
}
Button.displayName = 'Button';
