import { TGetProductSortByArgs } from 'api/graphql/queries/products/product/types';

export const PRODUCTS_SORT_BY_DESTRUCTURE: Record<
  TProductsSortBy,
  ValueOf<SortByArgs<TGetProductSortByArgs>>
> = {
  nameAsc: { field: 'name', order: 'ASC' },
  nameDesc: { field: 'name', order: 'DESC' },
  priceAsc: { field: 'price', order: 'ASC' },
  priceDesc: { field: 'price', order: 'DESC' },
  createdAtAsc: { field: 'createdAt', order: 'ASC' },
  createdAtDesc: { field: 'createdAt', order: 'DESC' },
};
