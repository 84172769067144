import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useGetProductCategoryQuery } from 'api/graphql/queries/products/category/hooks';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import ProductsList from 'app/components/ProductsList';

import { HeaderContainer, Header, SubHeader, BlackMask } from './styles';

/**
 * Product category content (name, image and associated products).
 */
export default function ProductCategoryDetails() {
  const { _id: categoryId } = useParams<{ _id: string }>();

  const company = useCompany();

  const osTheme = useOSThemeAndContent();

  const { mobile, topBarHeight, announcementBarHeight } = useLayout();

  const {
    data: productCategoryData,
    loading: loadingProductCategoryData,
    error: errorOnProductCategoryData,
  } = useGetProductCategoryQuery(categoryId);

  const category = productCategoryData?.getProductCategory;

  const headerHeight = topBarHeight + announcementBarHeight;

  if (loadingProductCategoryData)
    return <p>Cargando los detalles de la categoría...</p>;

  if (errorOnProductCategoryData)
    return <p>{errorOnProductCategoryData.graphQLErrors[0]?.message}</p>;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | ${category?.name}`
            : 'Cargando...'}
        </title>
      </Helmet>

      {category?.image && category.name && (
        <HeaderContainer
          backgroundImage={category.image}
          headerHeight={headerHeight}
        >
          <BlackMask headerHeight={headerHeight} />

          <Header fontStyle={osTheme.fonts.titlesFont}>{category.name}</Header>

          <SubHeader fontStyle={osTheme.fonts.subtitlesFont}>
            {category.description}
          </SubHeader>
        </HeaderContainer>
      )}

      <ProductsList
        groupingEntity={{ entity: 'category', _id: categoryId }}
        style={{ padding: !mobile ? 52 : '24px 52px 52px 52px' }}
      />
    </>
  );
}
