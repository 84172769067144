import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetProductsPriceIntervalsQuery,
  useGetProductsQuery,
} from 'api/graphql/queries/products/product/hooks';
import {
  TGetProductFilterByArgs,
  TGetProductsPriceIntervalsVariables,
  TGetProductsVariables,
} from 'api/graphql/queries/products/product/types';
import { useCompany } from 'app/context/CompanyContext/hooks';

import { PRODUCTS_SORT_BY_DESTRUCTURE } from './constants';
import { TProductListProps, TProductListSortByOption } from './types';

export function useProductListQueries({
  groupingEntity,
  search,
  sortBy,
  filterBy,
}: Pick<TProductListProps, 'groupingEntity' | 'search'> & {
  sortBy?: TProductsSortBy;
  filterBy?: TGetProductFilterByArgs;
}) {
  const company = useCompany();

  const getProductsVariables = useMemo(() => {
    let variables: TGetProductsVariables = {};

    if (search) variables = { ...variables, search };

    if (groupingEntity?.entity === 'category')
      variables = {
        ...variables,
        categoryId: groupingEntity._id,
      };

    if (groupingEntity?.entity === 'merchant')
      variables = {
        ...variables,
        merchantId: groupingEntity._id,
      };

    return variables;
  }, [search, groupingEntity]);

  const getPriceIntervalsVariables = useMemo(() => {
    let variables: TGetProductsPriceIntervalsVariables = {
      companyId: company._id,
    };

    if (groupingEntity?.entity === 'category')
      variables = {
        ...variables,
        categoryId: groupingEntity._id,
      };

    if (groupingEntity?.entity === 'merchant')
      variables = {
        ...variables,
        merchantId: groupingEntity._id,
      };

    return variables;
  }, [company, groupingEntity]);

  const getProductsQuery = useGetProductsQuery({
    variables: getProductsVariables,
  });

  const getProductsPriceIntervalsQuery = useGetProductsPriceIntervalsQuery({
    variables: getPriceIntervalsVariables,
  });

  const refetchProductsQuery = getProductsQuery.refetch;

  useEffect(() => {
    refetchProductsQuery({
      ...getProductsVariables,
      sortBy: sortBy && PRODUCTS_SORT_BY_DESTRUCTURE[sortBy],
    });
  }, [sortBy, getProductsVariables, refetchProductsQuery]);

  useEffect(() => {
    refetchProductsQuery({
      ...getProductsVariables,
      filterCriteria: filterBy,
    });
  }, [filterBy, getProductsVariables, refetchProductsQuery]);

  return { getProductsQuery, getProductsPriceIntervalsQuery };
}

export function useProductListSortByOptions(): TProductListSortByOption[] {
  const { t } = useTranslation();

  const options: TProductListSortByOption[] = [
    {
      value: 'nameAsc',
      label: t('productList.filters.sortByOptionLabels.nameAsc'),
    },
    {
      value: 'nameDesc',
      label: t('productList.filters.sortByOptionLabels.nameDesc'),
    },
    {
      value: 'priceAsc',
      label: t('productList.filters.sortByOptionLabels.priceAsc'),
    },
    {
      value: 'priceDesc',
      label: t('productList.filters.sortByOptionLabels.priceDesc'),
    },
    {
      value: 'createdAtAsc',
      label: t('productList.filters.sortByOptionLabels.createdAtAsc'),
    },
    {
      value: 'createdAtDesc',
      label: t('productList.filters.sortByOptionLabels.createdAtDesc'),
    },
  ];

  return options;
}
