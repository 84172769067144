import styled from 'styled-components';
import { theme } from 'kosmos-ui';

type IPriceSpanProps = {
  isOriginalPrice: boolean;
  discountPrice?: number;
  fontFamily: string;
};

type TWithNavbarOrientationProps = {
  navbarOrientation: TOSNavbarOrientation;
};

type TProductDetailsContainerProps = {
  headerHeight: number;
};

const MainContainer = styled.section<TProductDetailsContainerProps>`
  display: flex;
  justify-content: center;
  padding: ${({ headerHeight }) => headerHeight + 48}px 48px;

  @media (max-width: 600px) {
    padding: 88px 28px;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 40px;
  border-radius: 8px;
  box-shadow: 2px 4px 18px rgb(0 0 0 / 2%);

  @media (max-width: 600px) {
    margin-bottom: 24px;
    margin-left: 0px;
  }
`;

const Image = styled.img<TWithNavbarOrientationProps>`
  height: 500px;
  width: ${({ navbarOrientation }) =>
    navbarOrientation === 'vertical' ? '400px' : '368px'};
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 600px) {
    height: 100%;
    width: 100%;
  }
`;

const ImagesThumbsContainer = styled.div<TWithNavbarOrientationProps>`
  display: flex;
  flex-direction: column;
  max-height: ${({ navbarOrientation }) =>
    `calc(100vh - ${navbarOrientation === 'vertical' ? '176px' : '232px'})`};
  overflow-y: scroll;
  min-width: 88px;
`;

const ImageThumbContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ImageThumb = styled.img`
  height: 120px;
  width: 88px;
  cursor: pointer;
  border-radius: 5px;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 72px;
  width: 480px;

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const NameHeader = styled.h1<{ fontStyle: TOSFont }>`
  color: ${({ fontStyle }) => fontStyle.color};
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-family: ${({ fontStyle }) => fontStyle.family};
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  line-height: 1;
  margin: 0;
`;

const CategorySubHeader = styled.h2<{ fontFamily: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 12px 0;
`;

const Price = styled.span<IPriceSpanProps>`
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ theme, isOriginalPrice }) =>
    theme.fontWeights[isOriginalPrice ? 'regular' : 'bold']};
  text-decoration: ${({ discountPrice, isOriginalPrice }) =>
    discountPrice && isOriginalPrice ? 'line-through' : 'none'};
  margin-left: ${({ isOriginalPrice }) => (isOriginalPrice ? '8px' : '0')};
`;

const PriceContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  margin-bottom: 24px;
`;

const Paragraph = styled.p<{ fontStyle: TOSFont }>`
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-family: ${({ fontStyle }) => fontStyle.family};
  font-weight: ${({ fontStyle }) => fontStyle.weight};
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin: 24px 0;
  align-items: center;
`;

const selectStyles = {
  //@ts-ignore
  control(base) {
    return {
      ...base,
      border: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
      '&:focus': { border: 'none', boxShadow: 'none' },
      '&:active': { border: 'none', boxShadow: 'none' },
    };
  },
  //@ts-ignore
  container(base) {
    return {
      ...base,
      border: 'none',
      borderBottom: '1px solid #999',
      marginBottom: '24px',
      '&:hover': {
        borderBottom: '1px solid black',
      },
    };
  },
  //@ts-ignore
  valueContainer(base) {
    return { ...base, padding: '8px 0' };
  },
  //@ts-ignore
  dropdownIndicator(base) {
    return {
      ...base,
    };
  },
  //@ts-ignore
  menuList(base) {
    return {
      ...base,
      color: 'black',
    };
  },
  //@ts-ignore
  menu(base) {
    return {
      ...base,
      backgroundColor: 'white',
    };
  },
  indicatorSeparator() {
    return { display: 'none' };
  },
  //@ts-ignore
  singleValue(base) {
    return {
      ...base,
      marginLeft: '0',
      color: '#808080',
      fontSize: theme.fontSizes.regular,
    };
  },
  //@ts-ignore
  placeholder(base) {
    return {
      ...base,
      marginLeft: '0',
      color: '#808080',
      fontSize: theme.fontSizes.regular,
    };
  },
};

export {
  MainContainer,
  ImageContainer,
  ImagesThumbsContainer,
  Image,
  ImageThumbContainer,
  ImageThumb,
  InfoContainer,
  NameContainer,
  NameHeader,
  CategorySubHeader,
  Price,
  PriceContainer,
  Paragraph,
  ButtonsContainer,
  selectStyles,
};
