import { lighten } from 'polished';
import styled from 'styled-components';

export const ShopperSidebarContainer = styled.aside<{ show: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  min-width: 160px;
  max-width: 184px;
  z-index: 2;
  left: 0;
  padding: 16px;
  margin: 8px;
  border-radius: 8px;
  box-shadow: ${({ show }) =>
    show ? '2px 4px 12px rgb(0 0 0 / 4%)' : 'unset'};
  transform: ${({ show }) => (!show ? 'translateX(-110%)' : 'translateX(0)')};
  transition: transform 0.3s ease-out;
`;

export const ShopperSidebarSectionContainer = styled.div<{
  selected: boolean;
  fontStyle: TOSFont;
  color: string;
}>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  color: ${({ theme, selected, color }) =>
    selected ? color : theme.colorsPalette.gray.dark};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  font-family: ${({ fontStyle }) => fontStyle.family};
  transition: background-color 0.5s;
  background-color: ${({ theme, selected, color }) =>
    selected ? lighten(0.9, color) : theme.colorsPalette.white.default};

  &:hover {
    color: ${({ color }) => color};
  }
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  margin-left: 16px;
  color: inherit;
`;
