import { createContext, ReactNode } from 'react';

import { useGetMeAsShopperLazyQuery } from 'api/graphql/queries/shopper/hooks';
import { useGetAuthByDeviceQuery } from 'api/graphql/queries/auth/hooks';
import { AUTH_TOKEN_KEYS } from 'app/constants/app';
import { usePrevious } from 'app/hooks/general';

import { TShopperContextValues } from './types';

export const ShopperContext = createContext({} as TShopperContextValues);

export function ShopperContextProvider({ children }: { children: ReactNode }) {
  const [
    meAsShopperLazyQuery,
    {
      data: meAsShopperData,
      loading: loadingShopperData,
      error: errorOnShopperData,
    },
  ] = useGetMeAsShopperLazyQuery();

  const { loading: loadingGetAuthByDeviceData } = useGetAuthByDeviceQuery({
    onCompleted(data) {
      const token = data.getAuthByDevice?.token;

      if (token) localStorage.setItem(AUTH_TOKEN_KEYS.shopper, token);
      else localStorage.clear();

      meAsShopperLazyQuery();
    },
  });

  const prevLoadingGetAuthByDeviceData = usePrevious<boolean>(
    loadingGetAuthByDeviceData
  );

  const shopper = meAsShopperData?.meAsShopper;

  return (
    <ShopperContext.Provider
      value={{
        shopper,
        loadingShopperData:
          loadingGetAuthByDeviceData ||
          loadingShopperData ||
          !!prevLoadingGetAuthByDeviceData,
        errorOnShopperData,
      }}
    >
      {children}
    </ShopperContext.Provider>
  );
}
