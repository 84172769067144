import { gql } from '@apollo/client';

export const GET_OS_ANNOUNCEMENT_BAR_QUERY = gql`
  query GET_OS_ANNOUNCEMENT_BAR_QUERY($companyId: ID!) {
    getOSAnnouncementBar(companyId: $companyId) {
      _id
      show
      message
      backgroundColor
      fontColor
      createdAt
      updatedAt
    }
  }
`;
