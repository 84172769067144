import helpers from 'app/helpers';

export function getCouponDiscountText(
  discountValue: number,
  discountType: TCouponDiscountType
): string {
  switch (discountType) {
    case 'PERCENTAGE':
      return `${discountValue}% off`;

    case 'PRICE':
      return `-$${helpers.formatNumber(discountValue)}`;

    default:
      return '';
  }
}

export function getCheckoutFinalAmount(
  productsAmount: number,
  shippingCost: number
) {
  return productsAmount + shippingCost;
}

export function getCheckoutCouponDiscountPrice(
  discountType: string,
  couponValue: number,
  baseAmount: number
) {
  switch (discountType) {
    case 'PERCENTAGE': {
      return baseAmount * (1 - couponValue / 100);
    }
    case 'PRICE': {
      return baseAmount - couponValue;
    }
    default:
      return baseAmount;
  }
}
