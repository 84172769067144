import styled from 'styled-components';

type TStyledMainContainerProps = {
  headerHeight: number;
};

export const CheckoutContainer = styled.section<TStyledMainContainerProps>`
  display: flex;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};
  height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}px)`};
  padding-top: ${({ headerHeight }) => headerHeight}px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CheckoutPaymentFormContainer = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 72px 56px;

  @media (max-width: 480px) {
    padding: 32px 56px 0 56px;
    height: calc(100vh - 96px);
  }
`;
