import { gql } from '@apollo/client';

export const GET_COMPANY_BY_DOMAIN_QUERY = gql`
  query GET_COMPANY_BY_DOMAIN_QUERY($domain: String!) {
    getCompanyByDomain(domain: $domain) {
      _id
      address1
      address2
      apps {
        ga {
          trackingId
        }
      }
      domain
      email1
      email2
      fantasyName
      facebook
      instagram
      logo
      phone1
      phone2
    }
  }
`;
