import { useQuery } from '@apollo/client';
import { GET_COMPANY_BY_DOMAIN_QUERY } from './documents';
import {
  TGetCompanyByDomainData,
  TGetCompanyByDomainVariables,
  TGetCompanyByDomainOptions,
} from './types';

export function useGetCompanyByDomainQuery(
  options?: TGetCompanyByDomainOptions
) {
  const query = useQuery<TGetCompanyByDomainData, TGetCompanyByDomainVariables>(
    GET_COMPANY_BY_DOMAIN_QUERY,
    {
      variables: {
        domain: `${window.location.protocol}//${window.location.host}`,
      },
      ...options,
    }
  );

  return query;
}
