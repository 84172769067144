import { gql } from '@apollo/client';

export const GET_PRODUCT_MERCHANT_QUERY = gql`
  query GET_PRODUCT_MERCHANT_QUERY($_id: ID!) {
    getProductMerchant(_id: $_id) {
      _id
      name
      description
      image
      products {
        _id
        discountPrice
        images {
          url
        }
        isInSale
        name
        price
      }
    }
  }
`;
