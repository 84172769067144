import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { theme } from 'kosmos-ui';

type IStyledButtonProps = {
  backgroundColor: string;
  fontColor: string;
  disabled?: boolean;
};

export const PaymentFormContainer = styled.form`
  max-width: 372px;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 440px;
  position: relative;
`;

export const PaymentFormButton = styled.button<IStyledButtonProps>`
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 12px 24px;
  max-width: 180px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ fontColor }) => fontColor};
  border-radius: 5px;
  border: none;
  transition: all 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    background-color: ${({ backgroundColor }) => darken(0.3, backgroundColor)};
    top: 1px;
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
    cursor: pointer;
  }

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (min-width: 480px) {
    border: 1px solid ${({ fontColor }) => fontColor};
    background-color: transparent;
  }
`;

export const PaymentFormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PaymentFormFooterContainer = styled.footer`
  display: flex;
  justify-content: flex-end;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 32px;
  width: 100%;

  @media (min-width: 600px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const PaymentGatewayContainer = styled.aside<{
  checked: boolean;
  backgroundColor: string;
  fontColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  border: 2px solid
    ${({ checked, backgroundColor, fontColor }) =>
      !checked ? lighten(0.3, backgroundColor) : fontColor};
  border-radius: 8px;
  width: 100%;

  @media (max-width: 600px) {
    border: 2px solid
      ${({ checked, backgroundColor, fontColor }) =>
        checked ? lighten(0.3, backgroundColor) : darken(0.1, fontColor)};
  }
`;

export const PaymentGatewayHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const paymentFormSelectStyles = {
  //@ts-ignore
  control: (base) => ({
    ...base,
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 'none',
      borderBottom: '1px solid black',
      boxShadow: 'none',
    },
    '&:focus': { border: 'none', boxShadow: 'none' },
    '&:active': { border: 'none', boxShadow: 'none' },
  }),
  //@ts-ignore
  container: (base) => ({
    ...base,
    //width: "50%",
    border: 'none',
    borderBottom: '1px solid #999',
    margin: '24px 0px',
    '@media (max-width: 600px)': { margin: '12px 0px' },
  }),
  //@ts-ignore
  valueContainer(base) {
    return { ...base, padding: '8px 0' };
  },
  //@ts-ignore
  dropdownIndicator(base) {
    return {
      ...base,
    };
  },
  //@ts-ignore
  menuList(base) {
    return {
      ...base,
      color: 'black',
    };
  },
  indicatorSeparator() {
    return { display: 'none' };
  },

  //@ts-ignore
  singleValue(base) {
    return {
      ...base,
      marginLeft: '0',
      color: '#808080',
      fontSize: theme.fontSizes.regular,
    };
  },
  //@ts-ignore
  placeholder(base) {
    return {
      ...base,
      marginLeft: '0',
      color: '#808080',
      fontSize: theme.fontSizes.regular,
    };
  },
};
