import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';

import apolloClient from '../api/graphql/client';
import Routes from './routes';
import { GlobalSearchContextProvider } from './context/GlobalSearchContext';
import { ShopperContextProvider } from './context/ShopperContext';
import ThemeContextProvider from './context/ThemeContext';
import { LayoutContextProvider } from './context/LayoutContext';
import { AlertsContextProvider } from './context/AlertsContext';
import { CompanyContextProvider } from './context/CompanyContext';
import { OSLocalesContextProvider } from './context/OSLocalesContext';
import ShoppingCartContextProvider from './context/ShoppingCartContext';
import OSThemeAndContentContextProvider from './context/OSThemeAndContentContext';

import '../locales/i18n';

export default function App() {
  console.log({ host: window.location });

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <CompanyContextProvider>
          <OSLocalesContextProvider>
            <ThemeContextProvider>
              <LayoutContextProvider>
                <AlertsContextProvider>
                  <ShopperContextProvider>
                    <ShoppingCartContextProvider>
                      <OSThemeAndContentContextProvider>
                        <GlobalSearchContextProvider>
                          <Routes />
                        </GlobalSearchContextProvider>
                      </OSThemeAndContentContextProvider>
                    </ShoppingCartContextProvider>
                  </ShopperContextProvider>
                </AlertsContextProvider>
              </LayoutContextProvider>
            </ThemeContextProvider>
          </OSLocalesContextProvider>
        </CompanyContextProvider>
      </Router>
    </ApolloProvider>
  );
}
