import { useSignOutShopperMutation } from 'api/graphql/mutations/shopper/hooks';
import { useTranslation } from 'react-i18next';
import { theme, SignOutIcon, Loader } from 'kosmos-ui/lib';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { getShopperBrowserSidebarSections } from '../../helpers';

import { getShopperBrowserSidebarIconColors } from './helpers';
import {
  ShopperSidebarContainer,
  ShopperSidebarSectionContainer,
} from './styles';
import { TShopperBrowserSidebarProps } from './types';

export default function ShopperBrowserSidebar({
  show,
  activeSection,
  onSectionChange,
}: TShopperBrowserSidebarProps) {
  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  const [signOutShopperMutation, { loading: loadingSignOutShopperMutation }] =
    useSignOutShopperMutation();

  function handleSignOutShopperClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();

    signOutShopperMutation();
  }

  return (
    <ShopperSidebarContainer show={show}>
      {getShopperBrowserSidebarSections(t).map((section) => {
        return (
          <ShopperSidebarSectionContainer
            key={section._id}
            selected={activeSection === section._id}
            onClick={() => onSectionChange(section._id)}
            fontStyle={osTheme.fonts.paragraphsFont}
            color={osTheme.navbar.elementsColor}
          >
            <section.icon
              height="18px"
              style={{ marginRight: 8 }}
              {...getShopperBrowserSidebarIconColors({
                iconIsActive: activeSection === section._id,
                osTheme,
              })}
            />

            {section.label}
          </ShopperSidebarSectionContainer>
        );
      })}

      <ShopperSidebarSectionContainer
        selected={false}
        onClick={handleSignOutShopperClick}
        fontStyle={osTheme.fonts.paragraphsFont}
        color={osTheme.navbar.elementsColor}
      >
        <SignOutIcon
          height="16px"
          style={{ marginRight: 8, color: theme.colorsPalette.gray.light }}
        />
        {loadingSignOutShopperMutation ? (
          <Loader />
        ) : (
          t('shopperBrowser.sidebar.signOutLabel')
        )}
      </ShopperSidebarSectionContainer>
    </ShopperSidebarContainer>
  );
}
