import { gql } from '@apollo/client';

export const GET_DELIVERY_SETTINGS_QUERY = gql`
  query GET_DELIVERY_SETTINGS_QUERY($companyId: ID) {
    getDeliverySettings(companyId: $companyId) {
      _id
      deliveryInfo
      deliveryDates
      storePickup
    }
  }
`;
