import { gql } from '@apollo/client';

export const GET_ME_AS_SHOPPER_QUERY = gql`
  query GET_ME_AS_SHOPPER_QUERY {
    meAsShopper {
      _id
      email
      avatar
      firstname
      lastname
      rut
      phone
      locations {
        city
        commune
        address
      }
      orders {
        _id
        confirmationNumber
        status
        totalAmount
        amountWithoutDiscount
        productsAmount
        shippingCost
        createdAt
        deliveryNotes
        deliveryLocation {
          name
        }
        deliveryZone {
          name
        }
        deliveryType
        deliveryAddress
        products {
          qty
          subtotal
          price
          image
          name
          category
          sku
          variants {
            mixinName
            qty
            price
            subtotal
            sku
          }
        }
        payment {
          cardLast4digits
          type
        }
        coupon {
          _id
          code
          value
          discountType
        }
        shopper {
          firstname
          lastname
        }
      }
    }
  }
`;
