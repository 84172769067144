import { theme } from 'kosmos-ui/lib';
import { CSSProperties } from 'react';

export function getOrderStatusChipStyles(status: TOrderStatus): CSSProperties {
  switch (status) {
    case 'delivering':
      return {
        backgroundColor: theme.colorsPalette.blue.lighter,
        color: theme.colorsPalette.blue.default,
      };

    case 'preparing':
      return {
        backgroundColor: theme.colorsPalette.gray.lighter,
        color: theme.colorsPalette.gray.default,
      };

    case 'canceled':
      return {
        backgroundColor: theme.colorsPalette.red.lighter,
        color: theme.colorsPalette.red.default,
      };

    case 'done':
      return {
        backgroundColor: theme.colorsPalette.green.lighter,
        color: theme.colorsPalette.green.default,
      };

    case 'queued':
      return {
        backgroundColor: theme.colorsPalette.yellow.lighter,
        color: theme.colorsPalette.yellow.default,
      };

    default:
      return {
        backgroundColor: theme.colorsPalette.yellow.lighter,
        color: theme.colorsPalette.yellow.default,
      };
  }
}
