import { useQuery } from "@apollo/client";

import { GET_AUTH_BY_DEVICE_QUERY } from "./documents";
import { TGetAuthByDeviceData, TGetAuthByDeviceOptions } from "./types";

export function useGetAuthByDeviceQuery(options?: TGetAuthByDeviceOptions) {
  const query = useQuery<TGetAuthByDeviceData>(
    GET_AUTH_BY_DEVICE_QUERY,
    options
  );

  return query;
}
