import { useTranslation } from 'react-i18next';
import { IllustratedMessage } from 'kosmos-ui/lib';

import { useShopper } from 'app/context/ShopperContext/hooks';

import { SectionContainer } from '../../styles';
import { shopperOrdersIllustratedMessageStyle } from './styles';
import ShopperOrderCard from './components/ShopperOrderCard';

export default function ShopperOrdersSection() {
  const { t } = useTranslation();

  const { shopper } = useShopper();

  const orders = shopper?.orders;

  if (!orders) return null;

  if (orders.length === 0)
    return (
      <IllustratedMessage
        illustration="emptyInbox"
        header={t('shopperBrowser.orders.emptyOrdersTitle')}
        body={t('shopperBrowser.orders.emptyOrdersDescription')}
        mainContainerStyle={shopperOrdersIllustratedMessageStyle}
      />
    );

  return (
    <SectionContainer>
      {orders.map((order) => (
        <ShopperOrderCard key={order._id} order={order} />
      ))}
    </SectionContainer>
  );
}
