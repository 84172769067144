import styled from 'styled-components';

import helpers from 'app/helpers';

type TWithNavbarOrientationProps = {
  navbarOrientation: TOSNavbarOrientation;
};

type IHeaderContainerProps = TWithNavbarOrientationProps & {
  backgroundImage: string;
};

const ContentContainer = styled.section<{ pageType: TOSPageType }>`
  padding: 48px ${({ pageType }) => (pageType === 'gallery' ? '56px' : '25vw')};
  text-align: center;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};

  @media (max-width: 600px) {
    padding: 48px ${({ pageType }) => (pageType === 'gallery' ? '24px' : '0')};
  }
`;

const HeaderContainer = styled.section<IHeaderContainerProps>`
  background: url('${({ backgroundImage }) => backgroundImage}') no-repeat
    center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ navbarOrientation }) =>
    navbarOrientation === 'vertical' ? '64px' : '128px'};
  min-height: 50vh;
`;

const BlackMask = styled.div<TWithNavbarOrientationProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50vh;
  padding-top: ${({ navbarOrientation }) =>
    navbarOrientation === 'vertical' ? '64px' : '128px'};
  background: rgba(0, 0, 0, 0.4);
`;

const Header = styled.h1<{ fontStyle: TOSFont }>`
  margin-bottom: 72px;
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  font-family: ${({ fontStyle }) => fontStyle.family};
  z-index: 1;

  @media (max-width: 600px) {
    font-size: ${({ fontStyle }) =>
      helpers.getMobileCustomFontSize(fontStyle.size, 'header')};
    line-height: 1;
    text-align: center;
  }
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 32px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }
`;

const GalleryImage = styled.img`
  display: inline;
  height: 50vh;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 1250px) {
    height: 50vh;
  }
  @media (max-width: 1030px) {
    height: 45vh;
  }
  @media (max-width: 600px) {
    height: 50vh;
  }
`;

export {
  BlackMask,
  ContentContainer,
  Header,
  HeaderContainer,
  GalleryContainer,
  GalleryImage,
};
