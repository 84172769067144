import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { verify } from 'jsonwebtoken';
import { Card } from 'kosmos-ui';

import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import Typography from 'app/components/Typography';
import { Button } from 'app/components';
import { TShoppingCartActionProduct } from 'app/context/ShoppingCartContext/types';
import { ROUTES } from 'app/constants/app';

import { getPaymentResultLabels } from './helpers';
import { TPaymentResultPayload } from './types';
import { PaymentResultContainer, PaymentResultFooter } from './styles';

export default function PaymentResult() {
  const { resultToken } = useParams<{ resultToken: string }>();

  const history = useHistory();

  const { dispatchShoppingCart } = useShoppingCart();

  useEffect(() => {
    dispatchShoppingCart({
      type: 'reset',
      product: {} as TShoppingCartActionProduct,
      qty: 0,
    });
  }, [dispatchShoppingCart]);

  let payload: TPaymentResultPayload | undefined;

  try {
    payload = verify(
      resultToken,
      process.env.REACT_APP_JWT_SECRET || ''
    ) as TPaymentResultPayload;
  } catch (error) {
    payload = undefined;
  }

  const labels = getPaymentResultLabels(payload);

  if (!labels) {
    history.push(ROUTES.home);

    return null;
  }

  return (
    <PaymentResultContainer>
      <Card style={{ maxWidth: 380 }}>
        {labels.icon}

        <Typography
          as="h1"
          variant="title2"
          style={{ margin: '8px 0 24px 0', textAlign: 'center' }}
        >
          {labels.header}
        </Typography>

        <Typography as="p" variant="paragraph1">
          {labels.description}
        </Typography>

        <PaymentResultFooter>
          <Button
            onClick={() => history.push(ROUTES.home)}
            variant="primary"
            style={{ flex: 1 }}
          >
            {labels.action}
          </Button>
        </PaymentResultFooter>
      </Card>
    </PaymentResultContainer>
  );
}
