import { useQuery } from '@apollo/client';
import { useCompany } from 'app/context/CompanyContext/hooks';

import {
  GET_PRODUCTS_PRICE_INTERVALS_QUERY,
  GET_PRODUCTS_QUERY,
} from './documents';
import {
  TGetProductsData,
  TGetProductsOptions,
  TGetProductsPriceIntervalsData,
  TGetProductsPriceIntervalsOptions,
  TGetProductsPriceIntervalsVariables,
  TGetProductsVariables,
} from './types';

export function useGetProductsQuery(options?: TGetProductsOptions) {
  const company = useCompany();

  const query = useQuery<TGetProductsData, TGetProductsVariables>(
    GET_PRODUCTS_QUERY,
    {
      ...options,
      variables: { companyId: company._id, first: 25, ...options?.variables },
      nextFetchPolicy: 'cache-first',
    }
  );

  return query;
}

export function useGetProductsPriceIntervalsQuery(
  options: TGetProductsPriceIntervalsOptions
) {
  const query = useQuery<
    TGetProductsPriceIntervalsData,
    TGetProductsPriceIntervalsVariables
  >(GET_PRODUCTS_PRICE_INTERVALS_QUERY, options);

  return query;
}
