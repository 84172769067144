import { TOSThemeAndContentContextValues } from './types';

export const DEFAULT_OS_THEME_AND_CONTENT_CONTEXT_VALUES = {
  buttons: {
    _id: '',
    primary: {
      backgroundColor: '',
      fontColor: '',
    },
    danger: {
      backgroundColor: '',
      fontColor: '',
    },
    success: {
      backgroundColor: '',
      fontColor: '',
    },
  },
  customPages: [],
  fonts: {
    _id: '',
    titlesFont: {
      color: '',
      family: '',
      size: '',
      weight: '',
    },
    subtitlesFont: {
      color: '',
      family: '',
      size: '',
      weight: '',
    },
    paragraphsFont: {
      color: '',
      family: '',
      size: '',
      weight: '',
    },
  },
  navbar: {
    _id: '',
    navlink: {
      _id: '',
      children: [
        {
          _id: '',
          label: '',
          sequenceId: 0,
          link: '',
          updatedAt: new Date(),
        },
      ],
    },
    brandLogo: '',
    backgroundColor: '',
    elementsColor: '',
    orientation: 'vertical',
  },
  footer: {
    contactSection: {
      active: true,
      title: '',
      addresses: [],
      emails: [],
      phones: [],
    },
    socialNetworksSection: {
      active: true,
      title: '',
      instagram: '',
      facebook: '',
    },
  },
} as TOSThemeAndContentContextValues;
