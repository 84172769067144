import { useState } from 'react';
import {
  IllustratedMessage,
  NoSearchResultsIllustration,
  Skeleton,
} from 'kosmos-ui/lib';
import { lighten } from 'polished';

import { TGetProductFilterByArgs } from 'api/graphql/queries/products/product/types';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import Button from '../Button';
import Typography from '../Typography';
import ProductCard from '../ProductCard';

import { useProductListQueries } from './hooks';
import { ProductsListFilters } from './components';
import { TProductListProps } from './types';
import {
  ProductsListContainer,
  ProductsListItemsContainer,
  ProductsListFetchMoreContainer,
} from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Renders a paginated and sortable list of products.
 * @param groupingEntity - Product grouping entity for which render the list of items.
 * @param search - Search term.
 * @param style - Styles for root and products containers.
 */
export default function ProductsList({
  groupingEntity,
  search,
  style,
}: TProductListProps) {
  const [filterBy, setFilterBy] = useState<
    TGetProductFilterByArgs | undefined
  >();

  const [sortBy, setSortBy] = useState<TProductsSortBy | undefined>();

  const { t } = useTranslation();

  const osTheme = useOSThemeAndContent();

  const {
    getProductsQuery: {
      data: productsData,
      loading: loadingProductsData,
      error: errorOnProductsData,
      fetchMore: fetchMoreProducts,
    },
    getProductsPriceIntervalsQuery,
  } = useProductListQueries({
    sortBy,
    filterBy,
    search,
    groupingEntity,
  });

  const products = productsData?.getProducts.edges.map(
    (productEdge) => productEdge.node
  );

  const productsPageInfo = productsData?.getProducts.pageInfo;

  if (loadingProductsData)
    return (
      <ProductsListContainer>
        <ProductsListItemsContainer>
          {[...new Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              style={{ width: '100%', height: 'calc(50vh + 96px)' }}
            />
          ))}
        </ProductsListItemsContainer>
      </ProductsListContainer>
    );

  if (errorOnProductsData)
    return (
      <ProductsListContainer>
        <p>{t('productList.errorLoadingProductsLabel')}</p>
      </ProductsListContainer>
    );

  return (
    <ProductsListContainer style={style}>
      <ProductsListFilters
        filterBy={filterBy}
        sortBy={sortBy}
        setFilterBy={setFilterBy}
        setSortBy={setSortBy}
        priceInterval={
          getProductsPriceIntervalsQuery.data?.getProductsPriceIntervals
        }
        loadingPriceIntervals={getProductsPriceIntervalsQuery.loading}
      />

      {products?.length === 0 ? (
        <IllustratedMessage
          illustration={
            <NoSearchResultsIllustration
              colors={{
                dark: osTheme.buttons.primary.backgroundColor,
                default: lighten(0.55, osTheme.buttons.primary.backgroundColor),
                light: lighten(0.8, osTheme.buttons.primary.backgroundColor),
              }}
            />
          }
          illustrationConfigs={{ style: {} }}
          header={
            <Typography as="h1" variant="title4" style={{ marginBottom: 8 }}>
              {t('productList.productsNotFoundTitle')}
            </Typography>
          }
          body={
            <Typography as="p" variant="paragraph1">
              {t('productList.productsNotFoundDescription')}
            </Typography>
          }
        />
      ) : (
        <ProductsListItemsContainer>
          {products?.map((product) => (
            <ProductCard key={product._id} product={product}>
              <ProductCard.Image style={{ img: { height: '50vh' } }} />
              <ProductCard.Name />
              <ProductCard.Price />
            </ProductCard>
          ))}
        </ProductsListItemsContainer>
      )}

      {productsPageInfo?.hasNextPage && (
        <ProductsListFetchMoreContainer>
          <Button
            variant="primary"
            onClick={() =>
              fetchMoreProducts({
                variables: { after: productsPageInfo?.endCursor },
              })
            }
            loading={loadingProductsData}
          >
            {t('productList.fetchMoreButton')}
          </Button>
        </ProductsListFetchMoreContainer>
      )}
    </ProductsListContainer>
  );
}
