import { useHistory, useLocation } from 'react-router';

import { TUseBackgroundLocationHookState } from './types';

/**
 * Builds on RR useLocation to parse a query string.
 */
export function useQueryParam() {
  const queryParam = new URLSearchParams(useLocation().search);

  return queryParam;
}

/**
 * Provides a location object with a background state and go back callback.
 * @returns - The current app location, the background location (if exists)
 * and a go back callback for going back to the background route (to "/" if
 * there is no one defined).
 */
export function useBackgroundLocation() {
  const history = useHistory();

  const location = useLocation<TUseBackgroundLocationHookState | undefined>();
  const background = location.state?.background;

  function goBack(event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event?.stopPropagation();

    if (!background) return history.push('/');

    return history.goBack();
  }

  return {
    location,
    locationBackground: background,
    goBackToBackgroundLocation: goBack,
  };
}
