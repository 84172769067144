import { useState } from 'react';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { ChildNavlink, NavlinkLabel } from 'app/sections/Navigation/styles';

import { TProps } from './types';
import { MainContainer } from './styles';

export default function CollapsableNavLink(props: TProps) {
  const { navbar, fonts } = useOSThemeAndContent();

  const [expanded, setExpanded] = useState(false);

  const children = props.children.filter((child) => child.link);

  return (
    <MainContainer
      expanded={expanded}
      onClick={() => setExpanded((prevExpandedState) => !prevExpandedState)}
    >
      <NavlinkLabel
        fontStyle={{
          ...fonts.paragraphsFont,
          color: navbar.elementsColor,
        }}
        style={{ marginBottom: expanded ? 8 : 0 }}
      >
        {props.label}
      </NavlinkLabel>

      {expanded &&
        children.map((child) => {
          return (
            <ChildNavlink
              key={child._id}
              onClick={() => props.onChildClick && props.onChildClick()}
              to={child.link || ''}
              fontStyle={{
                ...fonts.paragraphsFont,
                color: navbar.elementsColor,
              }}
            >
              {child.label}
            </ChildNavlink>
          );
        })}
    </MainContainer>
  );
}
