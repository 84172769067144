import { TPaymentCardVariant } from 'kosmos-ui/lib/icons/PaymentCard';

export const ROUTES: Record<TRoute, string> = {
  home: '/',
  products: '/products',
  product: '/product',
  productsSearchResults: '/products/search',
  categories: '/categories',
  merchants: '/merchants',
  customPages: '/custom-pages',
  checkout: '/checkout',
  paymentResult: '/payment/result',
  shopperSignIn: '/shopper/signin',
  shopperSignUp: '/shopper/signup',
  shopperBrowser: '/shopper/browse',
};

export const LINKS_AND_ROUTES = Object.entries(ROUTES).reduce(
  (acc, [key, value]) => {
    return { ...acc, [value as string]: key as TRoute };
  },
  {} as Record<string, TRoute>
);

export const PAYMENT_CARDS_VARIANTS: TPaymentCardVariant[] = [
  'visa',
  'mastercard',
  'dinersClub',
  'magna',
  'americanExpress',
];

export const SEARCH_DEBOUNCE_DELAY = 500;

export const AUTH_TOKEN_KEYS: Record<TAuthTokenEntity, string> = {
  shopper: 'GrafitoAppShopperAuthToken',
};

export const ORDER_STATUS_DISPLAY_NAMES: Record<TOrderStatus, string> = {
  queued: 'Pendiente',
  preparing: 'Preparando',
  delivering: 'Entregando',
  done: 'Entregada',
  canceled: 'Cancelada',
};
