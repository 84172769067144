import { AccountShieldIcon, ShoppingBagIcon, ProfileIcon } from 'kosmos-ui/lib';
import { TFunction } from 'react-i18next';

import { TShopperBrowserSidebarSection } from './types';

export function getShopperBrowserSidebarSections(
  t: TFunction<'translation', undefined>
): TShopperBrowserSidebarSection[] {
  return [
    {
      _id: 'profile',
      label: t('shopperBrowser.sidebar.profileLabel'),
      icon: ProfileIcon,
    },
    {
      _id: 'orders',
      label: t('shopperBrowser.sidebar.ordersLabel'),
      icon: ShoppingBagIcon,
    },
    {
      _id: 'account',
      label: t('shopperBrowser.sidebar.accountLabel'),
      icon: AccountShieldIcon,
    },
  ];
}
