import { gql } from '@apollo/client';

export const GET_OS_PAGES_QUERY = gql`
  query GET_OS_PAGES_QUERY($companyId: ID) {
    getOnlineStoreBlogs(companyId: $companyId) {
      _id
      title
    }
    getOnlineStoreGalleries(companyId: $companyId) {
      _id
      title
      images {
        _id
      }
    }
  }
`;

export const GET_OS_BLOG_QUERY = gql`
  query GET_OS_BLOG_QUERY($_id: ID!) {
    getOnlineStoreBlog(_id: $_id) {
      _id
      title
      backgroundImage
      content
      subtitle
    }
  }
`;

export const GET_OS_GALLERY_QUERY = gql`
  query GET_OS_GALLERY_QUERY($_id: ID!) {
    getOnlineStoreGallery(_id: $_id) {
      _id
      title
      images {
        _id
        url
        sequenceId
      }
      backgroundImage
      createdAt
      updatedAt
    }
  }
`;
