import { gql } from '@apollo/client';

export const GET_OS_FONTS_QUERY = gql`
  query GET_OS_FONTS_QUERY($companyId: ID) {
    getOnlineStoreFonts(companyId: $companyId) {
      _id
      titlesFont {
        color
        family
        size
        weight
      }
      subtitlesFont {
        color
        family
        size
        weight
      }
      paragraphsFont {
        color
        family
        size
        weight
      }
    }
  }
`;
