import { gql } from '@apollo/client';

export const GET_PRODUCT_QUERY = gql`
  query GET_PRODUCT_QUERY($_id: ID!) {
    getProduct(_id: $_id) {
      _id
      category {
        name
      }
      description
      discountPrice
      images {
        _id
        url
        index
      }
      isSelectedProduct
      isInSale
      name
      price
      sku
      stock
      variants {
        _id
        stock
        price
        sku
        discountPrice
        mixin {
          _id
          name
        }
      }
    }
  }
`;

export const GET_PRODUCTS_QUERY = gql`
  query GET_PRODUCTS_QUERY(
    $companyId: ID
    $categoryId: ID
    $merchantId: ID
    $first: Int
    $after: String
    $filterCriteria: ProductFilterCriteriaInput
    $search: String
    $sortBy: SortByInput
  ) {
    getProducts(
      companyId: $companyId
      categoryId: $categoryId
      merchantId: $merchantId
      first: $first
      after: $after
      filterCriteria: $filterCriteria
      search: $search
      sortBy: $sortBy
    ) {
      edges {
        node {
          _id
          name
          price
          isSelectedProduct
          isInSale
          images {
            _id
            index
            url
          }
          category {
            _id
            name
          }
          description
          discountPrice
          sku
          stock
          variants {
            _id
            stock
            sku
            price
            discountPrice
            mixin {
              _id
              name
            }
          }
          dimensions {
            height
            width
            weight
            length
          }
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_PRODUCTS_PRICE_INTERVALS_QUERY = gql`
  query GET_PRODUCTS_PRICE_INTERVALS_QUERY(
    $companyId: ID
    $categoryId: ID
    $merchantId: ID
  ) {
    getProductsPriceIntervals(
      companyId: $companyId
      categoryId: $categoryId
      merchantId: $merchantId
    ) {
      min
      max
      intervals
    }
  }
`;
