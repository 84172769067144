import { TShopperBrowserSection } from '../../types';
import ShopperAccountSection from '../ShopperAccountSection';
import ShopperOrdersSection from '../ShopperOrdersSection';
import ShopperProfileSection from '../ShopperProfileSection';

export default function ShopperBrowserSectionSwitcher({
  activeSection,
}: {
  activeSection: TShopperBrowserSection;
}) {
  switch (activeSection) {
    case 'profile':
      return <ShopperProfileSection />;

    case 'orders':
      return <ShopperOrdersSection />;

    case 'account':
      return <ShopperAccountSection />;

    default:
      return null;
  }
}
