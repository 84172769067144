import { gql } from '@apollo/client';

export const SIGN_UP_SHOPPER_MUTATION = gql`
  mutation SIGN_UP_SHOPPER_MUTATION(
    $email: String!
    $password: String!
    $firstname: String!
    $lastname: String!
    $avatar: String
    $phone: String
    $rut: String
  ) {
    signUpShopper(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      phone: $phone
      rut: $rut
    ) {
      token
    }
  }
`;

export const SIGN_IN_SHOPPER_MUTATION = gql`
  mutation SIGN_IN_SHOPPER_MUTATION(
    $email: String!
    $password: String!
    $deviceId: String!
  ) {
    signInShopper(email: $email, password: $password, deviceId: $deviceId) {
      token
    }
  }
`;

export const SIGN_OUT_SHOPPER_MUTATION = gql`
  mutation SIGN_OUT_SHOPPER_MUTATION {
    signOutShopper {
      message
    }
  }
`;

export const UPDATE_MY_SHOPPER_PROFILE_MUTATION = gql`
  mutation UPDATE_MY_SHOPPER_PROFILE_MUTATION(
    $firstname: String
    $lastname: String
    $avatar: Upload
    $rut: String
    $phone: String
    $locations: [UpdateShopperLocationsInput]
  ) {
    updateMyShopperProfile(
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      rut: $rut
      phone: $phone
      locations: $locations
    ) {
      _id
      email
      avatar
      firstname
      lastname
      rut
      phone
      locations {
        city
        commune
        address
      }
    }
  }
`;

export const UPDATE_MY_SHOPPER_PW_MUTATION = gql`
  mutation UPDATE_MY_SHOPPER_PW_MUTATION($currentPw: String!, $newPw: String!) {
    updateMyShopperPw(currentPw: $currentPw, newPw: $newPw) {
      message
    }
  }
`;
