import { darken } from 'polished';
import {
  TButtonProps,
  TButtonVariant,
  TButtonVariantStyle,
} from 'kosmos-ui/lib/components/Button/types';
import { TOSThemeAndContentContextValues } from 'app/context/OSThemeAndContentContext/types';
import { CSSProperties } from 'react';
import { theme } from 'kosmos-ui/lib';

export function getOSButtonVariantStyles({
  variant,
  format,
  size,
  osTheme,
}: Pick<TButtonProps, 'variant' | 'format' | 'size'> & {
  osTheme: Pick<TOSThemeAndContentContextValues, 'fonts' | 'buttons'>;
}) {
  const variantsTheme: Record<TButtonVariant, CSSProperties> = {
    primary: {
      backgroundColor: osTheme.buttons.primary.backgroundColor,
      color: osTheme.buttons.primary.fontColor,
    },
    secondary: {
      backgroundColor: theme.colorsPalette.gray.superLight,
      color: theme.colorsPalette.black.default,
    },
    danger: {
      backgroundColor: osTheme.buttons.danger.backgroundColor,
      color: osTheme.buttons.danger.fontColor,
    },
    success: {
      backgroundColor: osTheme.buttons.success.backgroundColor,
      color: osTheme.buttons.success.fontColor,
    },
  };

  const button = variantsTheme[variant];

  const variantStyle: TButtonVariantStyle = {
    backgroundColor:
      format === 'outline' || format === 'link'
        ? 'transparent'
        : button.backgroundColor,
    border:
      format === 'outline' ? `1px solid ${button.backgroundColor}` : 'none',
    color:
      format === 'outline' || format === 'link'
        ? button.backgroundColor
        : button.color,
    backgroundColorOnHover:
      format === 'outline'
        ? button.backgroundColor
        : format === 'link'
        ? 'transparent'
        : darken(0.12, button.backgroundColor as string),
    colorOnHover:
      format === 'link'
        ? darken(0.12, button.backgroundColor as string)
        : format === 'outline'
        ? theme.colorsPalette.white.default
        : button.color,
    fontSize:
      size === 'small' ? theme.fontSizes.small : theme.fontSizes.regular,
    //@ts-ignore
    fontFamily: osTheme.fonts.paragraphsFont.family,
    padding:
      format === 'link' ? 0 : size === 'small' ? '4px 16px' : '12px 16px',
    minWidth: format === 'link' || size === 'small' ? 'unset' : '120px',
  };

  return variantStyle;
}
