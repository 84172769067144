import { createContext, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { useAlerts } from '../AlertsContext/hooks';

import {
  TShoppingCartProduct,
  TShoppingCartFlags,
  TShoppingCartActionProduct,
  TContextProps,
  TProps,
} from './types';
import {
  shoppingCartReducer,
  getShoppingCartProductsToRender,
} from './helpers';

export const ShoppingCartContext = createContext({} as TContextProps);

/**
 * Pass down to the provided children a shopping cart state and dispatcher.
 * @param children React nodes which will receive the shopping cart state
 * and dispatcher as context props.
 */
export default function ShoppingCartContextProvider({
  children,
}: TProps): JSX.Element {
  const [shoppingCart, dispatchShoppingCart] = useReducer(shoppingCartReducer, {
    products: {} as { [key: string]: TShoppingCartProduct },
    // TODO: Add this field.
    // shippingCost: 0,
    totalAmount: 0,
    coupon: undefined,
    flags: {} as TShoppingCartFlags,
  });

  const { t } = useTranslation();

  const { showSuccessSnackbar, showErrorSnackbar } = useAlerts();

  useEffect(
    () => {
      const additionResult: boolean = get(shoppingCart.flags, 'additionResult');

      if (additionResult === false) {
        showErrorSnackbar({
          title: t('shoppingCart.addProductsSnackbar.errorTitle'),
          message: t('shoppingCart.addProductsSnackbar.errorDescription'),
        });
      } else if (additionResult === true) {
        showSuccessSnackbar({
          title: t('shoppingCart.addProductsSnackbar.successTitle'),
          message: t('shoppingCart.addProductsSnackbar.successDescription'),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shoppingCart.flags, showErrorSnackbar, showSuccessSnackbar]
  );

  useEffect(() => {
    const cachedShoppingCart = localStorage.getItem('grafitoShoppingCart');

    if (cachedShoppingCart) {
      const jsonCachedShoppingCart = JSON.parse(cachedShoppingCart);

      dispatchShoppingCart({
        type: 'setup',
        cachedShoppingCart: jsonCachedShoppingCart,
        product: {} as TShoppingCartActionProduct,
        qty: 0,
      });
    }
  }, []);

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        dispatchShoppingCart,
        getShoppingCartProductsToRender,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
