import styled from 'styled-components';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(30vw * 1.2);
  box-shadow: 2px 4px 18px rgb(0 0 0 / 2%);
  border-radius: 8px;

  @media (max-width: 1030px) {
    height: calc(40vw * 1.5);
  }
  @media (max-width: 600px) {
    height: calc(70vw * 1.5);
  }
`;

const BlackMask = styled.div`
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: -webkit-fill-available;
  height: inherit;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.5s opacity;
  border-radius: 8px;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 600px) {
    opacity: 0.9;
    height: 20%;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(117, 117, 117, 0),
      rgba(95, 93, 93, 0.55),
      rgba(74, 73, 73, 0.79)
    );

    &:hover {
      opacity: 0.9;
    }
  }
`;

const Header = styled.h1<{ fontStyles: TOSFont }>`
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ fontStyles }) =>
    parseInt(fontStyles.size) > 36 ? '36' : fontStyles.size}px;
  font-family: ${({ fontStyles }) => fontStyles.family};
  font-weight: ${({ fontStyles }) => fontStyles.weight};
  text-align: center;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export { MainContainer, BlackMask, Header, Image };
