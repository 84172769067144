import { ReactNode } from 'react';
import i18next from 'i18next';
import { ErrorIcon, SuccessIcon, theme } from 'kosmos-ui';

import { TPaymentResultPayload } from './types';

export function getPaymentResultLabels(payload?: TPaymentResultPayload): {
  header: string;
  description: string;
  action: string;
  icon?: ReactNode;
} | null {
  if (!payload || payload.expiresIn - Math.floor(Date.now() / 1000) < 0) {
    return {
      header: i18next.t('paymentResult.invalidTokenTitle'),
      description: i18next.t('paymentResult.invalidTokenDescription'),
      action: i18next.t('paymentResult.invalidTokenActionMessage'),
      icon: <ErrorIcon height="48px" color={theme.colorsPalette.red.default} />,
    };
  }

  if (payload.type === 'error') {
    return {
      header: i18next.t('paymentResult.failedPaymentTitle'),
      description: i18next.t('paymentResult.failedPaymentDescription'),
      action: i18next.t('paymentResult.failedPaymentActionMessage'),
      icon: <ErrorIcon height="48px" color={theme.colorsPalette.red.default} />,
    };
  }

  if (payload.type === 'cancelled') {
    return {
      header: i18next.t('paymentResult.cancelledPaymentTitle'),
      description: i18next.t('paymentResult.cancelledPaymentDescription'),
      action: i18next.t('paymentResult.cancelledPaymentActionMessage'),
    };
  }

  if (payload.type === 'approved') {
    return {
      header: i18next.t('paymentResult.succeedPaymentTitle'),
      description: i18next.t('paymentResult.succeedPaymentDescription', {
        shopperEmail: payload.shopperEmail,
      }),
      action: i18next.t('paymentResult.succeedPaymentActionMessage'),
      icon: (
        <SuccessIcon height="48px" color={theme.colorsPalette.green.default} />
      ),
    };
  }

  return null;
}
