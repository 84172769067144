import { theme } from 'kosmos-ui/lib';

import { TOSThemeAndContentContextValues } from 'app/context/OSThemeAndContentContext/types';
import { TSelectProps } from 'kosmos-ui/lib/components/Select/types';
import { lighten } from 'polished';

export function getSelectStyles({
  osTheme,
  style,
}: {
  osTheme: Pick<TOSThemeAndContentContextValues, 'buttons' | 'fonts'>;
} & Pick<TSelectProps, 'style'> &
  any) {
  return {
    control: {
      color: osTheme.fonts.paragraphsFont.color,
      fontFamily: osTheme.fonts.paragraphsFont.family,
      backgroundColor: theme.colorsPalette.gray.superLight,
      ...style?.control,
    },
    container: {
      width: '100%',
      border: 'none',
      borderBottom: `2px solid ${theme.colorsPalette.gray.lighter}`,
      '&:hover': {
        borderBottom: `2px solid ${osTheme.buttons.primary.backgroundColor}`,
      },
      ...style?.container,
    },
    valueContainer: {
      padding: '8px 0',
      color: osTheme.fonts.paragraphsFont.color,
      fontFamily: osTheme.fonts.paragraphsFont.family,
      ...style?.valueContainer,
    },
    dropdownIndicator: {
      ...style?.dropdownIndicator,
    },
    singleValue: {
      color: osTheme.fonts.paragraphsFont.color,
      fontFamily: osTheme.fonts.paragraphsFont.family,
      ...style?.singleValue,
    },
    menu: {
      border: 'none',
      color: 'hsl(221,22%,66%)',
      fontSize: 14,
      padding: 16,
      boxShadow:
        '0px 0px 7px 20px rgba(0,0,0,0), 0 3px 5px 5px rgba(0,0,0,0.03)',
      minWidth: 180,
      ...style?.menu,
    },
    option: {
      border: 'none',
      textAlign: 'left',
      color: osTheme.fonts.paragraphsFont.color,
      fontFamily: osTheme.fonts.paragraphsFont.family,
      '&:hover': {
        backgroundColor: lighten(0.8, osTheme.buttons.primary.backgroundColor),
      },
      ...style?.option,
    },
  };
}
