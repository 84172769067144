import styled from 'styled-components';

export const GroupedInputsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const NotesContainer = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colorsPalette.blue.lighter};
  padding: 16px;
  color: ${({ theme }) => theme.colorsPalette.gray.default};
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 16px;
`;
