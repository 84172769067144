import styled from 'styled-components';

export const AnnouncementBarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 16px 56px;
`;

export const AnnouncementCloseButton = styled.button`
  position: absolute;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const AnnouncementBarParagraph = styled.p`
  width: calc(100% - 48px);
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.regularSmall};
  padding: 0 24px;
`;
