import { gql } from '@apollo/client';

export const GET_OS_HOMEPAGE_QUERY = gql`
  query GET_OS_HOMEPAGE_QUERY($companyId: ID) {
    getOSHomepage(companyId: $companyId) {
      _id
      header {
        height
        opacity
        slidesTransitionSpeed
        slides {
          _id
          title
          subtitle
          backgroundImage
        }
      }
      topCategories {
        _id
        name
        image
        isActive
      }
      selectedProducts {
        title
        products {
          _id
          name
          price
          images {
            url
          }
          discountPrice
          isSelectedProduct
          stock
          variants {
            _id
            price
            discountPrice
          }
        }
      }
    }
  }
`;
