import styled from 'styled-components';

type TStyledCheckoutNoteContainerProps = {
  color: string;
  backgroundColor: string;
};

export const CheckoutNotesContainer = styled.article<TStyledCheckoutNoteContainerProps>`
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  padding: 24px 12px;
  border-left: 5px solid ${({ backgroundColor }) => backgroundColor};
  margin: 0px 0px 30px 0px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-direction: column;

  @media (max-width: 600px) {
    margin: 0;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    border-left: none;
    border-radius: 0;
    text-align: center;
    padding: 16px 24px;
    font-size: 12px;
  }
`;
