import Axios, { AxiosInstance } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

import { getAuthToken } from '../helpers';

import { TRestRequestMock } from './types';

type TGetRestClientInstanceArgs<TData> = {
  url?: string;
  authTokenEntity?: TAuthTokenEntity;
  mock?: TRestRequestMock<TData>;
};

type TSetRestClientHeadersArgs<T = unknown> = Pick<
  TGetRestClientInstanceArgs<T>,
  'authTokenEntity'
>;

export class RestAPIClient {
  public instance: AxiosInstance = Axios.create();
  public mockInstance: AxiosInstance = Axios.create();
  private mockAdapter: AxiosMockAdapter = new AxiosMockAdapter(
    this.mockInstance
  );

  public getInstance<TData>(
    args?: TGetRestClientInstanceArgs<TData>
  ): AxiosInstance {
    if (args?.mock) {
      if (args.url) this.mockRequest({ ...args.mock, url: args.url });

      return this.mockInstance;
    }

    this.setHeaders({ authTokenEntity: args?.authTokenEntity });

    return this.instance;
  }

  private setHeaders(args?: TSetRestClientHeadersArgs) {
    this.instance.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers['Content-Type'] = 'application/json';
          config.headers['Access-Control-Allow-Origin'] = '*';

          if (args?.authTokenEntity)
            config.headers['Authorization'] =
              'Bearer ' + getAuthToken(args.authTokenEntity);
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  public mockRequest<TData>({
    mockAdapter = this.mockAdapter,
    url,
    data,
    statusCode = 200,
    timeout = 1000,
  }: TRestRequestMock<TData> & {
    url: string;
    mockAdapter?: AxiosMockAdapter;
  }) {
    return mockAdapter.onAny(url).reply(
      () =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve([statusCode, data]);
          }, timeout);
        })
    );
  }
}

const restAPIClient = new RestAPIClient();

export default restAPIClient;
