import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { Select as BaseSelect } from 'kosmos-ui/lib';
import { TSelectProps } from 'kosmos-ui/lib/components/Select/types';
import { getSelectStyles } from './styles';

export default function Select({ style: baseStyles, ...props }: TSelectProps) {
  const osTheme = useOSThemeAndContent();

  const styles = getSelectStyles({
    style: baseStyles,
    osTheme: {
      buttons: osTheme.buttons,
      fonts: osTheme.fonts,
    },
  });

  return <BaseSelect style={styles} {...props} />;
}
