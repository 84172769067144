import { ReactNode } from 'react';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { CarretIcon } from 'kosmos-ui/lib';
import * as yup from 'yup';

export function isCouponExpired(couponEndDate: Date): boolean {
  const isExpired = dayjs().isAfter(dayjs(couponEndDate));

  return isExpired;
}

/**
 * Gets the stepper dialog buttons according to -active step- state.
 * @returns A label/callback fields object (buttons main attrs).
 */
export function getPaymentFormStepButtons({
  activeStep,
  setActiveStep,
  form,
  mobile,
}: {
  activeStep: number;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  form: any;
  mobile?: boolean;
}): {
  label: ReactNode | string;
  callback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}[] {
  switch (activeStep) {
    case 0:
      return [
        {
          label: '',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          callback() {},
        },
        {
          label: i18next.t('checkout.paymentForm.steps.goNextButton'),
          callback(event) {
            event.preventDefault();

            setActiveStep(activeStep + 1);
          },
        },
      ];

    case 1:
      return [
        {
          label: i18next.t('checkout.paymentForm.steps.goBackButton'),
          callback(event) {
            event.preventDefault();

            setActiveStep(activeStep - 1);
          },
        },
        {
          label: i18next.t('checkout.paymentForm.steps.goNextButton'),
          callback(event) {
            event.preventDefault();

            setActiveStep(activeStep + 1);
          },
        },
      ];

    case 2:
      return [
        {
          label: i18next.t('checkout.paymentForm.steps.goBackButton'),
          callback(event) {
            event.preventDefault();

            setActiveStep(activeStep - 1);
          },
        },
        {
          label: mobile ? (
            i18next.t('checkout.paymentForm.steps.goNextButton')
          ) : (
            <>
              {i18next.t('checkout.paymentForm.steps.payButton')}
              <CarretIcon
                direction='right'
                height='12px'
                style={{ marginLeft: 8 }}
              />
            </>
          ),
          callback(event) {
            event.preventDefault();

            mobile ? setActiveStep(activeStep + 1) : form.handleSubmit();
          },
        },
      ];

    case 3:
      return [
        {
          label: i18next.t('checkout.paymentForm.steps.goBackButton'),
          callback(event) {
            event.preventDefault();

            setActiveStep(activeStep - 1);
          },
        },
        {
          label: (
            <>
              {i18next.t('checkout.paymentForm.steps.payButton')}
              <CarretIcon
                direction='right'
                height='12px'
                style={{ marginLeft: 8 }}
              />
            </>
          ),
          callback(event) {
            event.preventDefault();

            form.handleSubmit();
          },
        },
      ];

    default:
      return [
        {
          label: i18next.t('checkout.paymentForm.steps.goNextButton'),
          callback() {
            setActiveStep(activeStep + 1);
          },
        },
      ];
  }
}

export function getPaymentFormStepsLabels(mobile?: boolean): string[] {
  let steps = [
    i18next.t('checkout.paymentForm.steps.contactTitle'),
    i18next.t('checkout.paymentForm.steps.deliveryTitle'),
    i18next.t('checkout.paymentForm.steps.paymentTitle'),
  ];

  if (mobile)
    steps = [i18next.t('checkout.paymentForm.steps.summaryTitle'), ...steps];

  return steps;
}

export const formValidationSchema = yup.object().shape({
  userEmail: yup.string().required().email(),
  userFirstname: yup.string().required(),
  userLastname: yup.string().required(),
});
