import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useDebounce } from './general';
import { LINKS_AND_ROUTES, SEARCH_DEBOUNCE_DELAY } from 'app/constants/app';
import { TUseSearchHookProps } from './types';
import { useLocation } from 'react-router';

/**
 * App section handler (route based).
 * @returns - The active section in which the app is and a dispatcher
 * to change it.
 */
export function useAppSection(): [
  TRoute | undefined,
  Dispatch<SetStateAction<TRoute | undefined>>
] {
  const location = useLocation();

  const [activeSection, setActiveSection] = useState<TRoute | undefined>(
    undefined
  );

  useEffect(() => {
    setActiveSection(LINKS_AND_ROUTES[location.pathname]);
  }, [location.pathname]);

  return [activeSection, setActiveSection];
}

/**
 * Provides a debounced search state management.
 * @param delay - Optional delay for debouncing the search.
 * Default: 1000ms
 * @param onSearch - Optional callback to trigger when setting
 * the searched term.
 * @param onDebounce - Optional callback to trigger when debouncing happens.
 */
export function useSearch(props?: TUseSearchHookProps) {
  const [term, setTerm] = useState('');
  const [searching, setSearching] = useState(false);

  const onSearchRef = useRef<(_: string) => void>();
  const onDebounceRef = useRef<(_: string) => void>();

  const debouncedTerm = useDebounce(
    term,
    props?.delay || SEARCH_DEBOUNCE_DELAY
  );

  onSearchRef.current = props?.onSearch;
  onDebounceRef.current = props?.onDebounce;

  useEffect(() => {
    if (term) {
      setSearching(true);
      onSearchRef.current && onSearchRef.current(term);
    }
  }, [term, onSearchRef]);

  useEffect(() => {
    if (debouncedTerm) {
      setSearching(false);
      onDebounceRef.current && onDebounceRef.current(debouncedTerm);
    }
  }, [debouncedTerm, onDebounceRef]);

  return { term, debouncedTerm, searching, setTerm };
}
