import { useQuery } from '@apollo/client';

import { useCompany } from 'app/context/CompanyContext/hooks';

import { GET_OS_ANNOUNCEMENT_BAR_QUERY } from './documents';
import {
  TGetOSAnnouncementBarData,
  TGetOSAnnouncementBarVariables,
} from './types';

export function useOSAnnouncementBarQuery() {
  const company = useCompany();

  const query = useQuery<
    TGetOSAnnouncementBarData,
    TGetOSAnnouncementBarVariables
  >(GET_OS_ANNOUNCEMENT_BAR_QUERY, { variables: { companyId: company._id } });

  return query;
}
