import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Dialog, TextInput, MailIcon, KeyIcon } from 'kosmos-ui';

import { getDeviceId } from '../../../helpers/device';
import { useBackgroundLocation } from 'app/hooks/routes';
import { useSignInShopperMutation } from 'api/graphql/mutations/shopper/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { ROUTES } from 'app/constants/app';
import { Button } from 'app/components';
import Typography from 'app/components/Typography';

import { TShopperSignInForm } from './types';
import { signInShopperFormValidationSchema } from './helpers';

export default function ShopperSignIn() {
  const { t } = useTranslation();

  const { mobile } = useLayout();

  const { location, goBackToBackgroundLocation } = useBackgroundLocation();

  useEffect(() => {
    getDeviceId().then((deviceId: string) => {
      form.setFieldValue('deviceId', deviceId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [signUpShopperMutation, { loading: loadingSignInShopperMutation }] =
    useSignInShopperMutation({
      onCompleted() {
        goBackToBackgroundLocation();
      },
    });

  const form = useFormik<TShopperSignInForm>({
    initialValues: { email: '', password: '', deviceId: '' },
    validationSchema: signInShopperFormValidationSchema,
    onSubmit(values) {
      signUpShopperMutation({ variables: values });
    },
  });

  return (
    <Dialog show style={{ width: mobile ? '75vw' : '33vw', maxWidth: 560 }}>
      <Dialog.Header>
        <Dialog.Title>{t('shopperSignIn.title')}</Dialog.Title>

        <Dialog.Close onClick={goBackToBackgroundLocation} />
      </Dialog.Header>

      <Dialog.Body>
        <TextInput
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={`${t('shopperSignIn.emailInputLabel')}*`}
          variant="filled"
          adornments={{ left: <MailIcon height="20px" /> }}
          style={{ root: { marginBottom: 8 } }}
          error={!!form.errors.email && form.touched.email}
        />

        <TextInput
          name="password"
          type="password"
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          label={`${t('shopperSignIn.passwordInputLabel')}*`}
          variant="filled"
          adornments={{ left: <KeyIcon height="20px" /> }}
          error={!!form.errors.password && form.touched.password}
        />
      </Dialog.Body>

      <Dialog.Footer style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Button
          variant="primary"
          onClick={() => form.handleSubmit()}
          loading={loadingSignInShopperMutation}
          style={{ width: '100%', marginBottom: 8 }}
        >
          {t('shopperSignIn.submitButton')}
        </Button>

        <Typography as="p" variant="paragraph1">
          {t('shopperSignIn.switchToSignUpDescription')}{' '}
          <Link
            to={{
              pathname: ROUTES.shopperSignUp,
              state: location.state,
            }}
            replace
            style={{ textDecoration: 'none' }}
          >
            {t('shopperSignIn.switchToSignUpLink')}
          </Link>
        </Typography>
      </Dialog.Footer>
    </Dialog>
  );
}
