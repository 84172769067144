/*eslint no-unexpected-multiline: 0*/
import styled from 'styled-components';

import helpers from 'app/helpers';

type TWithHeaderHeightProps = {
  headerHeight: number;
};

type IHeaderContainerProps = TWithHeaderHeightProps & {
  backgroundImage: string;
};

export const HeaderContainer = styled.section<IHeaderContainerProps>`
  background: url('${({ backgroundImage }) => backgroundImage}') no-repeat
    center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  padding-top: ${({ headerHeight }) => headerHeight}px;
`;

export const Header = styled.h1<{ fontStyle: TOSFont }>`
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  font-family: ${({ fontStyle }) => fontStyle.family};
  z-index: 1;
  margin: 24px 0;

  @media (max-width: 600px) {
    font-size: ${({ fontStyle }) =>
      helpers.getMobileCustomFontSize(fontStyle.size, 'header')};
    line-height: 1;
    text-align: center;
  }
`;

export const SubHeader = styled.h2<{ fontStyle: TOSFont }>`
  color: ${({ theme }) => theme.colorsPalette.white.default};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  font-family: ${({ fontStyle }) => fontStyle.family};
  z-index: 1;
  margin: 0;
  width: 80%;
  line-height: 1.5;
  text-align: center;

  @media (max-width: 600px) {
    font-size: ${({ fontStyle }) =>
      helpers.getMobileCustomFontSize(fontStyle.size, 'paragraph')};
    line-height: 1;
    text-align: center;
  }
`;

export const BlackMask = styled.div<TWithHeaderHeightProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: rgba(0, 0, 0, 0.4);
  padding-top: ${({ headerHeight }) => headerHeight}px;
`;
