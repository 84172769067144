import { CHARS_EQUIVALENCE_HASH_TABLE } from '../constants/general';

/**
 * Determinate if some of the provided set of data has changed with respect to its prev value.
 * @param dataSet Set containing the data to compare in a two-items array.
 * @returns Flag indicating if data changed or not.
 */
function dataHasChanged(dataSet: any) {
  const result = dataSet.reduce((acc: boolean, item: string[]) => {
    const haveNotChanged = item[0] === item[1];

    return acc && haveNotChanged;
  }, true);

  return !result;
}

/**
 * Formats a number by including dots and commas.
 * @param number Plain-format number that wants to be converted.
 * @returns The provided number as formatted string.
 */
function formatNumber(number: number): string {
  const converterRegex = /(\d)(?=(\d{3})+(?!\d))/g;

  const parsedNumber = Math.floor(number).toString();

  return parsedNumber.replace(converterRegex, '$1.');
}

/**
 * Validates if the provided date follows the
 * --- <short weekday>, <numeric day> <short month>. <numeric year> --- format.
 * @param date String representation of the date that wants to be verified.
 * @returns True if the provided date is on format, false otherwise.
 */
function validateDateFormat(date?: string): boolean {
  const validationRegex =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

  return !!(date && validationRegex.test(date));
}

/**
 * Scale a pixel measure based on provided ratio.
 * @param measure The initial measure to scale (as string in <number>px format).
 * @param ratio The ratio of conversion (from 0 to 1).
 * @returns The new measure scaled according to the provided ratio (as string
 * in <number>px format).
 */
function resizePx(measure: string, ratio: number): string {
  const measureMagnitude = parseInt((measure as any).match(/\d+/g)[0]);

  return `${Math.round(measureMagnitude * ratio * 100) / 100}px`;
}

/**
 * Remove falsy values ( "", 0, false, null, undefined ) from a given object.
 * @param object A scalar-value object that wants to be cleaned.
 * @param exclude Array of falsy value types to ignore on the cleanup.
 * @returns An object with only the truthly values of the initial object
 * passed as prop.
 */
function cleanObject(
  object: {
    [key: string]: string | number | boolean | null | undefined | any[];
  },
  exclude?: ('string' | 'number' | 'boolean' | 'null' | 'undefined')[]
) {
  return Object.keys(object).reduce((acc, currentKey) => {
    if (
      object[currentKey] ||
      (Array.isArray(object[currentKey]) &&
        (object[currentKey] as []).length > 0) ||
      (exclude && exclude.includes(typeof object[currentKey] as any))
    )
      return { ...acc, [currentKey]: object[currentKey] };

    return acc;
  }, {});
}

function getObjectsDifference({
  primaryObject,
  secondaryObject,
}: {
  primaryObject: Record<string, unknown>;
  secondaryObject: Record<string, unknown>;
}) {
  const primaryObjectKeys = Object.keys(primaryObject);

  return primaryObjectKeys.reduce((acc, key) => {
    //@ts-ignore
    if (primaryObject[key] !== secondaryObject[key])
      //@ts-ignore
      return { ...acc, [key]: primaryObject[key] };

    return acc;
  }, {});
}

/**
 * Transform any kind of string (with spaces, uppercase and special chars/
 * symbols) into a URL-format string.
 * @param name The name that want to be converted
 * @returns The name in URL format (under score for spaces)
 */
function getUrlFromName(name: string): string {
  const convertedToValidCharsName = [...name].map((nameChar) => {
    return CHARS_EQUIVALENCE_HASH_TABLE[nameChar] || nameChar;
  });

  const transformedName = convertedToValidCharsName
    .join('')
    .replace(/[^-A-Za-z0-9]+/g, '_')
    .toLowerCase();

  return `/${transformedName}`;
}

/**
 * Gets the ideal font-size to be displayed in mobile devices (max-width: 600px),
 * based on the current configured size.
 * @param currentSize The current size to transform.
 * @param fontType The type of font to be transformed (header, subheader or
 * paragraph).
 * @returns The ideal size for a mobile version in (48<=number<=100)px format.
 */
function getMobileCustomFontSize(
  currentSize: string,
  fontType: 'header' | 'subheader' | 'paragraph'
): string {
  const decreasedSize = Math.floor(parseInt(currentSize) * 0.5);

  let maxSize: number;
  let minSize: number;

  switch (fontType) {
    case 'header':
      maxSize = 52;
      minSize = 48;
      break;

    case 'subheader':
      maxSize = 40;
      minSize = 12;
      break;

    case 'paragraph':
      maxSize = 28;
      minSize = 12;
      break;

    default:
      maxSize = 28;
      minSize = 12;
  }

  if (decreasedSize > maxSize) return `${maxSize.toString()}px`;
  else if (decreasedSize < minSize) return `${minSize.toString()}px`;

  return `${decreasedSize.toString()}px`;
}

/**
 * Gets a ready-to-render price prop of a product considering it as an
 * object with variants.
 * @param product - The top level price and variants from the product
 * to process.
 * @returns - The price as string or number according to variants prop.
 */
function getProductPriceToRender(
  product: Pick<TProduct, 'price' | 'variants'>
): string | number {
  const price =
    product.price ||
    (product.variants && product.variants.length > 0
      ? product.variants.reduce((acc, variant) => {
          if (acc === variant.price) return variant.price;

          return 'Precios variados';
        }, product.variants[0].price as number | string)
      : 'Sin definir');

  return price;
}

/**
 * Rounds a number given a no. of decimals.
 * @param value - The number to round.
 * @param decimals - How many decimals the result should include.
 * Default is 0.
 */
function roundNumber(value: number, decimals: number | undefined = 0) {
  if (decimals < 0) return value;

  return Number(
    Math.round(parseFloat(value + 'e' + decimals)) + 'e-' + decimals
  );
}

/**
 * Calculates the discount percentage (with '% off' suffix) for a given
 * original and discount prices.
 * @param originalPrice - The product original price.
 * @param discountPrice - The product discount price.
 */
function getProductDiscountToRender(
  originalPrice: number,
  discountPrice: number
): string {
  const percentage = 100 * (1 - discountPrice / originalPrice);

  const decimals = percentage < 1 ? 2 : 0;

  const roundedPercentage = roundNumber(percentage, decimals);

  return `${roundedPercentage}% off`;
}

const helpers = {
  dataHasChanged,
  formatNumber,
  validateDateFormat,
  resizePx,
  cleanObject,
  getObjectsDifference,
  getUrlFromName,
  getMobileCustomFontSize,
  getProductPriceToRender,
  roundNumber,
  getProductDiscountToRender,
};

export default helpers;
