import { theme } from 'kosmos-ui/lib';
import { TVectorProps } from 'kosmos-ui/lib/types/vectors';
import { lighten } from 'polished';

import { TOSThemeAndContentContextValues } from 'app/context/OSThemeAndContentContext/types';

export function getShopperBrowserSidebarIconColors({
  iconIsActive,
  osTheme,
}: {
  iconIsActive: boolean;
  osTheme: TOSThemeAndContentContextValues;
}): Required<Pick<TVectorProps, 'colors' | 'color'>> {
  return {
    colors: {
      default: iconIsActive
        ? osTheme.navbar.elementsColor
        : theme.colorsPalette.gray.light,
      light: iconIsActive
        ? lighten(0.7, osTheme.navbar.elementsColor)
        : theme.colorsPalette.gray.lighter,
    },
    color: iconIsActive
      ? osTheme.navbar.elementsColor
      : theme.colorsPalette.gray.light,
  };
}
