import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CloseIcon } from 'kosmos-ui/lib';

import { useOSAnnouncementBarQuery } from 'api/graphql/queries/online-store/announcement-bar/hooks';
import { useElementSize } from 'app/hooks/general';

import {
  AnnouncementBarContainer,
  AnnouncementCloseButton,
  AnnouncementBarParagraph,
} from './styles';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

export default function AnnouncementBar() {
  const [show, setShow] = useState(false);

  const { data: announcementBarData } = useOSAnnouncementBarQuery();

  const announcementBar = announcementBarData?.getOSAnnouncementBar;

  useEffect(() => {
    setShow(!!announcementBarData?.getOSAnnouncementBar.show);
  }, [announcementBarData]);

  if (!show || !announcementBar) return null;

  return (
    <AnnouncementBarWithData
      announcementBar={announcementBar}
      setShow={setShow}
    />
  );
}

function AnnouncementBarWithData({
  announcementBar,
  setShow,
}: {
  announcementBar: Distinct<TOSAnnouncementBar>;
  setShow: Dispatch<SetStateAction<boolean>>;
}) {
  const osTheme = useOSThemeAndContent();
  const { setAnnouncementBarHeight } = useLayout();

  const containerRef = useRef(null);

  useElementSize(containerRef, {
    onUpdate(size) {
      setAnnouncementBarHeight(size.height);
    },
  });

  function handleCloseClick() {
    setShow(false);
    setAnnouncementBarHeight(0);
  }

  return (
    <AnnouncementBarContainer
      style={{
        backgroundColor: announcementBar?.backgroundColor,
        color: announcementBar?.fontColor,
      }}
      ref={containerRef}
    >
      <AnnouncementCloseButton onClick={handleCloseClick}>
        <CloseIcon height="12px" />
      </AnnouncementCloseButton>

      <AnnouncementBarParagraph
        style={{
          fontFamily: osTheme.fonts.paragraphsFont.family,
          color: announcementBar?.fontColor,
        }}
      >
        {announcementBar?.message}
      </AnnouncementBarParagraph>
    </AnnouncementBarContainer>
  );
}
