import styled from 'styled-components';

const MainContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  overflow: hidden;
  transition: max-height 0.8s ease-out;
  height: auto;
  max-height: ${({ expanded }) => (expanded ? '600' : '40')}px;
`;

export { MainContainer };
