import styled from 'styled-components';
import { lighten } from 'polished';
import { CSSProperties } from 'react';
import { theme } from 'kosmos-ui/lib';

type IStyledBarContainerProps = {
  backgroundColor: string;
  elementsColor: string;
  hasNavlinks: boolean;
};

export const TopbarContainer = styled.nav<IStyledBarContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 56px;
  height: ${({ hasNavlinks }) => (hasNavlinks ? '96px' : '32px')};
  color: ${({ elementsColor }) => elementsColor};
  box-shadow: ${({ theme }) =>
    `0px 0px 14px -11px ${theme.colorsPalette.black.default}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color 0.5s, box-shadow 0.5s;

  @media (max-width: 600px) {
    padding: 16px 24px;
  }
`;

export const TopbarInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TopbarLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);

  @media (max-width: 600px) {
    position: unset;
    transform: none;
  }
`;

export const ShoppingCartButton = styled.button<{ elementsColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: 24px;

  &:hover {
    color: ${({ elementsColor }) => lighten(0.7, elementsColor)};
  }
`;

export const ToggleSidebarButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  left: 0;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    position: unset;
  }
`;

export const BrandLogoImageContainer = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;

  @media (max-width: 600px) {
    height: 40px;
  }
`;

export const shoppingCartQtyChipStyles: CSSProperties = {
  fontSize: 11,
  color: theme.colorsPalette.white.default,
  backgroundColor: theme.colorsPalette.red.default,
  padding: '2px 6px',
  position: 'absolute',
  right: -10,
  top: -4,
};
