import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Checkout, Home, ProductDetails, CustomPage } from '../sections';
import Navigation from 'app/sections/Navigation';
import Footer from 'app/sections/Footer';
import PaymentResult from 'app/sections/PaymentResult';
import ProductsSearchResults from 'app/sections/ProductsSearchResults';
import ProductCategoryDetails from 'app/sections/ProductCategoryDetails';
import ProductMerchantDetails from 'app/sections/ProductMerchantDetails';
import { useBackgroundLocation } from 'app/hooks/routes';
import { useScrollToTop } from 'app/hooks/general';
import ShopperBrowser from 'app/sections/shopper/ShopperBrowser';
import ShopperSignIn from 'app/sections/shopper/ShopperSignIn';
import ShopperSignUp from 'app/sections/shopper/ShopperSignUp';
import { ROUTES } from 'app/constants/app';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import useRUM from 'rum/hooks';

/**
 * Renders the app UI based on current route.
 */
export default function Routes() {
  const { location, locationBackground } = useBackgroundLocation();

  const company = useCompany();

  const { shoppingCart } = useShoppingCart();

  useRUM();

  useScrollToTop();

  const shoppingCartSubtotal = Object.values(shoppingCart.products).reduce(
    (acc, product) => {
      const productSubtotal = (
        product.variants
          ? product.variants.reduce((acc, variant) => {
              return acc + variant.subtotal;
            }, 0)
          : product.subtotal
      ) as number;

      return acc + productSubtotal;
    },
    0
  );

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          // TODO: Add it in the company db model, then read
          // it here as that.
          href={process.env.REACT_APP_COMPANY_FAVICON_ICO}
        />

        <title>
          {typeof company.fantasyName === 'string'
            ? company.fantasyName
            : 'Cargando...'}
        </title>
      </Helmet>

      <Navigation />

      <Switch location={locationBackground || location}>
        <Route exact path={ROUTES.home} component={Home} />

        <Route
          exact
          path={ROUTES.productsSearchResults}
          component={ProductsSearchResults}
        />

        <Route path={`${ROUTES.product}/:_id`} component={ProductDetails} />

        {shoppingCartSubtotal > 0 && (
          <Route path={ROUTES.checkout} component={Checkout} />
        )}

        <Route
          path={`${ROUTES.categories}/:_id`}
          component={ProductCategoryDetails}
        />

        <Route
          path={`${ROUTES.merchants}/:_id`}
          component={ProductMerchantDetails}
        />

        <Route
          path={`${ROUTES.customPages}/:type/:_id`}
          component={CustomPage}
        />

        <Route
          path={`${ROUTES.paymentResult}/:resultToken`}
          component={PaymentResult}
        />

        <Route component={Home} />
      </Switch>

      <Switch>
        <Route exact path={ROUTES.shopperBrowser} component={ShopperBrowser} />
        <Route exact path={ROUTES.shopperSignIn} component={ShopperSignIn} />
        <Route exact path={ROUTES.shopperSignUp} component={ShopperSignUp} />
      </Switch>

      <Footer />
    </>
  );
}
