import { useState } from 'react';
import { Stepper } from 'kosmos-ui';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';

import { usePaymentForm } from './hooks';
import { PaymentFormFooter, PaymentFormStepSwitcher } from './components';
import { getPaymentFormStepsLabels } from './helpers';
import { TPaymentFormProps } from './types';
import { PaymentFormContainer } from './styles';

/**
 * Renders checkout payment form for shoppers.
 */
export default function PaymentForm({
  setShippingCost,
  amounts,
  storePickUpEnabled,
}: TPaymentFormProps) {
  const [activeStep, setActiveStep] = useState(0);

  const { mobile } = useLayout();
  const osTheme = useOSThemeAndContent();

  const [form, { loadingPayment }] = usePaymentForm({ amounts });

  const stepsLabels = getPaymentFormStepsLabels(mobile);

  return (
    <PaymentFormContainer>
      <Stepper
        activeStep={activeStep}
        style={{
          color: mobile
            ? osTheme.buttons.primary.backgroundColor
            : osTheme.buttons.primary.fontColor,
          bar: {
            height: 6,
          },
        }}
      >
        {stepsLabels.map((stepLabel, index) => (
          <Stepper.Step key={index}>
            <Stepper.Label>{stepLabel}</Stepper.Label>
          </Stepper.Step>
        ))}
      </Stepper>

      <PaymentFormStepSwitcher
        activeStep={activeStep}
        form={form}
        amounts={amounts}
        storePickUpEnabled={storePickUpEnabled}
        setShippingCost={setShippingCost}
      />

      <PaymentFormFooter
        activeStep={activeStep}
        form={form}
        setActiveStep={setActiveStep}
        loadingPayment={loadingPayment}
      />
    </PaymentFormContainer>
  );
}
