import { useState, useEffect, useRef } from 'react';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { HamburgerIcon, ShoppingBagIcon, Chip } from 'kosmos-ui';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useElementSize, usePrevious } from 'app/hooks/general';
import { useGlobalSearch } from 'app/context/GlobalSearchContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { ROUTES } from 'app/constants/app';
import NavbarNavlinks from '../Navlinks';
import ShopperAccountEntry from '../../../shopper/ShopperEntry';

import { TProps } from './types';
import {
  TopbarContainer,
  TopbarInnerContainer,
  TopbarLeftSideContainer,
  ShoppingCartButton,
  BrandLogoImageContainer,
  ToggleSidebarButton,
  shoppingCartQtyChipStyles,
} from './styles';
import LocalesSwitcher from 'app/components/LocalesSwitcher';
import SearchInput from 'app/components/SearchInput';

/**
 * Main app top navigation bar.
 */
export default function Topbar({
  navlinks,
  toggleShowSidebar,
  toggleShowShoppingCartDetailsBar,
  shoppingCartQty,
}: TProps) {
  const [searchInputExpanded, setSearchInputExpanded] = useState(false);

  const containerRef = useRef(null);

  const history = useHistory();

  const osTheme = useOSThemeAndContent();

  const search = useGlobalSearch();

  const prevSearching = usePrevious(search.searching);

  const { mobile, setTopBarHeight } = useLayout();

  useEffect(() => {
    if (prevSearching && !search.searching) {
      history.push(`${ROUTES.products}/search?q=${search.debouncedTerm}`);
    }
  }, [prevSearching, search.searching, search.debouncedTerm, history]);

  useElementSize(containerRef, {
    onUpdate(size) {
      setTopBarHeight(size.height);
    },
  });

  return (
    <TopbarContainer
      backgroundColor={osTheme.navbar.backgroundColor}
      elementsColor={osTheme.navbar.elementsColor}
      hasNavlinks={!!navlinks && !mobile}
      ref={containerRef}
    >
      <TopbarInnerContainer>
        {(mobile || !navlinks) && (
          <ToggleSidebarButton onClick={toggleShowSidebar}>
            <HamburgerIcon
              color={osTheme.navbar.elementsColor}
              height="24px"
              style={{ marginBottom: -4 }}
            />
          </ToggleSidebarButton>
        )}

        {!mobile && (
          <ReactRouterLink to={ROUTES.home}>
            <BrandLogoImageContainer src={osTheme.navbar.brandLogo} />
          </ReactRouterLink>
        )}

        <TopbarLeftSideContainer>
          <SearchInput
            searchTerm={search.term}
            setSearchTerm={search.setTerm}
            expanded={searchInputExpanded}
            setExpanded={setSearchInputExpanded}
            style={{ expandButton: { marginRight: 16 } }}
          />

          {!searchInputExpanded && (
            <>
              <ShoppingCartButton
                elementsColor={osTheme.navbar.elementsColor}
                onClick={toggleShowShoppingCartDetailsBar}
              >
                <ShoppingBagIcon
                  height="28px"
                  color={osTheme.navbar.elementsColor}
                />

                <Chip
                  style={{
                    fontFamily: osTheme.fonts.paragraphsFont.family,
                    ...shoppingCartQtyChipStyles,
                  }}
                >
                  {shoppingCartQty}
                </Chip>
              </ShoppingCartButton>

              <ShopperAccountEntry />

              <LocalesSwitcher
                style={{ showDialogButton: { marginLeft: 16 } }}
              />
            </>
          )}
        </TopbarLeftSideContainer>
      </TopbarInnerContainer>

      {navlinks && !mobile && <NavbarNavlinks navlinks={navlinks} />}
    </TopbarContainer>
  );
}
