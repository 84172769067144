import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ContentEditor, Chip, SlideShow, theme } from 'kosmos-ui';

import { GET_PRODUCT_QUERY } from 'api/graphql/queries/products/product/documents';
import {
  TGetProductData,
  TGetProductVariables,
} from 'api/graphql/queries/products/product/types';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useShoppingCart } from 'app/context/ShoppingCartContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import helpers from 'app/helpers';
import { Button } from 'app/components';

import {
  MainContainer,
  CategorySubHeader,
  ImageContainer,
  Image,
  ImagesThumbsContainer,
  ImageThumbContainer,
  ImageThumb,
  InfoContainer,
  NameContainer,
  NameHeader,
  Price,
  PriceContainer,
  Paragraph,
  ButtonsContainer,
  selectStyles,
} from './styles';

type TProductDetailsProps = {
  match: { params: { _id: string } };
};

/**
 * Renders a product in a detailed way by showing its images, category, name, price
 * and description (among others). Also, provides a button to add it tothe shopping
 * card.
 * @param product The product to be rendered.
 */

// TODO: Disable add to cart button for products with variants HERE and in ALL PARTS
// where users are able to add more qty's of a product (sidebar and checkout)
function ProductDetails({ product }: { product: Distinct<TProduct> }) {
  const [selectedQty, setSelectedQty] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedImage, setSelectedImage] = useState(
    product.images && product.images[0].url
  );

  const { t } = useTranslation();

  const company = useCompany();
  const { dispatchShoppingCart } = useShoppingCart();
  const { fonts, buttons, navbar } = useOSThemeAndContent();
  const { mobile, topBarHeight, announcementBarHeight } = useLayout();

  const navbarOrientation = navbar.orientation;

  const variantsOptions =
    product.variants &&
    product.variants.length > 0 &&
    product.variants.map((variant) => {
      const { value, label } = variant.mixin.reduce(
        (acc, mixin, index) => {
          return {
            value: variant._id,
            label: index !== 0 ? `${acc.label}/ ${mixin.name}` : mixin.name,
          };
        },
        { value: '', label: '' }
      );

      return { value, label };
    });

  function getPrices(product: Distinct<TProduct>): {
    price?: number;
    discountPrice?: number;
  } {
    if (product.variants && selectedVariant && !product.price) {
      const productVariant = product.variants.find(
        (product) => product._id === selectedVariant?.value
      );

      return {
        discountPrice: productVariant && productVariant.discountPrice,
        price: productVariant && productVariant.price,
      };
    }

    return { discountPrice: product.discountPrice, price: product.price };
  }

  const stock =
    product.variants && product.variants.length > 0
      ? (
          product.variants.find(
            (variant) => variant._id === selectedVariant?.value
          ) || { stock: 1 }
        ).stock
      : product.stock;

  const sku =
    product.variants && product.variants.length > 0
      ? product.variants.find(
          (variant) => variant._id === selectedVariant?.value
        )?.sku
      : product.sku;

  const { price, discountPrice } = getPrices(product);

  function handleAddToCartClick(): void {
    const isPriceAvailable = !!(discountPrice || price);

    if (
      (!product.variants || product.variants.length === 0) &&
      isPriceAvailable
    ) {
      dispatchShoppingCart({
        type: 'add',
        qty: selectedQty,
        product: {
          _id: product._id,
          image: (product.images && product.images[0].url) || '',
          sku: product.sku,
          price: discountPrice || (price as number),
          name: product.name,
          category: product.category?.name || '',
          stock,
        },
      });
    } else if (
      product.variants &&
      product.variants.length > 0 &&
      selectedVariant &&
      isPriceAvailable
    ) {
      dispatchShoppingCart({
        type: 'addWithVariant',
        qty: selectedQty,
        product: {
          _id: product._id,
          image: (product.images && product.images[0].url) || '',
          name: product.name,
          category: product.category?.name || '',
          mixinId: (selectedVariant as { value: string }).value,
          mixinName: (selectedVariant as { label: string }).label,
          stock,
          sku,
          price: discountPrice || (price as number),
        },
      });
    }
  }

  const headerHeight = topBarHeight + announcementBarHeight;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | ${product.name}`
            : 'Cargando...'}
        </title>
      </Helmet>

      <MainContainer headerHeight={headerHeight}>
        {product.images && product.images.length > 1 && !mobile && (
          <ImagesThumbsContainer navbarOrientation={navbarOrientation}>
            {product.images.map((productImage, index) => {
              return (
                <ImageThumbContainer
                  key={`${productImage}-${index}`}
                  onClick={() => setSelectedImage(productImage.url)}
                >
                  <ImageThumb src={productImage.url} />
                </ImageThumbContainer>
              );
            })}
          </ImagesThumbsContainer>
        )}

        <ImageContainer>
          {mobile ? (
            <SlideShow
              slides={product.images?.map((image) => image.url || '') || []}
              style={{ slide: { height: 'auto', width: '100%', objectFit: 'cover' } }}
            />
          ) : (
            <Image src={selectedImage} navbarOrientation={navbarOrientation} />
          )}
        </ImageContainer>

        <InfoContainer>
          <CategorySubHeader fontFamily={fonts.subtitlesFont.family}>
            {product.category?.name}
          </CategorySubHeader>

          <NameContainer>
            <NameHeader
              fontStyle={{
                ...fonts.titlesFont,
                size:
                  parseInt(fonts.titlesFont.size) > 35
                    ? '35'
                    : fonts.titlesFont.size,
              }}
            >
              {product.name}
            </NameHeader>

            {stock <= 0 && (
              <Chip
                style={{
                  marginLeft: 8,
                  backgroundColor: buttons.primary.backgroundColor,
                  color: buttons.primary.fontColor,
                }}
              >
                {t('productDetails.outOfStockLabel')}
              </Chip>
            )}
          </NameContainer>

          {price && (
            <PriceContainer>
              {!!discountPrice && (
                <Price
                  isOriginalPrice={false}
                  discountPrice={discountPrice}
                  fontFamily={fonts.paragraphsFont.family}
                >
                  ${helpers.formatNumber(discountPrice)}
                </Price>
              )}

              <Price
                isOriginalPrice={true}
                discountPrice={discountPrice}
                fontFamily={fonts.paragraphsFont.family}
              >
                ${helpers.formatNumber(price)}
              </Price>

              {!!discountPrice && (
                <Chip
                  style={{
                    marginLeft: 16,
                    backgroundColor: theme.colorsPalette.red.default,
                    color: theme.colorsPalette.white.default,
                  }}
                >
                  {helpers.getProductDiscountToRender(price, discountPrice)}
                </Chip>
              )}
            </PriceContainer>
          )}

          {variantsOptions && (
            <Select
              options={variantsOptions}
              value={selectedVariant}
              onChange={(selectedVariant: any) =>
                setSelectedVariant(selectedVariant)
              }
              styles={selectStyles}
              placeholder={t('productDetails.selectVariantPlaceholder')}
            />
          )}

          <ContentEditor
            value={product.description || ''}
            readOnly
            style={{
              editor: { border: 'none' },
              root: {
                border: 'none',
                padding: 0,
                zIndex: 0,
                fontFamily: fonts.paragraphsFont.family,
                fontSize:
                  parseInt(fonts.paragraphsFont.size) > 35
                    ? '35'
                    : fonts.paragraphsFont.size,
              },
            }}
          />

          <ButtonsContainer>
            <Button
              variant="secondary"
              size="small"
              style={{ marginRight: 8 }}
              disabled={selectedQty <= 0}
              onClick={() => setSelectedQty(selectedQty - 1)}
            >
              -
            </Button>

            <Paragraph fontStyle={fonts.paragraphsFont}>
              {selectedQty}
            </Paragraph>

            <Button
              variant="secondary"
              size="small"
              style={{ margin: '0 16px 0 8px' }}
              disabled={stock <= selectedQty}
              onClick={() => setSelectedQty(selectedQty + 1)}
            >
              +
            </Button>

            <Button
              variant="primary"
              onClick={handleAddToCartClick}
              disabled={
                stock <= 0 ||
                selectedQty <= 0 ||
                (product.variants &&
                  product.variants.length > 0 &&
                  !selectedVariant)
              }
              style={{ width: mobile ? '100%' : 'fit-content' }}
            >
              {t('productDetails.addToCartButton')}
            </Button>
          </ButtonsContainer>
        </InfoContainer>
      </MainContainer>
    </>
  );
}

export default function WithApolloProductDetails({
  match: {
    params: { _id },
  },
}: TProductDetailsProps) {
  const { data: productData, loading: loadingProductInfo } = useQuery<
    TGetProductData,
    TGetProductVariables
  >(GET_PRODUCT_QUERY, {
    variables: { _id },
  });

  if (!_id) return null; // TODO: Handle this properly

  if (loadingProductInfo || !productData) return <p>Loading product...</p>;

  return <ProductDetails product={productData.getProduct} />;
}
