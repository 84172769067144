import { createContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'app/constants/app';

import { Container } from './styles';
import {
  TProductCard,
  TProductCardProps,
  TProductCardContextValues,
} from './types';
import {
  ProductCardImage,
  ProductCardName,
  ProductCardPrice,
} from './components';

export const ProductCardContext = createContext(
  {} as TProductCardContextValues
);

export default function ProductCard(props: TProductCardProps) {
  const history = useHistory();

  return (
    <Container
      onClick={() => history.push(`${ROUTES.product}/${props.product._id}`)}
      style={props.style}
      className={props.className}
    >
      <ProductCardContext.Provider value={{ product: props.product }}>
        {props.children}
      </ProductCardContext.Provider>
    </Container>
  ) as unknown as TProductCard;
}

ProductCard.Name = ProductCardName;
ProductCard.Image = ProductCardImage;
ProductCard.Price = ProductCardPrice;
