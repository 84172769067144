import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, HamburgerIcon } from 'kosmos-ui';

import { useShopper } from 'app/context/ShopperContext/hooks';
import { useLayout } from 'app/context/LayoutContext/hooks';
import { useBackgroundLocation } from 'app/hooks/routes';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import ShopperBrowserSidebar from './components/ShopperSidebar';
import ShopperBrowserSectionSwitcher from './components/ShopperSectionSwitcher';
import { TShopperBrowserSection } from './types';
import {
  ActiveSectionHeader,
  HeaderActionsContainer,
  TransparentButton,
} from './styles';
import { getShopperBrowserSidebarSections } from './helpers';

export default function ShopperBrowser() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeSection, setActiveSection] =
    // TODO: Set 'home' as default section when recommendation service
    // is available
    useState<TShopperBrowserSection>('profile');

  const { t } = useTranslation();

  const { goBackToBackgroundLocation } = useBackgroundLocation();

  const { mobile } = useLayout();

  const osTheme = useOSThemeAndContent();

  const { shopper, loadingShopperData } = useShopper();

  function handleSectionChange(selectedSection: TShopperBrowserSection) {
    setShowSidebar(false);
    setActiveSection(selectedSection);
  }

  if (!shopper) {
    goBackToBackgroundLocation();

    return null;
  }

  const activeSectionLabel = getShopperBrowserSidebarSections(t).find(
    (section) => section._id === activeSection
  )?.label;

  return (
    <Dialog show style={{ width: mobile ? '75vw' : '40vw' }}>
      <Dialog.Header style={{ marginBottom: 0 }}>
        <HeaderActionsContainer>
          <TransparentButton onClick={() => setShowSidebar((state) => !state)}>
            <HamburgerIcon color={osTheme.navbar.elementsColor} height="20px" />
          </TransparentButton>

          <ActiveSectionHeader>{activeSectionLabel}</ActiveSectionHeader>
        </HeaderActionsContainer>

        <Dialog.Close
          onClick={goBackToBackgroundLocation}
          style={{ position: 'absolute', right: 24 }}
        />
      </Dialog.Header>

      <Dialog.Body style={{ position: 'relative', height: '60vh' }}>
        {loadingShopperData ? (
          <p>{t('shopperBrowser.loadingShopperDescription')}...</p>
        ) : (
          <>
            <ShopperBrowserSidebar
              show={showSidebar}
              activeSection={activeSection}
              onSectionChange={handleSectionChange}
            />

            <ShopperBrowserSectionSwitcher activeSection={activeSection} />
          </>
        )}
      </Dialog.Body>
    </Dialog>
  );
}
