import styled from 'styled-components';

export const CheckoutCartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  overflow-y: scroll;
  padding: 72px 56px;

  @media (max-width: 1030px) {
    padding: 0;
    flex: 1;
  }
`;

export const CheckoutCartProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(80vh - 150px);
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 1030px) {
    max-height: calc(80vh - 312px);
    margin-top: 24px;
  }
`;

export const CheckoutCartProductInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colorsPalette.gray.lighter};
  }

  @media (max-width: 1030px) {
    align-items: end;
    flex-direction: column;
    border-bottom: none;
  }
`;

export const CheckoutCartProductsInfoContainer = styled.div`
  display: flex;
  width: 280px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CheckoutCartProductImage = styled.img`
  height: 100px;
  width: 72px;
  object-fit: cover;
  border-radius: 5px;

  @media (max-width: 600px) {
    height: 80px;
  }
`;

export const CheckoutCartProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CheckoutCartTotalTypography = styled.p<{
  fontStyle: TOSFont;
}>`
  display: flex;
  justify-content: flex-end;
  width: 120px;
  font-size: ${({ fontStyle }) =>
    parseInt(fontStyle.size) > 14 ? '14' : fontStyle.size}px;
  font-family: ${({ fontStyle }) => fontStyle.family};
  color: ${({ theme }) => theme.colorsPalette.black.default};

  @media (max-width: 420px) {
    width: 100%;
    text-align: right;
  }
`;

export const CheckoutCartButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 420px) {
    width: 60%;
  }
`;

export const CheckoutCartFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 0;
  }
`;

export const CheckoutCartPaymentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 600px) {
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const CheckoutGoBackTypography = styled.p`
  color: inherit;
  margin-left: 8px;
  margin-bottom: 2px;
`;
