import { Chip } from 'kosmos-ui/lib';

import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import helpers from 'app/helpers';

import { getProductDiscountTag } from './helpers';
import {
  NameContainer,
  NameParagraph,
  ImageContainer,
  Image,
  DiscountPrice,
  DiscountPriceTag,
  Price,
  PriceContainer,
} from './styles';
import { useProductCard } from './hooks';
import {
  TProductCardImageProps,
  TProductCardNameProps,
  TProductCardPriceProps,
} from './types';

export function ProductCardName({ style, className }: TProductCardNameProps) {
  const { buttons, fonts } = useOSThemeAndContent();
  const { product } = useProductCard();

  return (
    <NameContainer style={style?.root} className={className?.root}>
      <NameParagraph
        fontStyles={fonts.paragraphsFont}
        style={style?.name}
        className={className?.name}
      >
        {product.name}
      </NameParagraph>

      {product.stock <= 0 && (
        <Chip
          style={{
            marginLeft: 8,
            backgroundColor: buttons.primary.backgroundColor,
            color: buttons.primary.fontColor,
            ...style?.root,
          }}
          className={className?.root}
        >
          Agotado
        </Chip>
      )}
    </NameContainer>
  );
}

export function ProductCardImage({ style, className }: TProductCardImageProps) {
  const { product } = useProductCard();

  const discountPriceTag = getProductDiscountTag({
    variants: product.variants,
    price: product.price,
    discountPrice: product.discountPrice,
  });

  return (
    <ImageContainer style={style?.root} className={className?.root}>
      <Image
        src={product.images && product.images[0].url}
        style={style?.img}
        className={className?.img}
      />

      {discountPriceTag && (
        <DiscountPriceTag
          style={style?.discountPrice}
          className={className?.discountPrice}
        >
          {discountPriceTag}
        </DiscountPriceTag>
      )}
    </ImageContainer>
  );
}

export function ProductCardPrice({ style, className }: TProductCardPriceProps) {
  const { product } = useProductCard();
  const { fonts } = useOSThemeAndContent();

  const price = helpers.getProductPriceToRender({
    price: product.price,
    variants: product.variants,
  });

  return (
    <PriceContainer style={style?.root} className={className?.root}>
      {!!product.discountPrice && (
        <DiscountPrice
          fontStyles={fonts.paragraphsFont}
          style={style?.discountPrice}
          className={className?.discountPrice}
        >
          ${helpers.formatNumber(product.discountPrice)}
        </DiscountPrice>
      )}

      <Price
        fontStyles={fonts.paragraphsFont}
        isDiscountPrice={!!product.discountPrice}
        style={style?.price}
        className={className?.price}
      >
        {typeof price === 'number' ? `$${helpers.formatNumber(price)}` : price}
      </Price>
    </PriceContainer>
  );
}
