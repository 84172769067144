import styled from 'styled-components';

export const ShoppingDetailsContainer = styled.aside<{ show: boolean }>`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${({ theme }) => theme.colorsPalette.white.default};
  height: 60vh;
  width: 20vw;
  z-index: 100;
  transform: ${({ show }) => (!show ? 'translateX(100%)' : 'translateX(0)')};
  transition: transform 0.3s ease-out;

  @media (max-width: 1300px) {
    width: 22vw;
  }
  @media (max-width: 900px) {
    width: 28vw;
  }
  @media (max-width: 600px) {
    width: 60vw;
  }
`;

export const ShoppingDetailsProductsSection = styled.section`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
`;

export const SubtotalContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const ProductContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin: 16px 0px;
  }
`;

export const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: 100%;
  margin-left: 10px;
`;

export const ProductImage = styled.img`
  width: 28%;
  border-radius: 5px;
  height: 105px;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 36%;
    height: 96px;
  }
`;

export const EmptyStateParagraph = styled.p<{ fontStyle: TOSFont }>`
  margin: 0;
  font-family: ${({ fontStyle }) => fontStyle.family};
  color: ${({ theme }) => theme.colorsPalette.gray.light};
  text-align: center;
`;

export const ProductNameParagraph = styled.p<{ fontStyle: TOSFont }>`
  margin: 0;
  font-family: ${({ fontStyle }) => fontStyle.family};
`;

export const ProductVariantParagraph = styled.p<{ fontStyle: TOSFont }>`
  margin: 0;
  font-family: ${({ fontStyle }) => fontStyle.family};
  color: ${({ theme }) => theme.colorsPalette.gray.default};
`;

export const ProductPriceAndQty = styled.p<{ fontStyle: TOSFont }>`
  margin: 0;
  font-family: ${({ fontStyle }) => fontStyle.family};
  color: ${({ theme }) => theme.colorsPalette.black.default};
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const SubtotalTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
