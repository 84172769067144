import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';

import { TProps } from './types';
import { MainContainer, BlackMask, Header, Image } from './styles';

function ImageCard({ image, title }: TProps) {
  const { fonts } = useOSThemeAndContent();

  return (
    <MainContainer>
      <BlackMask>
        <Header fontStyles={fonts.titlesFont}>{title}</Header>
      </BlackMask>

      <Image src={image} />
    </MainContainer>
  );
}

export default ImageCard;
