import { gql } from '@apollo/client';

export const GET_OS_FOOTER_QUERY = gql`
  query GET_OS_FOOTER_QUERY($companyId: ID) {
    getOnlineStoreFooter(companyId: $companyId) {
      _id
      contactSection {
        active
        title
        addresses
        emails
        phones
      }
      socialNetworksSection {
        active
        title
        instagram
        facebook
      }
    }
  }
`;
