import { createContext, ReactNode } from 'react';

import { useSearch } from 'app/hooks/app';

export const GlobalSearchContext = createContext({} as TSearchValues);

export function GlobalSearchContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const search = useSearch();

  return (
    <GlobalSearchContext.Provider value={search}>
      {children}
    </GlobalSearchContext.Provider>
  );
}
