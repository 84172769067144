import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';

import { useLayout } from 'app/context/LayoutContext/hooks';
import {
  TGetProductMerchantData,
  TGetProductMerchantVariables,
} from 'api/graphql/queries/products/merchant/types';
import { GET_PRODUCT_MERCHANT_QUERY } from 'api/graphql/queries/products/merchant/documents';
import { useCompany } from 'app/context/CompanyContext/hooks';
import { useOSThemeAndContent } from 'app/context/OSThemeAndContentContext/hooks';
import ProductsList from 'app/components/ProductsList';

import { HeaderContainer, Header, SubHeader, BlackMask } from './styles';

/**
 * Product merchant content (name, image and associated products).
 */
export default function ProductMerchantDetails() {
  const { _id: merchantId } = useParams<{ _id: string }>();

  const company = useCompany();
  const osTheme = useOSThemeAndContent();
  const { mobile } = useLayout();

  const {
    data: merchantData,
    loading: loadingMerchantData,
    error: errorOnMerchantData,
  } = useQuery<TGetProductMerchantData, TGetProductMerchantVariables>(
    GET_PRODUCT_MERCHANT_QUERY,
    {
      variables: { _id: merchantId },
    }
  );

  const merchant = merchantData?.getProductMerchant;

  const navbarOrientation = osTheme.navbar.orientation;

  if (loadingMerchantData) return <p>Cargando los detalles de la marca...</p>;

  if (errorOnMerchantData)
    return <p>{errorOnMerchantData.graphQLErrors[0]?.message}</p>;

  return (
    <>
      <Helmet>
        <title>
          {typeof company.fantasyName === 'string'
            ? `${company.fantasyName} | ${merchant?.name}`
            : 'Cargando...'}
        </title>
      </Helmet>

      {merchant?.image && merchant?.name && (
        <HeaderContainer
          backgroundImage={merchant?.image}
          navbarOrientation={mobile ? 'vertical' : navbarOrientation}
        >
          <BlackMask
            navbarOrientation={mobile ? 'vertical' : navbarOrientation}
          />

          <Header fontStyle={osTheme.fonts.titlesFont}>{merchant?.name}</Header>

          <SubHeader fontStyle={osTheme.fonts.subtitlesFont}>
            {merchant.description}
          </SubHeader>
        </HeaderContainer>
      )}

      <ProductsList
        groupingEntity={{ entity: 'merchant', _id: merchantId }}
        style={{ padding: !mobile ? 52 : '24px 52px 52px 52px' }}
      />
    </>
  );
}
