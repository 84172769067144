import { useLazyQuery } from '@apollo/client';

import { GET_ME_AS_SHOPPER_QUERY } from './documents';
import { TMeAsShopperData } from './types';

export function useGetMeAsShopperLazyQuery() {
  const query = useLazyQuery<TMeAsShopperData>(GET_ME_AS_SHOPPER_QUERY);

  return query;
}
