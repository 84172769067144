import styled from 'styled-components';

export const SidebarContainer = styled.nav<{
  show: boolean;
  backgroundColor: string;
}>`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 56px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 100vh;
  min-width: 136px;
  max-width: 240px;
  box-shadow: 0px 12px 0px ${({ theme }) => theme.colorsPalette.black.default};
  transform: ${({ show }) => (!show ? 'translateX(-100%)' : 'translateX(0)')};
  transition: transform 0.3s ease-out;
  z-index: 100;

  @media (max-width: 600px) {
    min-width: 100px;
    padding: 24px;
  }
`;
