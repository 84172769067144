import styled from 'styled-components';

import helpers from 'app/helpers';

const TopCategoriesSectionContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
  gap: 32px;
  padding: 72px 56px;

  @media (max-width: 600px) {
    padding: 38px;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
`;

const SelectedProductsSectionContainer = styled.section`
  padding: 72px 56px;
  text-align: center;
  background-color: ${({ theme }) => theme.colorsPalette.gray.superLight};
`;

const BlackMask = styled.div<{ height: number; opacity: number }>`
  width: 100%;
  height: ${({ height }) => `${height * 100}vh`};
  opacity: ${({ opacity }) => opacity};
  position: absolute;
  background: black;
`;

const SelectedProductsProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 32px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
`;

const SelectedProductsHeader = styled.h1<{ fontStyle: TOSFont }>`
  margin-bottom: 40px;
  color: ${({ fontStyle }) => fontStyle.color};
  font-size: ${({ fontStyle }) =>
    parseInt(fontStyle.size) > 36 ? '36' : fontStyle.size}px;
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  font-family: ${({ fontStyle }) => fontStyle.family};

  @media (max-width: 600px) {
    line-height: 1;
  }
`;

const Header = styled.h1<{ fontStyle: TOSFont }>`
  line-height: 1;
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-family: ${({ fontStyle }) => fontStyle.family};
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  color: ${({ theme }) => theme.colorsPalette.white.default};
  text-align: center;
  z-index: 1;
  margin: 10px 0;

  @media (max-width: 600px) {
    font-size: ${({ fontStyle }) =>
      helpers.getMobileCustomFontSize(fontStyle.size, 'header')};
  }
`;

const SubHeader = styled.h2<{ fontStyle: TOSFont }>`
  font-size: ${({ fontStyle }) => fontStyle.size}px;
  font-family: ${({ fontStyle }) => fontStyle.family};
  font-weight: ${({ fontStyle }) => fontStyle.weight};
  color: ${({ theme }) => theme.colorsPalette.white.default};
  z-index: 1;
  margin: 10px 0;
  line-height: 1.5;
  text-align: center;

  @media (max-width: 600px) {
    font-size: calc(30px - 50%);
  }
`;

export {
  SelectedProductsProductsContainer,
  SelectedProductsHeader,
  SelectedProductsSectionContainer,
  BlackMask,
  Header,
  TopCategoriesSectionContainer,
  SubHeader,
};
